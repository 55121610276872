// External Dependencies
import { Link } from 'react-router-dom';
import { UserAlertFlags } from '@presto-assistant/api_types';
import { ExtendedMember } from '@presto-assistant/api_types/api/admin/members';
import { toTitleCase } from '@presto-assistant/api_types/utils/toTitleCase';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { EnhancedCardContent } from 'components/shared';
import { getFullName } from 'utils';

// Local Dependencies
import MemberSearchChip from '../../shared/MemberSearchChip';

// Local Typings
interface MemberWithStateLabel extends Partial<ExtendedMember> {
  stateLabel?: string;
}

interface Props {
  member?: MemberWithStateLabel;
}

// Component Definition
const MemberCardHeader = ({ member }: Props): JSX.Element | null => {
  const isFraudUser = member?.alertFlagId === UserAlertFlags.Fraud;
  const hasDobViolation = member?.alertFlagId === UserAlertFlags.DOBViolation;

  return (
    <EnhancedCardContent className="member-search-result-card-header">
      <Box
        display="flex"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          flexWrap="wrap"
          maxWidth="50%"
        >
          <Typography
            className="member-search-result-header"
            component="h3"
            paragraph
            variant="h6"
          >
            {member?.organizationLabel || member?.districtLabel}
          </Typography>

          <Link
            className="link"
            to={`/organizations/${member?.organizationId}`}
          >
            link
          </Link>
        </Box>

        <Box
          alignItems="flex-end"
          display="flex"
          gap={1.5}
        >
          {isFraudUser && (
            <MemberSearchChip
              color="error"
              label="Fraud"
              variant="outlined"
            />
          )}
          {hasDobViolation && (
            <MemberSearchChip
              color="error"
              fontWeight={500}
              label="Under 13 years old"
              variant="outlined"
            />
          )}
          <Typography
            className="member-search-result-header"
            component="h4"
            variant="h6"
          >
            {toTitleCase(getFullName({
              firstName: member?.firstName || '',
              lastName: member?.lastName || '',
            }))}
          </Typography>
        </Box>
      </Box>
    </EnhancedCardContent>
  );
};

export default MemberCardHeader;
