// External Dependencies
import {
  Button,
  CircularProgress,
  DialogActions,
} from '@mui/material';
import { FC, useCallback } from 'react';
import { FeedbackStatus } from '@presto-assistant/api_types';

// Internal Dependencies
import { useUpdateFeedback } from 'gql/mutations/feedback';
import { EnhancedCard } from 'components/shared';

// Local Typings
interface Props {
  feedback: GQL.IFeedback;
}

// Component Definition
const FeedbackActions: FC<Props> = ({ feedback }) => {
  const [updateFeedback, { loading: isSubmitting }] = useUpdateFeedback();

  const handleUpdateFeedback = useCallback((statusId: number) => () => {
    updateFeedback({
      variables: {
        id: feedback.id ?? '',
        input: {
          statusId,
        },
      },
    });
  }, [feedback, updateFeedback]);

  const isTicketOpen = Number(feedback.status.id ?? 1) === FeedbackStatus.Open;

  return (
    <EnhancedCard sx={{ marginTop: 2 }}>
      <DialogActions>
        {isSubmitting ? (
          <CircularProgress />
        ) : (
          <>
            <Button
              color="primary"
              onClick={handleUpdateFeedback(isTicketOpen
                ? FeedbackStatus.Ignored
                : FeedbackStatus.Open)}
              variant="outlined"
            >
              {isTicketOpen ? 'Ignore' : 'Re-Open'}
            </Button>

            {isTicketOpen && (
              <Button
                color="primary"
                onClick={handleUpdateFeedback(FeedbackStatus.Resolved)}
                variant="contained"
              >
                Mark as Resolved
              </Button>
            )}
          </>
        )}
      </DialogActions>
    </EnhancedCard>
  );
};

export default FeedbackActions;
