// External Dependencies
import DialogContentText from '@mui/material/DialogContentText';
import MusicOffIcon from '@mui/icons-material/MusicOffOutlined';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import { alpha } from '@mui/material/styles';

// Local Dependencies
import { useGetFilterCounts } from './hooks';
import Flex from '../Flex';

// Local Typings
interface Props {
  excludeNeedHelp?: boolean;
  maxTextWidth?: string;
  message?: string | React.ReactElement;
  padding?: number;
}

// Local Variables
const StyledRoot = styled(Flex)(({
  theme,
}) => ({
  '& .MuiDialogContentText-root': {
    [theme.breakpoints.up('md')]: {
      maxWidth: '50%',
    },
    marginTop: theme.spacing(2.5),
    maxWidth: '80%',
    textAlign: 'center',
  },

  '.zeroStateIcon': {
    color: alpha(theme.palette.grey[400], 0.8),
    height: 96,
    width: 96,
  },

  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: theme.spacing(6),
  position: 'relative',
  zIndex: 1,
}));

// When the user has no results using filter or search, we use the shared
//  TableDataGridZeroState, without a contact us link, adding a custom message

// Component Definition
const TableDataGridZeroResultsState = ({
  ...otherProps
}: Props): JSX.Element => {
  const {
    appliedFilterCount,
    currentQuickFilterValue,
  } = useGetFilterCounts();

  const hasFilterApplied = appliedFilterCount > 0;

  // An empty quick filter value is ['']
  const hasQuickFilterValue = currentQuickFilterValue && currentQuickFilterValue[0];

  const message = (
    <Typography>
      No results found
      {hasQuickFilterValue ? (
        <>
          {' '}for <strong>{currentQuickFilterValue}</strong>
        </>
      ) : ''}
      {hasFilterApplied ? 'for selected filter(s)' : ''}
    </Typography>
  );

  return (
    <StyledRoot
      alignContent="center"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      padding={0}
      {...otherProps}
    >
      <MusicOffIcon
        className="zeroStateIcon"
      />

      <DialogContentText
        component="div"
        variant="body1"
      >
        {message}
      </DialogContentText>
    </StyledRoot>
  );
};

export default TableDataGridZeroResultsState;
