// External Dependencies
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { FC, useCallback } from 'react';
import styled from 'styled-components';

// Internal Dependencies
import {
  BackToButton,
  CustomCheckbox,
  CustomFormButton,
  CustomInput,
  EnhancedAlert,
  EnhancedCard,
  Flex,
  Subtitle,
} from 'components/shared';
import { convertDollarsToCents } from 'utils/lib/currency';
import { useCreateLicensePaymentByAdmin } from 'gql/mutations/licenses';
import { useGetDistrictById } from 'gql/queries/districts';

// Local Variables
const StyledEnhancedCard = styled(EnhancedCard)(({ theme }) => ({
  '.MuiCardContent-root:last-child': {
    paddingBottom: theme.spacing(2),
  },
}));

const inputContainerWidth = 200;

// Component Definition
const LicensesNew: FC = () => {
  const navigate = useNavigate();
  const { districtId } = useParams();

  const {
    data,
  } = useGetDistrictById(districtId!);

  const handleCompleted = useCallback(() => navigate(-1), []);

  const [
    createLicense,
    {
      error: createLicenseError,
      loading: isSubmitting,
    },
  ] = useCreateLicensePaymentByAdmin({
    onCompleted: handleCompleted,
  });

  if (!data?.districtById) {
    return null;
  }

  return (
    <>
      <Box mb={3}>
        <BackToButton
          context={data?.districtById.label ?? 'District'}
          navigateTo={`/districts/${districtId}`}
        />
      </Box>

      <Subtitle>
        Create Licenses
      </Subtitle>

      <StyledEnhancedCard>
        <CardContent>
          <Formik<GQL.ICreateLicensePaymentByAdminInput>
            initialValues={{
              authorizedById: data.districtById.admin.id,
              checkNumber: '',
              districtId: districtId!,
              isComplimentary: false,
              isTaxExempt: false,
              licenses: [
                {
                  numberOfLicenses: 0,
                  numberOfMonths: 6,
                },
                {
                  numberOfLicenses: 0,
                  numberOfMonths: 12,
                },
                {
                  numberOfLicenses: 0,
                  numberOfMonths: 24,
                },
                {
                  numberOfLicenses: 0,
                  numberOfMonths: 36,
                },
                {
                  numberOfLicenses: 0,
                  numberOfMonths: 60,
                },
              ],
              purchaseOrderNumber: '',
              totalInCents: 0,
            }}
            onSubmit={(values) => createLicense({
              variables: {
                input: {
                  ...values,
                  totalInCents: convertDollarsToCents(values.totalInCents),
                },
              },
            })}
          >
            {() => (
              <Form>
                <Typography
                  color="textSecondary"
                  fontSize="small"
                  paragraph
                >
                  License Totals
                </Typography>

                <Flex gap={2}>
                  <Box width={inputContainerWidth}>
                    <CustomInput
                      label="Number of 6-Month Licenses"
                      name="licenses[0].numberOfLicenses"
                      type="number"
                    />
                  </Box>

                  <Box width={inputContainerWidth}>
                    <CustomInput
                      label="Number of 1-Year Licenses"
                      name="licenses[1].numberOfLicenses"
                      type="number"
                    />
                  </Box>

                  <Box width={inputContainerWidth}>
                    <CustomInput
                      label="Number of 2-Year Licenses"
                      name="licenses[2].numberOfLicenses"
                      type="number"
                    />
                  </Box>

                  <Box width={inputContainerWidth}>
                    <CustomInput
                      label="Number of 3-Year Licenses"
                      name="licenses[3].numberOfLicenses"
                      type="number"
                    />
                  </Box>

                  <Box width={inputContainerWidth}>
                    <CustomInput
                      label="Number of 5-Year Licenses"
                      name="licenses[4].numberOfLicenses"
                      type="number"
                    />
                  </Box>
                </Flex>

                <Divider sx={{ marginY: 2 }} />

                <Typography
                  color="textSecondary"
                  fontSize="small"
                  paragraph
                >
                  Payment Details
                </Typography>

                <Flex gap={2}>
                  <Box width={inputContainerWidth}>
                    <CustomInput
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      label="Payment total"
                      name="totalInCents"
                      type="number"
                    />
                  </Box>

                  <Box width={inputContainerWidth}>
                    <CustomInput
                      label="Check #"
                      name="checkNumber"
                    />
                  </Box>

                  <Box width={inputContainerWidth}>
                    <CustomInput
                      label="Purchase Order #"
                      name="purchaseOrderNumber"
                    />
                  </Box>
                </Flex>

                <Box
                  marginLeft={1}
                  marginTop={1}
                >
                  <CustomCheckbox
                    label="Complimentary"
                    name="isComplimentary"
                  />
                </Box>

                <Box
                  marginLeft={1}
                  marginTop={1}
                >
                  <CustomCheckbox
                    label="Tax Exempt"
                    name="isTaxExempt"
                  />
                </Box>

                <Box marginTop={2}>
                  <CustomFormButton
                    buttonText="Create license payment"
                    disabled={isSubmitting}
                  />
                </Box>
              </Form>
            )}
          </Formik>

          <Collapse in={Boolean(createLicenseError?.message)}>
            <EnhancedAlert
              severity="error"
              sx={{ marginTop: 1.5 }}
            >
              {createLicenseError?.message}
            </EnhancedAlert>
          </Collapse>
        </CardContent>
      </StyledEnhancedCard>
    </>
  );
};

export default LicensesNew;
