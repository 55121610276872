// External Dependencies
import { useTheme } from 'styled-components';
import ReactJson from 'react-json-view';
import Typography from '@mui/material/Typography';

// Local Typings
interface Props {
  blockTitle: string;
  contents: object;
  noMarginTop?: boolean;
}

// Component Definition
const CodeBlock = ({
  blockTitle,
  contents,
  noMarginTop,
}: Props): JSX.Element => {
  const theme = useTheme();

  return (
    <>
      <Typography
        sx={{
          marginBottom: 1,
          marginTop: noMarginTop ? 0 : theme.spacing(2),
        }}
        variant="body1"
      >
        {blockTitle}
      </Typography>

      <ReactJson
        iconStyle="square"
        src={contents}
        style={{
          borderRadius: theme.shape.borderRadius,
          padding: theme.spacing(2),
        }}
        theme="monokai"
      />
    </>
  );
};

export default CodeBlock;
