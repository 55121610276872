// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { FRAGMENT_ORGANIZATION } from 'gql/queries/organization';
import { useMutationEnhanced } from 'hooks';

// Local Typings
interface DeleteOrganizationResponse {
  deleteOrganization: boolean;
}

const DELETE_ORGANIZATION = gql`
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
  ) {
    deleteOrganization(input: $input)
  }
`;

export const VERIFY_ORGANIZATION = gql`
  mutation VerifyOrganization (
    $input: VerifyOrganizationInput!
  ) {
    verifyOrganization(input: $input) {
      ...OrganizationFragment
    }
  }
  ${FRAGMENT_ORGANIZATION}
`;

export const useDeleteOrganization = (args: {
  onCompleted: () => void;
}) =>
  useMutationEnhanced<
    DeleteOrganizationResponse,
    GQL.IDeleteOrganizationOnMutationArguments
  >(
    DELETE_ORGANIZATION,
    {
      clearCachePredicates: ['adminOrganizations', 'organizations'],
      onCompleted: args.onCompleted,
    },
  );

export const useVerifyOrganization = (args: {
  onCompleted: () => void;
}) =>
  useMutationEnhanced<
    GQL.IOrganization,
    GQL.IVerifyOrganizationOnMutationArguments
  >(
    VERIFY_ORGANIZATION,
    {
      clearCachePredicates: ['adminOrganizationsIndex', 'organizations'],
      onCompleted: args.onCompleted,
    },
  );
