// External Dependencies
import {
  ChangeEvent, FC, useCallback, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { BackToButton, ShowPageDataDisplay, Subtitle } from 'components/shared';
import { tableQueryParams } from 'state/table/selectors';
import { useReprocessStripeWebhookEvent } from 'gql/mutations/stripeWebhookEvents';
import { useGetStripeWebhookEvent } from 'gql/queries/stripeWebhookEvents';

// Component Definition
const StripeWebhookEvent: FC = () => {
  const { id } = useParams();

  const [note, setNote] = useState('');

  const stripeWebhookEventsParams = useSelector(tableQueryParams('stripeWebhookEvents'));

  const {
    data,
  } = useGetStripeWebhookEvent(id ?? '');

  const [
    reprocessStripeWebhookEvent,
    {
      data: reprocessedData,
    },
  ] = useReprocessStripeWebhookEvent();

  const handleClickReprocess = useCallback(() => {
    if (id) {
      reprocessStripeWebhookEvent({
        variables: {
          id,
          note: 'Reprocessed from Admin Actions',
        },
      });
    }
  }, [id, reprocessStripeWebhookEvent]);

  const handleChangeNote = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setNote(event.target.value);
  }, []);

  return (
    <>
      <BackToButton
        context="Stripe Events"
        navigateTo={`/admin_actions/stripe_webhook_events${stripeWebhookEventsParams}`}
      />

      <Subtitle hasGutterTop>
        Stripe Event
      </Subtitle>

      <Typography
        component="h2"
        variant="h5"
      >
        ID: {data?.stripeWebhookEvent.id}
      </Typography>

      <ShowPageDataDisplay
        label="Type"
        value={data?.stripeWebhookEvent.eventType}
      />

      <ShowPageDataDisplay
        label="Processed At"
        type="datetime"
        value={data?.stripeWebhookEvent.processedAt}
      />

      {reprocessedData?.reprocessStripeWebhookEvent ? (
        <Typography>
          Reprocessed!
        </Typography>
      ) : (
        <Box
          alignItems="flex-end"
          display="flex"
          flexDirection="column"
          marginTop={2}
          maxWidth={600}
        >
          <TextField
            fullWidth
            label="Reason for Reprocessing"
            minRows={3}
            multiline
            onChange={handleChangeNote}
            sx={{ marginBottom: 2 }}
            value={note}
          />

          <Button
            disabled={note.trim().length < 5}
            onClick={handleClickReprocess}
            variant="contained"
          >
            Reprocess Event
          </Button>
        </Box>
      )}
    </>
  );
};

export default StripeWebhookEvent;
