// External Dependencies
import {
  ImportOriginalSources,
  ImportResources,
  ImportStatuses,
} from '@presto-assistant/api_types';
import { GetOneImportResponse } from '@presto-assistant/api_types/api/admin/import';
import { getFullNameWithEmail } from '@presto-assistant/api_types/utils';
import { toTitleCase } from '@presto-assistant/api_types/utils/toTitleCase';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import Box from '@mui/material/Box';
import ReplayIcon from '@mui/icons-material/Replay';
import { useCallback, useState } from 'react';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import CardActions from '@mui/material/CardActions';

// Internal Dependencies
import {
  BecomeThisUserButton,
  EnhancedAlert,
  Flex,
  ShowPageDataDisplay,
  SplitButton,
} from 'components/shared';
import { useReplayImport } from 'hooks/api/imports';

// Local Dependencies
import ChangeStatusFormDialog from './ChangeStatusFormDialog';

// Local Typings
interface Props {
  importData: GetOneImportResponse['data'];
}

// Component Definition
const ImportDetailsContent = ({
  importData,
}: Props): JSX.Element => {
  const [isChangeStatusDialogOpen, setIsChangeStatusDialogOpen] = useState(false);

  const {
    mutate: replayImport,
    status: replayStatus,
  } = useReplayImport();

  const handleClickReplayTransformation = useCallback(async () => {
    replayImport({
      params: {
        importId: importData.import.id,
      },
    });
  }, [importData.import.id]);

  const handleClickDownloadTransformed = useCallback(() => {
    window.open(importData.transformedUrl, '_blank');
  }, [importData.transformedUrl]);

  const handleClickDownloadOriginal = useCallback(() => {
    window.open(importData.originalUrl, '_blank');
  }, [importData.originalUrl]);

  const handleClickChangeStatus = useCallback(() => {
    setIsChangeStatusDialogOpen(true);
  }, []);

  const handleCloseChangeStatusDialog = useCallback(() => {
    setIsChangeStatusDialogOpen(false);
  }, []);

  return (
    <Flex
      alignItems="stretch"
      flexDirection="column"
      height="100%"
    >
      <ShowPageDataDisplay
        label="Import ID"
        value={importData.import.id}
      />

      <ShowPageDataDisplay
        label="Organization"
        value={importData.import.organizationLabel}
      />

      <ShowPageDataDisplay
        label="Imported At"
        type="datetime"
        value={importData.import.createdAt}
      />

      <ShowPageDataDisplay
        label="Imported By"
        value={(
          <>
            {getFullNameWithEmail({
              email: importData.import.createdByMemberEmail,
              firstName: importData.import.createdByMemberFirstName,
              lastName: importData.import.createdByMemberLastName,
            })}

            <Box
              display="inline-block"
              marginLeft={2}
            >
              <BecomeThisUserButton userId={importData.import.createdByMemberId} />
            </Box>
          </>
        )}
      />

      <ShowPageDataDisplay
        label="Resource"
        value={toTitleCase(ImportResources[importData.import.importResourceId])}
      />

      <ShowPageDataDisplay
        label="Status"
        value={(
          <>
            {toTitleCase(ImportStatuses[importData.import.importStatusId])}

            <Box
              display="inline-block"
              marginLeft={2}
            >
              <Button
                disabled={replayStatus !== 'idle'}
                onClick={handleClickChangeStatus}
                size="small"
                variant="outlined"
              >
                Change Status
              </Button>
            </Box>
          </>
        )}
      />

      <ShowPageDataDisplay
        label="Claimed By"
        value={importData.import.claimedByAdminUserId ? getFullNameWithEmail({
          email: importData.import.claimedByAdminUserEmail ?? '',
          firstName: importData.import.claimedByAdminUserFirstName ?? '',
          lastName: importData.import.claimedByAdminUserLastName ?? '',
        }) : null}
      />

      <ShowPageDataDisplay
        label="Original Source"
        value={toTitleCase(ImportOriginalSources[importData.import.importOriginalSourceId])}
      />

      <Collapse in={replayStatus === 'success'}>
        <EnhancedAlert
          severity="success"
        >
          The transformation has been replayed.
          The transformed file will be available shortly.
          Refresh this page after you receive the Slack notification in
          the #import-alerts channel.
        </EnhancedAlert>
      </Collapse>

      <CardActions
        sx={{ justifyContent: 'flex-end' }}
      >
        <SplitButton
          buttons={[
            {
              label: 'Download Original',
              onClick: handleClickDownloadOriginal,
              startIcon: <DownloadIcon />,
            },
            {
              disabled: replayStatus !== 'idle',
              label: 'Replay Transformation',
              onClick: handleClickReplayTransformation,
              startIcon: <ReplayIcon />,
            },
          ]}
          primaryButtonProps={{
            disabled: replayStatus !== 'idle',
            label: 'Download Transformed',
            onClick: handleClickDownloadTransformed,
            startIcon: <DownloadIcon />,
          }}
          secondaryButtonAriaLabel="Select other action"
        />
      </CardActions>

      <ChangeStatusFormDialog
        importData={importData}
        isOpen={isChangeStatusDialogOpen}
        onClose={handleCloseChangeStatusDialog}
      />
    </Flex>
  );
};

export default ImportDetailsContent;
