// External Dependencies
import Card, { CardProps } from '@mui/material/Card';

// Component Definition
const EnhancedCard = ({
  children,
  ...otherProps
}: CardProps): JSX.Element => (
  <Card
    {...otherProps}
    variant="outlined"
  >
    {children}
  </Card>
);

export default EnhancedCard;
