import { doesStateRequireSalesTax } from '@presto-assistant/api_types/utils';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';

import {
  ShowPageDataDisplay,
} from 'components/shared';
import { useDownloadTaxExemptForm } from 'hooks/api/district';

// Local Typings
interface Props {
  district: GQL.IDistrict;
  isMobileScreen?: boolean;
}

const TaxExemptFormListItem: React.FC<Props> = ({
  district,
  isMobileScreen,
}) => {
  const navigate = useNavigate();

  const {
    mutate: downloadTaxExemptForm,
  } = useDownloadTaxExemptForm();

  const handleDownloadTaxExemptForm = useCallback(() => {
    downloadTaxExemptForm(district.id);
  }, [district.id, downloadTaxExemptForm]);

  const handleNavigateToNewTaxExemptFormPage = useCallback(() => {
    navigate(`/districts/${district.id}/tax-exempt-form/new`);
  }, [district.id, navigate]);

  const hasTaxExemptForm = Boolean(district.taxExemptFormS3Key);

  // We remove the start icons to avoid overlapping UI on small screens
  // eslint-disable-next-line no-nested-ternary
  const addUpdateButtonStartIcon = isMobileScreen
    ? null
    : hasTaxExemptForm
      ? <EditIcon />
      : <AddIcon />;

  const stateRequiresSalesTax = doesStateRequireSalesTax(parseInt(district.state.id, 10));

  return (
    <List
      dense={isMobileScreen}
      sx={{ padding: 0 }}
    >
      <ListItem sx={{ padding: 0 }}>
        <ListItemText
          primary={(
            <ShowPageDataDisplay
              label="Tax-Exempt Form?"
              type={stateRequiresSalesTax ? 'boolean' : 'text'}
              value={stateRequiresSalesTax
                ? Boolean(district.taxExemptFormS3Key)
                : 'State does not require sales tax'}
            />
          )}
          sx={{ margin: 0 }}
        />

        {stateRequiresSalesTax && (
          <ListItemSecondaryAction>
            <Button
              onClick={handleNavigateToNewTaxExemptFormPage}
              size="small"
              startIcon={addUpdateButtonStartIcon}
              variant="outlined"
            >
              {hasTaxExemptForm ? 'Update' : 'Add'}
            </Button>

            {district.taxExemptFormS3Key && (
              <Button
                onClick={handleDownloadTaxExemptForm}
                size="small"
                startIcon={isMobileScreen ? null : <FileDownloadIcon />}
                sx={{
                  marginLeft: 1,
                }}
                variant="outlined"
              >
                Download
              </Button>
            )}
          </ListItemSecondaryAction>
        )}
      </ListItem>
    </List>
  );
};

export default TaxExemptFormListItem;
