import gql from 'graphql-tag';
import { useMutationEnhanced } from 'hooks';

interface ReprocessStripeWebhookEventResponse {
  reprocessStripeWebhookEvent: GQL.IStripeWebhookEvent;
}

interface ReprocessStripeWebhookEventVariables {
  id: string;
  note: string;
}

const REPROCESS_STRIPE_WEBHOOK_EVENT = gql`
  mutation ReprocessStripeWebhookEvent(
    $id: ID!
    $note: String5to255LenType!
  ) {
    reprocessStripeWebhookEvent(id: $id, note: $note) {
      id
      processedAt
    }
  }
`;

export const useReprocessStripeWebhookEvent = () =>
  useMutationEnhanced<
    ReprocessStripeWebhookEventResponse,
    ReprocessStripeWebhookEventVariables
    >(
      REPROCESS_STRIPE_WEBHOOK_EVENT,
      {
        clearCachePredicates: ['stripeWebhookEvent'],
      },
    );
