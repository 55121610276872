// External Dependencies
import { UserAlertFlags } from '@presto-assistant/api_types';
import { useCallback, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import {
  EnhancedCardContent,
  ShowPageDataDisplay,
} from 'components/shared';
import { useIsOpen } from 'hooks/useIsOpen';

// Local Dependencies
import DialogRemoveAuthUser from './DialogRemoveAuthUser';
import MemberSearchChip from '../../shared/MemberSearchChip';

// Local Typings
interface Props {
  authUser?: DB.AuthUser;
  memberEmail?: string;
  memberId?: string;
}

// Local Variables
const ITEM_HEIGHT = 48;

const anchorOrigin = {
  horizontal: 'right',
  vertical: 'bottom',
} as MenuProps['anchorOrigin'];
const transformOrigin = {
  horizontal: 'right',
  vertical: 'top',
} as MenuProps['transformOrigin'];

// Component Definition
const MemberLoginInfoSection = ({
  authUser,
  memberEmail,
  memberId,
}: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handlePressOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const isFraudUser = authUser?.alertFlagId === UserAlertFlags.Fraud;
  const hasDobViolation = authUser?.alertFlagId === UserAlertFlags.DOBViolation;

  const {
    isOpen: isRemoveAuthUserDialogOpen,
    toggleIsOpen: toggleRemoveAuthUserDialogIsOpen,
  } = useIsOpen();

  const handleRemoveAuthUser = useCallback((): void => {
    toggleRemoveAuthUserDialogIsOpen();
    handleClose();
  }, [toggleRemoveAuthUserDialogIsOpen]);

  const hasAuthUser = Boolean(authUser);

  const menuElement = useMemo(() => (
    <div>
      <IconButton
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        aria-label="more"
        id="long-button"
        onClick={handlePressOpenMenu}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        id="long-menu"
        onClose={handleClose}
        open={open}
        transformOrigin={transformOrigin}
      >
        <MenuItem
          disabled={!hasAuthUser}
          key="remove-auth-user-option"
          onClick={handleRemoveAuthUser}
        >
          <ListItemIcon>
            <RemoveCircleIcon fontSize="small" />
          </ListItemIcon>

          <ListItemText>
            Remove Auth User
          </ListItemText>
        </MenuItem>
      </Menu>
    </div>
  ), [anchorEl, handleRemoveAuthUser, open]);

  return (
    <>
      <EnhancedCardContent className="member-auth-user">
        <Box
          alignItems="baseline"
          display="flex"
          justifyContent="space-between"
          sx={{ marginBottom: 1.5 }}
        >
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Typography
              className="subheader"
              gutterBottom
            >
              Login Info
            </Typography>

            <Collapse in={hasAuthUser}>
              <Box
                sx={{
                  display: 'flex',
                  flex: 1,
                  gap: 2,
                  marginLeft: 2,
                }}
              >
                <MemberSearchChip
                  fontWeight={500}
                  label={authUser?.onboardingRole ?? ''}
                  size="small"
                  variant="outlined"
                />

                {isFraudUser && (
                  <MemberSearchChip
                    color="error"
                    fontWeight={500}
                    label="Fraud"
                    size="small"
                    variant="outlined"
                  />
                )}
                {hasDobViolation && (
                  <MemberSearchChip
                    color="error"
                    fontWeight={500}
                    label="Under 13 years old"
                    size="small"
                    variant="outlined"
                  />
                )}
              </Box>
            </Collapse>
          </Box>

          {menuElement}
        </Box>

        <Collapse in={!hasAuthUser}>
          <Typography variant="body2">
            No login info
          </Typography>
        </Collapse>

        <Collapse in={hasAuthUser}>
          <div className="auth-user-data">
            <div>
              <Typography variant="body2">
                {authUser?.email}
              </Typography>

              <ShowPageDataDisplay
                label="Created At"
                type="datetime"
                value={authUser?.createdAt as unknown as string}
              />
            </div>

            <div>
              <ShowPageDataDisplay
                className="auth-user-data-display"
                label="Logged In At"
                type="datetime"
                value={authUser?.loggedInAt as unknown as string}
              />

              <ShowPageDataDisplay
                label="Email Verified"
                type="boolean"
                value={authUser?.isEmailVerified}
              />
            </div>
          </div>
        </Collapse>
      </EnhancedCardContent>

      <DialogRemoveAuthUser
        authUserEmail={authUser?.email ?? ''}
        isOpen={isRemoveAuthUserDialogOpen}
        memberEmail={memberEmail ?? ''}
        memberId={memberId ?? ''}
        onClose={toggleRemoveAuthUserDialogIsOpen}
      />
    </>
  );
};

export default MemberLoginInfoSection;
