// External Dependencies
import { FC } from 'react';
import { Navigate } from 'react-router-dom';

// Internal Dependencies
import { getSearchWithRedirectUrl } from 'utils/lib/redirect_user';

// Local Dependencies
import { getToken } from '../utils/cookies';

// Local Typings
interface Props {
  component: FC | any; // TODO: drop the any after other page files are FC files
}

// Component Definition
const Auth: FC<Props> = ({
  component: Component,
}) => {
  const isLoggedIn = getToken();

  if (!isLoggedIn) {
    const queryString = getSearchWithRedirectUrl();

    return (
      <Navigate
        to={`/login?${queryString}`}
      />
    );
  }
  return <Component />;
};

export default Auth;
