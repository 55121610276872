// External Dependencies
import { FC } from 'react';
import { useSelector } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AccountTieIcon from 'mdi-material-ui/AccountTie';
import AdminIcon from 'mdi-material-ui/ShieldAccount';
import SendIcon from '@mui/icons-material/Send';
import styled from 'styled-components';

// Internal Dependencies
import { getUsersByFraudAlertFlag } from 'gql/queries/alertFlags';
import { CONTACT_INFO } from 'utils/constants';
import { getFullName } from 'utils';
import { isMobileOrTabletScreenSize } from 'state/selectors';

// Local Dependencies
import BecomeThisUserButton from '../BecomeThisUserButton';
import PersonListItemMoreActionsIconMenu from '../PersonListItemMoreActionsIconMenu';

// Local Typings
interface Props extends ListItemProps {
  userData: GQL.IUser;
  variant: 'district_administrator' | 'district_assistant' | 'director';
}

// Local Variables
const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.prestoPrimaryMain,
}));

const StyledTypography = styled(Typography)({
  // We are setting a `<span>` to block to avoid HTML warnings
  //  since a `<p>` cannot have a nested `<div>`
  display: 'block',
  lineHeight: 1.6,
}) as typeof Typography;

const getIconAndTitle = (variant: Props['variant']) => {
  let Icon = AccountTieIcon;
  let title = 'Director';

  switch (variant) {
    case 'district_administrator':
      Icon = AdminIcon;
      title = 'District Admin';
      break;
    case 'district_assistant':
      Icon = AdminIcon;
      title = 'District Assistant';
      break;
    case 'director':
      Icon = AccountTieIcon;
      title = 'Director';
      break;
    default:
      Icon = AccountTieIcon;
      title = 'Director';
      break;
  }

  return {
    Icon,
    title,
  };
};

// Component Definition
const PersonListItem: FC<Props> = ({
  userData,
  variant,
  ...otherProps
}) => {
  const isMobileOrTabletScreen = useSelector(isMobileOrTabletScreenSize);

  const {
    authUserId,
    email,
    id,
    phoneNumber,
  } = userData;

  const {
    Icon,
    title,
  } = getIconAndTitle(variant);

  const { data } = getUsersByFraudAlertFlag();

  const userIsFlagged = data?.usersByAlertFlag.filter((user) => user.id === authUserId).length;

  const userFullName = getFullName(userData);

  return (
    <ListItem
      {...otherProps}
      alignItems="flex-start"
      key={id}
    >
      <ListItemAvatar>
        <StyledAvatar variant="rounded">
          <Icon />
        </StyledAvatar>
      </ListItemAvatar>

      <ListItemText
        primary={title}
        secondary={(
          <>
            <StyledTypography
              color="textPrimary"
              component="span"
              variant="body2"
            >
              {userFullName}
              {userIsFlagged ? (
                <Box
                  component="span"
                  marginLeft={1.5}
                >
                  <Chip
                    color="error"
                    component="span"
                    label="Fraud"
                    size="small"
                  />
                </Box>
              ) : null}
            </StyledTypography>

            {userData.title && (
              <StyledTypography
                color="textPrimary"
                component="span"
                variant="body2"
              >
                {userData.title}
              </StyledTypography>
            )}

            <StyledTypography
              component="span"
              variant="body2"
            >
              {email}
            </StyledTypography>

            <StyledTypography
              component="span"
              variant="body2"
            >
              {phoneNumber}
            </StyledTypography>
          </>
        )}
      />

      <ListItemSecondaryAction sx={{ alignItems: 'center', display: 'flex' }}>
        <BecomeThisUserButton userId={id} />

        {isMobileOrTabletScreen ? (
          <Tooltip
            arrow
            title="Send Email"
          >
            <IconButton
              href={`mailto:${email}?cc=${CONTACT_INFO.SUPPORT_EMAIL}`}
              rel="noreferrer noopener"
              size="large"
              target="_blank"
            >
              <SendIcon color="primary" />
            </IconButton>
          </Tooltip>
        ) : (
          <Button
            color="primary"
            href={`mailto:${email}?cc=${CONTACT_INFO.SUPPORT_EMAIL}`}
            rel="noreferrer noopener"
            size="small"
            startIcon={<SendIcon />}
            sx={{ marginRight: 1 }}
            target="_blank"
            variant="outlined"
          >
            Email
          </Button>

        )}

        <PersonListItemMoreActionsIconMenu
          authUserId={authUserId}
          userFullName={userFullName}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default PersonListItem;
