// External Dependencies
import {
  ChangeEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {
  calculateFeeOfSubTotal,
  calculateFeeAfterTotal,
} from '@presto-assistant/api_types/utils/calculateFees';
import {
  convertCentsToDollars,
  convertDollarsToCents,
  displayPriceStringFromDollarAmount,
} from '@presto-assistant/api_types/utils';

// Internal Dependencies
import {
  EnhancedCard,
  Flex,
  ShowPageDataDisplay,
  Subtitle,
} from 'components/shared';
import useTextField from 'hooks/useTextField';

// Local Typings
interface FeeParams {
  baseFee: number;
  percentageFee: number;
}

// Local Variables
const quickBooksFeeParams: FeeParams = {
  baseFee: 0,
  percentageFee: 2.99,
};
const stripeFeeParams: FeeParams = {
  baseFee: 0.30,
  percentageFee: 2.9,
};
const revTrakFeeParams: FeeParams = {
  baseFee: 0,
  percentageFee: 3.49,
};

const buttons = [
  {
    feeParams: quickBooksFeeParams,
    label: 'QuickBooks',
  },
  {
    feeParams: stripeFeeParams,
    label: 'Stripe',
  },
  {
    feeParams: revTrakFeeParams,
    label: 'RevTrak',
  },
];

// Component Definition
const FeeCalculator = (): JSX.Element => {
  const subtotalField = useTextField();
  const baseFeeField = useTextField();
  const percentageFeeField = useTextField();

  const [isRecipientCoveringFees, setIsRecipientCoveringFees] = useState(false);

  const handleClickPreset = useCallback((feeParams: FeeParams) => () => {
    baseFeeField.onChange({
      target: {
        value: feeParams.baseFee.toString(),
      },
    } as ChangeEvent<HTMLInputElement>);
    percentageFeeField.onChange({
      target: {
        value: feeParams.percentageFee.toString(),
      },
    } as ChangeEvent<HTMLInputElement>);
  }, []);

  const compareFeeMatch = useCallback((feeParams: FeeParams) => {
    return baseFeeField.value === feeParams.baseFee.toString()
      && percentageFeeField.value === feeParams.percentageFee.toString();
  }, [baseFeeField.value, percentageFeeField.value]);

  const feeTotal = useMemo(() => {
    const subtotalFloat = Number(subtotalField.value);
    const baseFeeFloat = Number(baseFeeField.value);
    const percentageFeeFloat = Number(percentageFeeField.value);

    const baseFeeInCents = convertDollarsToCents(baseFeeFloat);
    const percentageFee = percentageFeeFloat;
    const subTotalInCents = convertDollarsToCents(subtotalFloat);

    if (isRecipientCoveringFees) {
      return calculateFeeOfSubTotal({
        baseFeeInCents,
        percentageFee,
        subTotalInCents,
      });
    }

    return calculateFeeAfterTotal({
      baseFeeInCents,
      percentageFee,
      subTotalInCents,
    });
  }, [
    baseFeeField.value,
    isRecipientCoveringFees,
    percentageFeeField.value,
    subtotalField.value,
  ]);

  return (
    <div>
      <Subtitle>
        Fee Presets
      </Subtitle>

      <ButtonGroup
        aria-label="contained primary button group"
        color="primary"
        variant="outlined"
      >
        {buttons.map((button) => (
          <Button
            key={button.label}
            onClick={handleClickPreset(button.feeParams)}
            variant={compareFeeMatch(button.feeParams) ? 'contained' : 'outlined'}
          >
            {button.label}
          </Button>
        ))}
      </ButtonGroup>

      <Subtitle hasGutterTop>
        Fee Calculator
      </Subtitle>

      <Flex
        alignItems="stretch"
        gap={4}
      >
        <EnhancedCard sx={{ flexGrow: 1 }}>
          <CardContent>
            <Flex
              alignItems="flex-start"
              flexDirection="column"
              gap={2}
            >
              <TextField
                InputProps={{
                  startAdornment: <Box marginRight={0.5}>$</Box>,
                }}
                label="Subtotal"
                type="number"
                {...subtotalField}
              />

              <TextField
                InputProps={{
                  startAdornment: <Box marginRight={0.5}>$</Box>,
                }}
                helperText="In dollars"
                label="Base Fee"
                type="number"
                {...baseFeeField}
              />

              <TextField
                InputProps={{
                  endAdornment: '%',
                }}
                helperText="Percentage"
                label="Percentage Fee"
                type="number"
                {...percentageFeeField}
              />

              <FormControlLabel
                control={(
                  <Checkbox
                    checked={isRecipientCoveringFees}
                    onChange={() => setIsRecipientCoveringFees(!isRecipientCoveringFees)}
                  />
                )}
                label="Is recipient covering fees?"
              />
            </Flex>
          </CardContent>
        </EnhancedCard>

        <EnhancedCard sx={{ flexGrow: 4, minWidth: 200 }}>
          <CardContent>
            <ShowPageDataDisplay
              label="Subtotal"
              type="currency"
              value={convertDollarsToCents(Number(subtotalField.value))}
            />

            <ShowPageDataDisplay
              label="Base Fee"
              type="currency"
              value={convertDollarsToCents(Number(baseFeeField.value))}
            />

            <ShowPageDataDisplay
              label="Percentage Fee"
              value={`${percentageFeeField.value}%`}
            />

            <ShowPageDataDisplay
              label="Fee"
              value={(
                <Typography
                  fontWeight="bold"
                  variant="h5"
                >
                  {displayPriceStringFromDollarAmount(convertCentsToDollars(feeTotal))}
                </Typography>
              )}
            />
          </CardContent>
        </EnhancedCard>
      </Flex>
    </div>
  );
};

export default FeeCalculator;
