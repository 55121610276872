// External Dependencies
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  GetOneImportResponse,
  UpdateImportRequest,
} from '@presto-assistant/api_types/api/admin/import';
import { Form, Formik } from 'formik';
import { CardActions } from '@mui/material';
import { useCallback } from 'react';
import Button from '@mui/material/Button';
import { ImportStatuses } from '@presto-assistant/api_types';
import { toTitleCase } from '@presto-assistant/api_types/utils/toTitleCase';

// Internal Dependencies
import { CustomSelect, SaveButton } from 'components/shared';
import { mapEnum } from 'utils/lib/mapEnum';
import { useUpdateImport } from 'hooks/api/imports';

// Local Typings
interface Props {
  importData: GetOneImportResponse['data'];
  isOpen: boolean;
  onClose: () => void;
}

// Local Variables
const importStatusOptions = mapEnum(ImportStatuses).map((status) => ({
  ...status,
  label: toTitleCase(status.label),
}));

// Component Definition
const ChangeStatusFormDialog = ({
  importData,
  isOpen,
  onClose,
}: Props): JSX.Element => {
  const {
    mutate: updateImport,
  } = useUpdateImport();

  const handleFormikSubmit = useCallback((values: UpdateImportRequest['body']) => {
    updateImport({
      body: values,
      params: {
        importId: importData.import.id,
      },
    }, {
      onSuccess: onClose,
    });
  }, []);

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      onClose={onClose}
      open={isOpen}
    >
      <DialogTitle>
        Change Import Status
      </DialogTitle>
      <Formik<UpdateImportRequest['body']>
        initialValues={{
          importStatusId: importData.import.importStatusId,
        }}
        onSubmit={handleFormikSubmit}
      >
        {() => (
          <Form>
            <DialogContent>
              <CustomSelect
                label="Status"
                name="importStatusId"
                options={importStatusOptions}
              />
            </DialogContent>

            <CardActions
              sx={{
                justifyContent: 'flex-end',
              }}
            >
              <Button
                onClick={onClose}
              >
                Cancel
              </Button>

              <SaveButton>
                Update Status
              </SaveButton>
            </CardActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ChangeStatusFormDialog;
