// External Dependencies
import { ApolloError } from '@apollo/client';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import { FC, useState, useCallback } from 'react';
import ActiveIcon from '@mui/icons-material/CheckCircle';
import InactiveIcon from '@mui/icons-material/NotInterested';

// Internal Dependencies
import {
  Flex,
  FullWidthDiv,
  Subtitle,
} from 'components/shared';
import { FeatureFlag } from 'gql/queries/featureFlags';

// Local Dependencies
import CreateOverrideForm from './CreateOverrideForm';
import FlagListSecondaryAction from './FlagListSecondaryAction';

// Local Typings
interface Props {
  flag: FeatureFlag;
  onError: (error: ApolloError | null) => void;
}

// Component Definition
const OverridesList: FC<Props> = ({
  flag,
  onError,
}) => {
  const [deleteWarnId, setDeleteWarnId] = useState<string | null>(null);

  const [isFormOpen, setIsFormOpen] = useState(false);

  const handleToggleForm = useCallback(() => setIsFormOpen((open) => !open), []);

  const handleSuccess = useCallback(() => {
    handleToggleForm();
    onError(null);
  }, [handleToggleForm, onError]);

  return (
    <FullWidthDiv>
      <>
        <Box my={3}>
          <Subtitle>
            Organization Overrides
          </Subtitle>
        </Box>

        {flag.overrides.length ? (
          <List component={Paper}>
            {flag.overrides.map((override) => (
              <ListItem key={override.id}>
                <ListItemIcon>
                  {override.isActive
                    ? <ActiveIcon color="primary" />
                    : <InactiveIcon color="error" />}
                </ListItemIcon>

                <ListItemText
                  primary={override.organization.label}
                  secondary={override.reason}
                />

                <FlagListSecondaryAction
                  deleteWarnId={deleteWarnId}
                  onError={onError}
                  override={override}
                  setDeleteWarnId={setDeleteWarnId}
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography
            sx={{ marginTop: 2 }}
            variant="body2"
          >
            No overrides set for this feature flag.
          </Typography>
        )}

        {isFormOpen ? (
          <CreateOverrideForm
            existingOverrideOrgIds={flag.overrides.map((override) => override.organization.id)}
            featureFlagId={flag.id}
            flagLabel={flag.label}
            onCancel={handleToggleForm}
            onError={onError}
            onSuccess={handleSuccess}
          />
        ) : (
          <Flex justifyContent="flex-end">
            <Button
              color="primary"
              onClick={handleToggleForm}
              sx={{ marginTop: 2 }}
              variant="contained"
            >
              Add Override
            </Button>
          </Flex>
        )}
      </>
    </FullWidthDiv>
  );
};

export default OverridesList;
