// External Dependencies
import startCase from 'lodash.startcase';
import {
  CartesianGrid,
  Line,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Area,
} from 'recharts';
import { useTheme } from 'styled-components';

// Local Typings
interface Props<T> {
  data: T[] | undefined;
  xKey: string;
  yKey: string;
}

// Component Definition
function AreaLineChart<T>({
  data,
  xKey,
  yKey,
}: Props<T>) {
  const theme = useTheme();

  return (
    <ResponsiveContainer
      height={300}
      width="100%"
    >
      <ComposedChart
        data={data}
        margin={{
          bottom: 5,
          left: 20,
          right: 30,
          top: 5,
        }}
      >
        <defs>
          <linearGradient
            id="gradient-color"
            x1="0"
            x2="0"
            y1="0"
            y2="1"
          >
            <stop
              offset="5%"
              stopColor={theme.palette.prestoPrimaryDark}
              stopOpacity={0.5}
            />
            <stop
              offset="95%"
              stopColor={theme.palette.prestoPrimaryLight}
              stopOpacity={0.01}
            />
          </linearGradient>
        </defs>

        <XAxis dataKey={xKey} />
        <YAxis allowDecimals={false} />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip formatter={(value: number) => new Intl.NumberFormat('en').format(value)} />
        <Line
          animationDuration={500}
          dataKey={yKey}
          dot={false}
          name={startCase(yKey)}
          stroke={theme.palette.prestoPrimaryDark}
          type="monotone"
        />
        <Area
          animationDuration={500}
          dataKey={yKey}
          fill="url(#gradient-color)"
          fillOpacity={1}
          strokeWidth={2}
          tooltipType="none"
          type="monotone"
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export default AreaLineChart;
