// External Dependencies
import { useCallback } from 'react';

// Internal Dependencies
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import TableDataGrid from 'components/shared/TableDataGrid';
import { Subtitle } from 'components/shared';
import { useGetAllOrganizations } from 'hooks/api/organizations';

// Local Dependencies
import { useColumns } from './hooks';

// Component Definition
const Organizations = (): JSX.Element => {
  const {
    data: organizationsResponse,
    isLoading,
  } = useGetAllOrganizations();

  const columns = useColumns();

  const handleClickRow = useCallback((id: string): string => {
    return `/organizations/${id}`;
  }, [organizationsResponse]);

  return (
    <>
      <Subtitle>Organizations</Subtitle>

      <DataGridContainer>
        <TableDataGrid
          clickRowTo={handleClickRow}
          columns={columns}
          loading={isLoading}
          rows={organizationsResponse?.data.data ?? []}
          tableResource="organizations"
          withSearch
        />
      </DataGridContainer>
    </>
  );
};

export default Organizations;
