// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { useQueryEnhanced, useLazyQueryEnhanced } from 'hooks';

// Local Typings
interface OrgSubDetailsResponse {
  organization: GQL.IOrganization
}
// Query Definitions
export const FRAGMENT_ORGANIZATION = gql`
  fragment OrganizationFragment on Organization {
    active
    activeSubscription {
      dateOrdered
      endDate
      extendedGracePeriodDeadline
      id
      startDate
    }
    addressOne
    addressTwo
    city
    code
    creator {
      id
      email
      firstName
      lastName
    }
    daysUntilPaymentIsDue
    directors {
      authUserId
      email
      firstName
      id
      lastName
      phoneNumber
      title
    }
    district {
      addressOne
      addressTwo
      admin {
        email
        firstName
        id
        lastName
      }
      city
      id
      label
      phoneNumber
      state {
        id
        label
      }
      zipcode
    }
    entityType {
      id
      label
    }
    hasGoogleCalendar
    hasStripeId
    hasVancoPcct
    id
    isFreeTrial
    isVerified
    label
    organizationType {
      id
      label
    }
    phoneNumber
    stateId
    state {
      abbreviation
      id
      label
    }
    subscriptions {
      dateOrdered
      endDate
      extendedGracePeriodDeadline
      id
      startDate
    }
    zipcode
  }
`;

const GET_ORGANIZATION = gql`
  query Organization($id: ID) {
    organization(id: $id) {
      ...OrganizationFragment
    }
  }
  ${FRAGMENT_ORGANIZATION}
`;

const GET_ORGANIZATIONS_ALL = gql`
  query GetOrganizationsAll(
    $queryParams: QueryParams
  ) {
    organizationsAll(queryParams: $queryParams) {
      data {
        id
        label
      }
    }
  }
`;

export interface SimpleOrg {
  id: string;
  label: string;
}

interface OrganizationsAllResponse {
  organizationsAll: {
    data: SimpleOrg[];
  }
}

interface QueryParams {
  q?: string;
}

interface Variables {
  queryParams?: QueryParams;
}

export const useGetOrganization = (id?: string) =>
  useQueryEnhanced<OrgSubDetailsResponse>(
    GET_ORGANIZATION,
    {
      skip: !id,
      variables: { id },
    },
  );

export const useGetOrganizationsAll = () =>
  useLazyQueryEnhanced<
    OrganizationsAllResponse,
    Variables
  >(GET_ORGANIZATIONS_ALL);
