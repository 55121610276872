// External Dependencies
import {
  Autocomplete,
  CircularProgress,
  TextField,
} from '@mui/material';
import { FC, useEffect } from 'react';
import { useDebounce } from 'use-debounce/lib';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { SimpleOrg, useGetOrganizationsAll } from 'gql/queries/organization';
import { isMobileScreenSize } from 'state/selectors';
import useTextField from 'hooks/useTextField';

// Local Typings
interface Props {
  disabledIds?: string[];
  excludedIds?: string[];
  onSelectOrganization: (organization: SimpleOrg | null) => any; // intentionally any.
  // We don't care what the caller does with this
}

// Component Definition
const OrganizationsTypeAhead: FC<Props> = ({
  disabledIds,
  excludedIds,
  onSelectOrganization,
}) => {
  const isMobileScreen = useSelector(isMobileScreenSize);

  const textField = useTextField('');
  const [searchValue] = useDebounce(textField.value, 500);

  const [
    getOrganizations,
    {
      data,
      loading,
    },
  ] = useGetOrganizationsAll();

  useEffect(() => {
    getOrganizations({
      variables: {
        queryParams: {
          q: searchValue,
        },
      },
    });
  }, [getOrganizations, searchValue]);

  const isLoading = loading || searchValue.trim() !== textField.value.trim();

  const handleChange = (_evt: any, organization: SimpleOrg | null) => {
    onSelectOrganization(organization);
  };

  const filteredData = excludedIds
    ? data?.organizationsAll.data.filter((org) => !excludedIds.includes(org.id))
    : data?.organizationsAll.data;

  return (
    <Autocomplete
      filterOptions={(org) => org}
      getOptionDisabled={(org) => !!disabledIds?.includes(org.id)}
      getOptionLabel={(org) => org.label}
      isOptionEqualToValue={(org, value) => org.id === value.id}
      loading={isLoading}
      onChange={handleChange}
      options={filteredData ?? []}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textField}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress
                    color="inherit"
                    size={20}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          label="Search Organizations"
          size={isMobileScreen ? 'small' : 'medium'}
          variant="filled"
        />
      )}
    />
  );
};

export default OrganizationsTypeAhead;
