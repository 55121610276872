// External Dependences
import { ReactElement } from 'react';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';

// Local Typings
interface Props {
  className?: string;
  displayName: string;
  value: ReactElement | number | string | undefined | null;
}

// Local Variables
const useStyles = makeStyles({
  displayName: {
    marginRight: 12,
    whiteSpace: 'nowrap',
  },
  displayValue: {
    textAlign: 'left',
    whiteSpace: 'nowrap',
  },
  keyValueContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '16px 32px',
  },
});

// Component Definition
const KeyValueDisplay = ({
  className = '',
  displayName,
  value,
}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <div
      className={
        clsx(
          classes.keyValueContainer,
          className,
        )
      }
    >
      <Typography className={classes.displayName}>
        {displayName}
      </Typography>
      <Typography className={classes.displayValue}>
        {value}
      </Typography>
    </div>
  );
};

export default KeyValueDisplay;
