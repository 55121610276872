// External Dependencies
import { ApolloError } from '@apollo/client';
import gql from 'graphql-tag';

// Internal Dependencies
import { useMutationEnhanced } from 'hooks';
import { FeatureFlag } from 'gql/queries/featureFlags';

// Local Typings
interface UpdateResponse {
  updateFeatureFlag: FeatureFlag;
}

interface UpdateVariables {
  id: number;
  input: {
    allowOrganizationOverrides?: boolean;
    isActive?: boolean;
  }
}

interface CreateOverrideResponse {
  createFeatureFlagOverride: boolean;
}

interface DeleteOverrideResponse {
  deleteFeatureFlagOverride: boolean;
}

interface CreateOverrideVariables {
  input: {
    isActive: boolean;
    featureFlagId: number;
    organizationId: string;
    reason: string;
  }
}

interface DeleteOverrideVariables {
  id: string;
}

// Local Variables
const UPDATE_FEATURE_FLAG = gql`
  mutation UpdateFeatureFlag(
    $id: Int!
    $input: UpdateFeatureFlagInput!
  ) {
    updateFeatureFlag(
      id: $id
      input: $input
    ) {
      allowOrganizationOverrides
      description
      id
      isActive
      label
      overrides {
        id
        isActive
        organization {
          id
          label
        }
        reason
      }
    }
  }
`;

const CREATE_FEATURE_FLAG_OVERRIDE = gql`
  mutation CreateFeatureFlagOverride(
    $input: CreateFeatureFlagOverrideInput!
  ) {
    createFeatureFlagOverride(input: $input)
  }
`;

const DELETE_FEATURE_FLAG_OVERRIDE = gql`
  mutation DeleteFeatureFlagOverride(
    $id: String!
  ) {
    deleteFeatureFlagOverride(id: $id)
  }
`;

export const useUpdateFeatureFlag = (options: {
  onError: (e: ApolloError | null) => void;
}) =>
  useMutationEnhanced<UpdateResponse, UpdateVariables>(
    UPDATE_FEATURE_FLAG,
    {
      onCompleted: () => {
        options.onError(null);
      },
      onError: options.onError,
      refetchQueries: () => ['FeatureFlags'],
    },
  );

export const useCreateOverride = (options: {
  onCompleted?: () => void;
  onError: (e: ApolloError) => void;
}) =>
  useMutationEnhanced<
    CreateOverrideResponse,
    CreateOverrideVariables
  >(
    CREATE_FEATURE_FLAG_OVERRIDE,
    {
      onCompleted: options.onCompleted,
      onError: options.onError,
      refetchQueries: () => ['FeatureFlags'],
    },
  );

export const useDeleteOverride = (options: {
  onError: (e: ApolloError | null) => void;
}) =>
  useMutationEnhanced<
    DeleteOverrideResponse,
    DeleteOverrideVariables
  >(
    DELETE_FEATURE_FLAG_OVERRIDE,
    {
      onCompleted: () => {
        options.onError(null);
      },
      onError: options.onError,
      refetchQueries: () => ['FeatureFlags'],
    },
  );
