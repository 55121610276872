// Internal Dependencies
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import TableDataGrid from 'components/shared/TableDataGrid';
import { useGetAllImports } from 'hooks/api/imports';

// Local Dependencies
import { useColumns } from './hooks';

// Local Variables
const handleRowClick = (id: string) => `/imports/${id}`;

// Component Definition
const DistrictsTable = (): JSX.Element => {
  const {
    data: importResponse,
    isLoading,
  } = useGetAllImports();

  const columns = useColumns();

  return (
    <DataGridContainer>
      <TableDataGrid
        clickRowTo={handleRowClick}
        columns={columns}
        loading={isLoading}
        rows={importResponse?.data.data.imports ?? []}
        tableResource="imports"
        withSearch
      />
    </DataGridContainer>
  );
};

export default DistrictsTable;
