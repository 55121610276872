// External Dependencies
import { FC } from 'react';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import {
  ConfirmationDialog,
  EnhancedAlert,
} from 'components/shared';
import { useVerifyOrganization } from 'gql/mutations/organizations';

// Local Typings
interface Props {
  onClose: () => void;
  open: boolean;
  organizationId: string;
}

// Component Definition
const VerifyOrganizationDialog: FC<Props> = ({
  onClose,
  open,
  organizationId,
}) => {
  const [
    verifyOrganization,
    {
      error,
      loading,
    },
  ] = useVerifyOrganization({
    onCompleted: onClose,
  });

  const handleVerifyOrganization = () => {
    verifyOrganization({
      variables: {
        input: {
          id: organizationId,
          isVerified: true,
        },
      },
    });
  };

  return (
    <ConfirmationDialog
      confirmButtonAction={handleVerifyOrganization}
      confirmButtonText="Yes, Verify"
      description={(
        <>
          <Typography gutterBottom>
            You have verified that this organization is legitimate.
          </Typography>

          <Typography>
            Once verified, this organization will be able to use restricted features.
          </Typography>

          <Collapse in={Boolean(error)}>
            <EnhancedAlert
              severity="error"
              sx={{ marginTop: 2 }}
            >
              {error?.message}
            </EnhancedAlert>
          </Collapse>
        </>
      )}
      isSubmitting={loading}
      maxWidth="xs"
      onClose={onClose}
      open={open}
      title="Verify organization?"
    />
  );
};

export default VerifyOrganizationDialog;
