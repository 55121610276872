// External Dependencies
import { FC, useCallback, useMemo } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import linkifyHtml from 'linkify-html';
import moment from 'moment';
import sanitizeHtml from 'sanitize-html';
import styled from 'styled-components';

// Internal Dependencies
import { APP_NAME, CONTACT_INFO, SHORT_APP_NAME } from 'utils/constants';
import { AdminUser } from 'state/reducers/user_reducer';
import { EnhancedCard, ShowPageDataDisplay } from 'components/shared';
import { useGetCurrentUser } from 'hooks/useGetCurrentUser';

// Local Typings
interface Props {
  feedback: GQL.IFeedback;
}

// Local Variables
const StyledCardContent = styled(CardContent)(({ theme }) => ({
  '&:last-child': {
    paddingBottom: theme.spacing(2),
  },
  '.bodyCard': {
    backgroundColor: theme.palette.feedbackDetails,
    marginTop: theme.spacing(2),
  },
  '.bodyCardContent': {
    paddingBottom: 0,
    paddingTop: 0,
  },
}));

const rowOfHyphens = '%2D%2D%2D%2D%2D%2D%2D';
const oneNewLine = '%0D%0A';
const twoNewLines = '%0D%0A%0D%0A';

// Component Definition
const FeedbackResponseCard: FC<Props> = ({ feedback }) => {
  const user = useGetCurrentUser() as AdminUser;

  // Thanks to this Stack Overflow post for the reminder on how to do this:
  //  https://stackoverflow.com/a/50157224/5943040
  const dateToFromNowDaily = useCallback((myDate: string) => {
    // Get from-now for this date
    const fromNow = moment(myDate).format('MMMM Do, YYYY');

    // Ensure the date is displayed with today, yesterday, et al.
    return moment(myDate).calendar(null, {
      // When the date is closer, specify custom values
      lastDay: '[yesterday]',
      lastWeek: '[last] dddd',
      sameDay: '[today]',
      // When the date is further away, use from-now functionality
      sameElse() {
        return `on [${fromNow}]`;
      },
    });
  }, []);

  // Quick way to take the raw HTML and convert it to plain text
  const getBodyInPlainText = useCallback(() => {
    const { body } = feedback;

    // We attempt to preserve some whitespace formatting in the email body
    // Add new line after each <br> tag
    const bodyWithModifiedBreakTags = body.replace(/<br\s*\/?>/mg, oneNewLine);

    // Add two new lines after </p> tag
    const bodyWithModifiedClosingParagraphTags = bodyWithModifiedBreakTags.replace(/<\/p>/mg, twoNewLines);

    const div = document.createElement('div');
    div.innerHTML = bodyWithModifiedClosingParagraphTags;

    return div.textContent;
  }, [feedback]);

  // We send a detailed set of data via a mailto link so we have a consistent way to
  //  respond to User feedback.
  // - CC support email
  // - Subject line with app name and feedback subject, helps show our email by subject
  // - Body with User's first name, date of submission, and feedback body
  const emailHref = useMemo(
    () => {
      const subject = encodeURIComponent(`${APP_NAME} User Feedback | ${feedback.subject}`);
      const body = `${feedback.submittedBy.firstName},${twoNewLines}We%20received%20this%20${SHORT_APP_NAME}%20user%20feedback%20from%20you%20${dateToFromNowDaily(feedback.submittedAt)}%3A${oneNewLine}${rowOfHyphens}${twoNewLines}${getBodyInPlainText()}${rowOfHyphens}${twoNewLines}`;
      const prestoAdminEmail = user.email;
      // Putting the presto admin email in the URL in case they have multiple
      //  Gmail accounts and a non-presto account is their default
      const baseUrl = `https://mail.google.com/mail/b/${prestoAdminEmail}/`;
      const queryString = `fs=1&tf=cm&source=mailto&cc=${CONTACT_INFO.SUPPORT_EMAIL}&su=${subject}&to=${feedback.submittedBy.email}&body=${body}`;

      return `${baseUrl}?${queryString}`;
    },
    [
      dateToFromNowDaily,
      feedback.subject,
      feedback.submittedAt,
      feedback.submittedBy.email,
      feedback.submittedBy.firstName,
      getBodyInPlainText,
      user.email,
    ],
  );

  return (
    <EnhancedCard>
      <StyledCardContent>
        <ShowPageDataDisplay
          label="Subject"
          value={feedback.subject}
        />

        <EnhancedCard className="bodyCard">
          <CardContent className="bodyCardContent">
            <Typography
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(linkifyHtml(feedback.body)),
              }}
            />
          </CardContent>
        </EnhancedCard>

        <Box
          alignItems="center"
          display="flex"
          justifyContent="flex-end"
          mt={2}
        >
          <Button
            href={emailHref}
            rel="noopener noreferrer"
            size="small"
            target="_blank"
            variant="outlined"
          >
            Email User
          </Button>
        </Box>
      </StyledCardContent>
    </EnhancedCard>
  );
};

export default FeedbackResponseCard;
