// External Dependencies
import { lighten } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import MembershipItem from './MembershipItem';

// Local Typings
interface Props {
  memberships?: DB.OrganizationMembership[];
}

// Local Variables
const StyledRoot = styled(Box)(({ theme }) => ({
  '.membership-section-header': {
    marginLeft: theme.spacing(2),
  },
  backgroundColor: lighten(theme.palette.stripeBlue['300'], 0.92),
  marginTop: theme.spacing(2),
  paddingTop: theme.spacing(2),
}));

// Component Definition
const MembershipSection = ({ memberships }: Props): JSX.Element | null => {
  return (
    <StyledRoot>
      <Typography
        className="subheader membership-section-header"
        gutterBottom
      >
        Memberships
      </Typography>

      <Box
        display="flex"
        flexWrap="wrap"
        gap={2}
      >
        {memberships?.map((membership) => {
          return (
            <MembershipItem
              key={membership.id}
              membership={membership}
            />
          );
        })}
      </Box>
    </StyledRoot>
  );
};

export default MembershipSection;
