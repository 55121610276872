// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { useQueryEnhanced } from 'hooks';

// Local Typings
interface DistrictsAllResponse {
  districts: GQL.IDistrictIndex[];
}

// Query Definitions
export const FRAGMENT_DISTRICT = gql`
  fragment DistrictFragment on District {
    addressOne
    addressTwo
    admin {
      email
      firstName
      id
      lastName
      phoneNumber
      title
    }
    city
    districtAssistants {
      email
      id
      firstName
      lastName
      phoneNumber
    }
    id
    invitations {
      accepted
      activationCode
      createdAt
      id
      recipientEmail
    }
    isVerified
    label
    licensePriceInCents
    organizations {
      creator {
        email
        firstName
        id
        lastName
      }
      entityType {
        id
        label
      }
      id
      label
      organizationType {
        id
        label
      }
    }
    phoneNumber
    state {
      id
      label
    }
    taxExemptFormS3Key
    zipcode
  }
`;

const GET_DISTRICTS = gql`
  query Districts {
    districts {
      dfaMemberEmail
      dfaMemberFirstName
      dfaMemberId
      dfaMemberLastName
      id
      isVerified
      label
      latestPaymentDate
      licensePriceInCents
      numberOfActiveLicenses
      numberOfComplimentaryActiveLicenses
      numberOfOrganizations
      numberOfUnredeemedLicenses
      taxExemptFormS3Key
      totalPaidLifetimeInCents
    }
  }
`;

const GET_DISTRICT_BY_ID = gql`
  query DistrictById($id: ID!) {
    districtById(id: $id) {
      ...DistrictFragment
    }
  }
  ${FRAGMENT_DISTRICT}
`;

export const useGetDistricts = () =>
  useQueryEnhanced<DistrictsAllResponse>(
    GET_DISTRICTS,
  );

export const useGetDistrictById = (id?: string) =>
  useQueryEnhanced<{
    districtById: GQL.IDistrict;
  }>(
    GET_DISTRICT_BY_ID,
    {
      skip: !id,
      variables: { id },
    },
  );
