// External Dependencies
import {
  useCallback, useRef, useState,
} from 'react';
import { alpha, lighten } from '@mui/material/styles';
import { getFullName } from '@presto-assistant/api_types/utils';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import List from '@mui/material/List';
import LogoutIcon from '@mui/icons-material/PowerSettingsNew';
import Popover, { PopoverOrigin } from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import styled, { useTheme } from 'styled-components';

// Internal Dependencies
import { AdminUser } from 'state/reducers/user_reducer';
import { getSearchWithRedirectUrl } from 'utils/lib/redirect_user';
import { isMobileScreenSize } from 'state/selectors';

// Local Dependencies
import {
  EnhancedCardContent,
  EnhancedMenuItem,
} from './shared';

// Local Typings
interface Props {
  user: AdminUser;
}

// Local Variables
const StyledButton = styled(Button)(({ theme }) => ({
  '&.user-name-container': {
    '&:hover, &:focus': {
      background: lighten(theme.palette.prestoPrimaryMain, 0.1),
      border: `1px solid ${alpha(theme.palette.common.white, 0.9)}`,
    },
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(0.5),
    },
    alignItems: 'center',
    border: `1px solid ${alpha(theme.palette.common.white, 0.8)}`,
    display: 'flex',
    marginRight: theme.spacing(2),
    padding: '5px 8px 5px 10px',
  },
  '.user-name-text': {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.8rem',
    },
    color: theme.palette.common.white,
    marginLeft: 8,
    marginRight: 6,
  },
}));

const StyledPopover = styled(Popover)(({ theme }) => ({
  '.divider-spaced': {
    margin: `${theme.spacing(1)} 0`,
  },
  '.list-element': {
    width: '100%',
  },
  '.popover-paper': {
    minWidth: 264,
  },
  '.profile-card-content': {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(0.5),
  },
  '.user-name-avatar': {
    backgroundColor: theme.palette.prestoPrimaryLight,
    marginBottom: theme.spacing(1.5),
  },
}));

const anchorOrigin = { horizontal: 'right', vertical: 'bottom' };
const transformOrigin = { horizontal: 'right', vertical: 'top' };

// Component Definition
const AccountSubMenu = ({ user }: Props): JSX.Element | null => {
  const navigate = useNavigate();

  const {
    palette: {
      common,
    },
  } = useTheme();

  const isMobileScreen = useSelector(isMobileScreenSize);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleClickAccountMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleLogout = useCallback(async () => {
    const queryString = getSearchWithRedirectUrl();

    await handleClose();
    navigate(`/logout?${queryString}`);
  }, [handleClose, navigate]);

  if (!user) {
    return null;
  }

  return (
    <>
      <StyledButton
        aria-controls={isMenuOpen ? 'account-menu' : undefined}
        aria-haspopup="true"
        className="user-name-container"
        endIcon={<KeyboardArrowDownIcon htmlColor={common.white} />}
        onClick={handleClickAccountMenu}
        ref={buttonRef}
        size={isMobileScreen ? 'small' : 'medium'}
        startIcon={<AccountCircle htmlColor={common.white} />}
        variant="outlined"
      >
        <Typography
          className="user-name-text"
          color="primary"
          variant="button"
        >
          {(user as AdminUser).firstName}
        </Typography>
      </StyledButton>

      <StyledPopover
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin as PopoverOrigin}
        classes={{
          paper: 'popover-paper',
        }}
        id="account-menu"
        onClose={handleClose}
        open={isMenuOpen}
        role="menu"
        transformOrigin={transformOrigin as PopoverOrigin}
      >
        <EnhancedCardContent className="profile-card-content">
          <Avatar className="user-name-avatar">
            <AccountCircle />
          </Avatar>

          <Typography>
            {getFullName(user)}
          </Typography>

          <Typography
            color="textSecondary"
            sx={{ mt: 0.5 }}
            variant="body2"
          >
            {(user as AdminUser).email}
          </Typography>

          <Divider
            className="divider-spaced"
            flexItem
          />

          <List
            className="list-element"
            disablePadding
          >
            <EnhancedMenuItem
              icon={LogoutIcon}
              onClick={handleLogout}
              text="Sign Out"
            />
          </List>
        </EnhancedCardContent>
      </StyledPopover>
    </>
  );
};

export default AccountSubMenu;
