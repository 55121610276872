// External Dependencies
import {
  Box,
  Typography,
} from '@mui/material';
import { FC } from 'react';

// Internal Dependencies
import {
  BackToButton,
  EnhancedCard,
  Subtitle,
} from 'components/shared';

// Component Definition
const DistrictsNew: FC = () => (
  <>
    <Box mb={2}>
      <BackToButton
        context="All Districts"
        navigateTo="/districts"
      />
    </Box>

    <Subtitle>
      Create a District
    </Subtitle>

    <EnhancedCard
      sx={{
        marginTop: 2,
        padding: 2,
      }}
    >
      <Typography>Coming soon...</Typography>
    </EnhancedCard>
  </>
);

export default DistrictsNew;
