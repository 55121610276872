// External Dependencies
import { NavigateFunction } from 'react-router-dom';

// Local Dependencies
import { stringifyQueryParams } from './stringify_query_params';
import parseSearch from './parse_search';

// Local Typings
export interface NavigateSearchArguments {
  navigate: NavigateFunction,
  searchOverrides: object
}

export function navigateSearch({
  navigate,
  searchOverrides,
}: NavigateSearchArguments) {
  const { pathname, search } = window.location;

  const parsedSearch = parseSearch(search);

  const newSearch = stringifyQueryParams({
    ...parsedSearch,
    ...searchOverrides,
  });

  navigate(`${pathname}?${newSearch}`);
}
