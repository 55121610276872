// External Dependencies
import SvgIcon from '@mui/material/SvgIcon';
import makeStyles from '@mui/styles/makeStyles';

// Local Variables
const useStyles = makeStyles({
  root: {
    height: 64,
    width: 64,
  },
});

// Component Definition
const PrestoLogoGradientSvg = (props: any) => {
  const classes = useStyles();

  return (
    <SvgIcon
      className={classes.root}
      viewBox="0 0 208.74 156.99"
      {...props}
    >
      <defs>
        <style>{'.cls-1{fill:url(#linear-gradient);}.cls-2{fill:url(#linear-gradient-2);}'}</style>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="linear-gradient"
          x1="131.51"
          x2="127.5"
          y1="55.25"
          y2="101.08"
        >
          <stop
            offset="0"
            stopColor="#00d9e9"
          />
          <stop
            offset="1"
            stopColor="#966aff"
          />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="112.65"
          x2="98.96"
          xlinkHref="#linear-gradient"
          y1="-1.88"
          y2="154.59"
        />
      </defs>
      <g
        data-name="Layer 2"
        id="Layer_2"
      >
        <g
          data-name="Layer 1"
          id="Layer_1-2"
        >
          <circle
            className="cls-1"
            cx="129.5"
            cy="78.16"
            r="23"
          />
          <path
            className="cls-2"
            d="M166.34,8.94c-14.1-7-26-10.47-48.71-8.29A65.5,65.5,0,0,0,93.29,8.41c-9.16,5.23-9.16,5.23-19.08,14.06L5.7,91A19.46,19.46,0,0,0,33.22,118.5h0L101.73,50A39.89,39.89,0,1,1,145,115.16a39.85,39.85,0,0,1-43.22-8.76l-7.85-7.86c-7.46-7.46-19.62-8-27.34-.82a19.47,19.47,0,0,0-.51,28l8.18,8.18A78.81,78.81,0,0,0,207.3,63.16a70,70,0,0,0-3.25-10.26c-2.8-7.38-2.92-8.51-7.16-15.17-12.08-19-20.66-22.7-30.55-28.79"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default PrestoLogoGradientSvg;
