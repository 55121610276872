// External Dependencies
import { useQueryClient } from '@tanstack/react-query';
import gql from 'graphql-tag';

// Internal Dependencies
import { useMutationEnhanced } from 'hooks';

// Mutation Definitions
export const REMOVE_AUTH_USER = gql`
  mutation RemoveAuthUser (
    $input: RemoveAuthUserInput!
  ) {
    removeAuthUser(input: $input)
  }
`;

export const useRemoveAuthUser = (args: {
  onCompleted: () => void;
}) => {
  const queryClient = useQueryClient();

  return useMutationEnhanced<
    {
      memberId: string;
    },
    GQL.IRemoveAuthUserOnMutationArguments
  >(
    REMOVE_AUTH_USER,
    {
      onCompleted: () => {
        queryClient.invalidateQueries(['searchMemberByEmail']);
        args.onCompleted();
      },
    },
  );
};
