import Box from '@mui/material/Box';

// Internal Dependencies
import {
  BackToButton,
  Subtitle,
} from 'components/shared';

// Local Dependencies
import InventoryVendorsTable from './InventoryVendorsTable';

// Component Definition
const InventoryVendorsAll = (): JSX.Element => {
  return (
    <>
      <Box marginBottom={3}>
        <BackToButton
          context="All Vendors"
          navigateTo="/vendors"
        />
      </Box>

      <Subtitle>
        Inventory Vendors
      </Subtitle>

      <InventoryVendorsTable />
    </>
  );
};

export default InventoryVendorsAll;
