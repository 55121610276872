import { AxiosError } from 'axios';
import { FileWithPath } from 'react-dropzone';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {
  BackToButton,
  EnhancedAlert,
  SaveButton,
  ShowPageDataDisplay,
  UploadDropzone,
} from 'components/shared';
import { convertAxiosErrorToYupError } from 'utils/convertAxiosErrorToYupError';
import {
  useAddTaxExemptFormS3Key,
  useCreateTaxExemptFormPresignedUrl,
} from 'hooks/api/district';
import { useGetDistrictById } from 'gql/queries/districts';
import { useIsOpen } from 'hooks/useIsOpen';

import ConfirmUpdateDistrictTaxExemptFormDialog from './ConfirmUpdateDistrictTaxExemptFormDialog';

// Component Definition
const TaxExemptFormNew: React.FC = () => {
  const navigate = useNavigate();
  const { districtId } = useParams();

  const [taxExemptFormFile, setTaxExemptFormFile] = useState<File | null>(null);
  const [taxExemptS3GetUrl, setTaxExemptS3GetUrl] = useState<string | null>(null);
  const [taxExemptS3PutUrl, setTaxExemptS3PutUrl] = useState<string | null>(null);
  const [isUploadingToS3, setIsUploadingToS3] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [showAddFileMessage, setShowAddFileMessage] = useState(false);
  const [hasConfirmedUpdate, setHasConfirmedUpdate] = useState(false);

  const {
    handleClose: handleCloseConfirmUpdateDialog,
    handleOpen: handleOpenConfirmUpdateDialog,
    isOpen: isConfirmUpdateDialogOpen,
  } = useIsOpen();

  const { data, loading } = useGetDistrictById(districtId);

  const districtHasTaxExemptForm = useMemo(() => {
    if (!data) {
      return false;
    }

    return Boolean(data.districtById.taxExemptFormS3Key);
  }, [data]);

  const districtShowPagePath = `/districts/${districtId}`;

  const {
    data: presignedUrlData,
    error: presignedUrlError,
    isLoading: isGeneratingPresignedUrl,
    mutate: createTaxExemptFormPresignedUrl,
  } = useCreateTaxExemptFormPresignedUrl();

  const {
    error: addTaxExemptFormS3KeyError,
    isLoading: isAddingTaxExemptFormS3Key,
    mutate: addTaxExemptFormS3Key,
  } = useAddTaxExemptFormS3Key();

  // When the presignedUrlData changes, set the taxExemptS3Url
  useEffect(() => {
    if (presignedUrlData) {
      setTaxExemptS3GetUrl(presignedUrlData.data?.presignedUrl?.getUrl);
      setTaxExemptS3PutUrl(presignedUrlData.data?.presignedUrl?.putUrl);
    }
  }, [presignedUrlData]);

  const handleConfirmUpdate = useCallback(() => {
    setHasConfirmedUpdate(true);
    handleCloseConfirmUpdateDialog();
  }, [handleCloseConfirmUpdateDialog]);

  // The user presses the CTA button that reads "Upload Tax-Exempt Form"
  // This will send the file to S3 and update the Presto database with the S3 key
  const handleUploadToS3 = useCallback(async () => {
    if (!taxExemptS3PutUrl) {
      return setHasError(true);
    }

    setIsUploadingToS3(true);

    try {
      const res = await fetch(taxExemptS3PutUrl, {
        body: taxExemptFormFile,
        method: 'PUT',
      });

      if (!res.ok) {
        throw new Error('Unable to upload tax-exempt form file.');
      }

      if (hasError) {
        setHasError(false);
      }

      setIsUploadingToS3(false);

      return addTaxExemptFormS3Key({
        districtId: districtId!,
        payload: {
          taxExemptFormS3Url: taxExemptS3GetUrl ?? '',
        },
      });
    } catch (error) {
      return setHasError(true);
    }
  }, [
    addTaxExemptFormS3Key,
    districtId,
    hasError,
    taxExemptFormFile,
    taxExemptS3GetUrl,
    taxExemptS3PutUrl,
  ]);

  // When the taxExemptS3Url changes, call the mutation to upload the file
  useEffect(() => {
    if (Boolean(0) && taxExemptS3GetUrl && taxExemptS3PutUrl) {
      handleUploadToS3();
    }
  }, [handleUploadToS3, taxExemptS3GetUrl, taxExemptS3PutUrl]);

  const yupErrors = useMemo(() => {
    if (!presignedUrlError || !addTaxExemptFormS3KeyError) {
      return null;
    }

    const yupCreatePresignedUrlError = convertAxiosErrorToYupError(presignedUrlError as AxiosError);
    const yupAddTaxExemptFormS3KeyError = convertAxiosErrorToYupError(
      addTaxExemptFormS3KeyError as AxiosError,
    );

    if (yupCreatePresignedUrlError) {
      return Object.values(yupCreatePresignedUrlError);
    }

    if (yupAddTaxExemptFormS3KeyError) {
      return Object.values(yupAddTaxExemptFormS3KeyError);
    }

    return null;
  }, [addTaxExemptFormS3KeyError, presignedUrlError]);

  const handleGoToDistrictShowPage = useCallback(() => {
    navigate(districtShowPagePath);
  }, [navigate, districtShowPagePath]);

  const hasTaxExemptFormAndHasNotConfirmedUpdate = districtHasTaxExemptForm && !hasConfirmedUpdate;

  const handleUploadFile = useCallback((acceptedFiles: FileWithPath[]) => {
    const file = acceptedFiles[0];

    setTaxExemptFormFile(file);

    try {
      if (hasError) {
        setHasError(false);
      }

      return createTaxExemptFormPresignedUrl({
        districtId: districtId!,
        payload: {
          filename: file.name,
          filetype: file.type,
        },
      });
    } catch (err) {
      setTaxExemptFormFile(null);
      return setHasError(true);
    }
  }, [createTaxExemptFormPresignedUrl, districtId, hasError]);

  const handlePressUploadDropzoneContainer = useCallback(() => {
    if (hasTaxExemptFormAndHasNotConfirmedUpdate) {
      handleOpenConfirmUpdateDialog();
      return null;
    }

    if (showAddFileMessage) {
      return setShowAddFileMessage(false);
    }

    return null;
  }, [handleOpenConfirmUpdateDialog, hasTaxExemptFormAndHasNotConfirmedUpdate, showAddFileMessage]);

  const handlePressUploadButton = useCallback(() => {
    if (!taxExemptFormFile) {
      setShowAddFileMessage(true);
    } else {
      handleUploadToS3();
    }
  }, [handleUploadToS3, taxExemptFormFile]);

  const isLoading = isGeneratingPresignedUrl || isUploadingToS3 || isAddingTaxExemptFormS3Key;

  return (
    <>
      <Container fixed>
        <Box marginBottom={4}>
          <BackToButton
            context="District Detail"
            navigateTo={districtShowPagePath}
          />
        </Box>

        <Stack
          direction="column"
          spacing={2}
        >
          <Card
            sx={{
              borderRadius: '12px',
            }}
            variant="outlined"
          >
            <Typography
              component="h1"
              gutterBottom
              sx={{
                marginLeft: 2,
                marginTop: 2,
              }}
              variant="h5"
            >
              Add Tax-Exempt Form
            </Typography>

            <CardContent
              sx={{
                padding: 2,
              }}
            >
              {
                loading
                  ? <CircularProgress />
                  : (
                    <Typography
                      gutterBottom
                      variant="h6"
                    >
                      {data?.districtById.label}
                    </Typography>
                  )
              }

              <ShowPageDataDisplay
                label="Currently Has Tax-Exempt Form?"
                type="boolean"
                value={districtHasTaxExemptForm}
              />

              <div
                onClick={handlePressUploadDropzoneContainer}
                role="presentation"
                tabIndex={-1}
              >
                <UploadDropzone
                  disabled={isLoading}
                  helperText={`Upload a tax-exempt form for ${data?.districtById.label}`}
                  marginTop={1}
                  multiple={false}
                  onDrop={hasTaxExemptFormAndHasNotConfirmedUpdate
                    ? null
                    : handleUploadFile}
                  preventFileDialogOpen={hasTaxExemptFormAndHasNotConfirmedUpdate}
                  rejectedDropErrorMessage="Unable to upload files"
                  showAcceptedFiles
                />
              </div>

              {yupErrors?.map((errorMessage) => (
                <EnhancedAlert
                  key={errorMessage as string}
                  severity="error"
                  sx={{ marginTop: 2 }}
                >
                  {errorMessage}
                </EnhancedAlert>
              ))}

              <Collapse in={hasError}>
                <EnhancedAlert
                  severity="error"
                  sx={{ marginTop: 2 }}
                  title="Something went sideways"
                >
                  Please try again or refresh the page.
                </EnhancedAlert>
              </Collapse>

              <Collapse in={showAddFileMessage}>
                <EnhancedAlert
                  severity="info"
                  sx={{ marginTop: 2 }}
                  title="No file added"
                >
                  Press the dashed box above or drop a file there.
                </EnhancedAlert>
              </Collapse>
            </CardContent>

            <CardActions
              sx={{
                justifyContent: 'flex-end',
                padding: 2,
              }}
            >
              <Button
                onClick={handleGoToDistrictShowPage}
                startIcon={<ArrowBackIcon />}
                sx={{
                  borderRadius: '20px',
                }}
                variant="outlined"
              >
                Go Back
              </Button>

              <SaveButton
                disabled={!taxExemptFormFile && showAddFileMessage}
                isSaving={isGeneratingPresignedUrl || isUploadingToS3 || isAddingTaxExemptFormS3Key}
                onClick={handlePressUploadButton}
                startIcon={<CloudUploadIcon />}
                sx={{
                  borderRadius: '20px',
                }}
                variant="contained"
              >
                Upload Tax-Exempt Form
              </SaveButton>
            </CardActions>
          </Card>
        </Stack>
      </Container>

      <ConfirmUpdateDistrictTaxExemptFormDialog
        districtName={data?.districtById.label}
        onClose={handleCloseConfirmUpdateDialog}
        onConfirm={handleConfirmUpdate}
        open={isConfirmUpdateDialogOpen}
      />
    </>
  );
};

export default TaxExemptFormNew;
