export const convertDollarsToCents = (priceInDollars: number) => Math.round(priceInDollars * 100);

export const convertCentsToDollars = (priceInCents: number) => priceInCents / 100;

export const displayPriceStringFromDollarAmount = (priceInDollars: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency',
  });

  return formatter.format(priceInDollars);
};

export const formatDollarAmount = (priceInDollars: number) => `$${(priceInDollars).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
