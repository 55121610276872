import { shallowEqual, useSelector } from 'react-redux';

/**
 *
 * React Redux provides a helper function called `shallowEqual` that
 *  can be used to check if the value changes for the selected data.
 *  https://react-redux.js.org/api/hooks#equality-comparisons-and-updates
 *
 * This function is kindly borrowed from the React Redux documentation.
 *  https://react-redux.js.org/api/hooks#recipe-useshallowequalselector
 *
 * ⚠️ Only use this function when the value returned from the selector is a
 *  non-primitive type (e.g. object, array, etc).
 */

// Hook Definition
export function useShallowEqualSelector(selector: any) {
  return useSelector(selector, shallowEqual);
}
