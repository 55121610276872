// External Dependencies
import {
  Alert,
  Box,
  Typography,
} from '@mui/material';
import {
  FC, useCallback, useState,
} from 'react';
import { ApolloError } from '@apollo/client';

// Internal Dependencies
import {
  BackToButton,
  EnhancedCard,
  Subtitle,
} from 'components/shared';
import { useGetFeatureFlags } from 'gql/queries/featureFlags';
import { useIsPrestoOwner } from 'hooks/useIsPrestoOwner';
import RestrictedContentCard from 'components/shared/RestrictedContentCard';

// Local Dependencies
import FlagList from './FlagList';

// Component Definition
const FeatureFlagsPage: FC = () => {
  const [error, setError] = useState<ApolloError | null>(null);

  // For some reason, apollo does not update the cache on refetch
  // Passing in refetchCount as a key to FlagList
  const [refetchCount, setRefetchCount] = useState(0);

  const isPrestoOwner = useIsPrestoOwner();

  const {
    data,
    loading,
    refetch,
  } = useGetFeatureFlags();

  const handleError = useCallback(async (apolloError: ApolloError | null) => {
    if (apolloError) {
      await refetch();
      setRefetchCount((count) => count + 1);
    }

    setError(apolloError);
  }, [refetch]);

  if (!isPrestoOwner) {
    return <RestrictedContentCard />;
  }

  return (
    <>
      <Box mb={2}>
        <BackToButton
          context="Server Settings"
          navigateTo="/server_settings"
        />
      </Box>

      <Subtitle>
        Feature Flags
      </Subtitle>

      <Box my={2}>
        <EnhancedCard>
          {loading && !data && (
            <Box p={2}>
              <Typography>
                Loading...
              </Typography>
            </Box>
          )}

          {data && (
            <FlagList
              featureFlags={data.featureFlags}
              key={refetchCount}
              onError={handleError}
            />
          )}
        </EnhancedCard>
      </Box>

      {error?.message && (
        <Alert severity="error">
          {error.message}
        </Alert>
      )}
    </>
  );
};

export default FeatureFlagsPage;
