// External Dependencies
import { DistrictLicensesResponseItem } from '@presto-assistant/api_types/api/admin/district';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import { dateColumn } from 'utils/lib/tableColumns';
import { useGetDistrictById } from 'gql/queries/districts';

// Hook Definition
export const useColumns = () => {
  const { districtId } = useParams();

  const { data } = useGetDistrictById(districtId);

  const organizationsData = data?.districtById.organizations;

  return useMemo(() => {
    const columns: DataGridColDef<DistrictLicensesResponseItem>[] = [
      dateColumn({
        field: 'purchasedAt',
        headerName: 'Purchased',
        hideable: true,
      }),
      dateColumn({
        field: 'redeemedAt',
        headerName: 'Redeemed',
        hideable: true,
      }),
      {
        field: 'organizationLabel',
        headerName: 'Organization',
        minWidth: 200,
        type: 'singleSelect',
        valueOptions: () => {
          if (organizationsData) {
            return organizationsData.map((org) => {
              return { label: org.label, value: org.label };
            });
          }

          return [];
        },
      },
      {
        field: 'isActive',
        headerName: 'Active',
        type: 'boolean',
      },
      {
        field: 'numberOfMonths',
        headerName: '# of Months',
      },
      {
        field: 'redemptionCode',
        headerName: 'Redemption Code',
        minWidth: 360,
      },
      {
        field: 'authorizedBy',
        headerName: 'Purchased By',
        minWidth: 180,
      },
      {
        field: 'isComplimentary',
        headerName: 'Complimentary',
        type: 'boolean',
      },
      dateColumn({
        field: 'expiresAt',
        headerName: 'Use By',
        hideable: true,
      }),
    ];

    return columns;
  }, []);
};
