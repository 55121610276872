// External Dependencies
import { useDispatch } from 'react-redux';
import { useEffect, useMemo } from 'react';
import {
  gridFilterModelSelector,
  gridQuickFilterValuesSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';

// Internal Dependencies
import { TableResource, updateTableQueryParams } from 'state/table/actions';

// Hook Definitions
export const useUpdateParams = (
  tableResource: TableResource | undefined,
  search: string,
) => {
  const dispatch = useDispatch();

  return useEffect(() => {
    if (tableResource) {
      dispatch(updateTableQueryParams({
        key: tableResource,
        value: search,
      }));
    }
  }, [dispatch, search, tableResource]);
};

export const useGetFilterCounts = () => {
  const apiRef = useGridApiContext();

  // Current content in the quick filter i.e. search bar
  const currentQuickFilterValue = useGridSelector(apiRef, gridQuickFilterValuesSelector);
  const hasQuickFilterValue = currentQuickFilterValue && currentQuickFilterValue.length > 0;

  // Current state for all applied filters
  const currentFilterModelValue = useGridSelector(apiRef, gridFilterModelSelector);
  const appliedFilterCount = currentFilterModelValue.items.length;

  return useMemo(() => ({
    appliedFilterCount,
    currentQuickFilterValue,
    hasQuickFilterValue,
  }), [appliedFilterCount, currentQuickFilterValue, hasQuickFilterValue]);
};
