// External Dependencies
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import CircularProgress from '@mui/material/CircularProgress';
import ClickAwayListener, { ClickAwayListenerProps } from '@mui/material/ClickAwayListener';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popover, { PopoverProps } from '@mui/material/Popover';
import {
  MouseEvent,
  ReactNode,
  useRef,
  useState,
} from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// Local Typings
interface SubButtonProps {
  disabled?: boolean;
  label: string;
  onClick: () => void;
  startIcon?: ReactNode;
}
interface Props {
  buttons: SubButtonProps[];
  isLoading?: boolean;
  primaryButtonProps: SubButtonProps;
  secondaryButtonAriaLabel: string;
}

// Local Variables
const popoverOrigin: PopoverProps['anchorOrigin'] = {
  horizontal: 'right',
  vertical: 'top',
};

const transformOrigin: PopoverProps['transformOrigin'] = {
  horizontal: 'right',
  vertical: 'bottom',
};

// Component Definition
const SplitButton = ({
  buttons,
  isLoading,
  primaryButtonProps,
  secondaryButtonAriaLabel,
}: Props): JSX.Element => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(1);

  const handleMenuItemClick = (
    event: MouseEvent,
    index: number,
  ) => {
    setSelectedIndex(index);
    setOpen(false);
    buttons[index]?.onClick();
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose: ClickAwayListenerProps['onClickAway'] = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        aria-label="split button"
        color="primary"
        disabled={primaryButtonProps.disabled || isLoading}
        ref={anchorRef}
        variant="contained"
      >
        <Button
          onClick={primaryButtonProps.onClick}
          startIcon={isLoading ? <CircularProgress size={20} /> : primaryButtonProps.startIcon}
        >
          {primaryButtonProps.label}
        </Button>

        {buttons.length > 0 && (
          <Button
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="menu"
            aria-label={secondaryButtonAriaLabel}
            color="primary"
            onClick={handleToggle}
            size="small"
          >
            <ArrowDropDownIcon />
          </Button>
        )}
      </ButtonGroup>

      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={popoverOrigin}
        disablePortal
        onClose={handleClose}
        open={open}
        transformOrigin={transformOrigin}
      >
        <Paper elevation={6}>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList id="split-button-menu">
              {buttons.map((option, index) => (
                <MenuItem
                  key={option.label}
                  onClick={(event) => handleMenuItemClick(event, index)}
                  selected={index === selectedIndex}
                >
                  {option.startIcon && <ListItemIcon>{option.startIcon}</ListItemIcon>}
                  <ListItemText>{option.label}</ListItemText>
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popover>
    </>
  );
};

export default SplitButton;
