// External Dependencies
import { lighten } from '@mui/material/styles';
import { useCallback } from 'react';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import SwapVerticalIcon from '@mui/icons-material/SwapVert';
import styled from 'styled-components';

// Internal Dependencies
import {
  EnhancedAlert,
  EnhancedCard,
  EnhancedCardContent,
  SaveButton,
} from 'components/shared';
import { useRemoveAuthUser } from 'gql/mutations/members';

// Local Typings
interface Props {
  authUserEmail: string;
  isOpen: boolean;
  memberEmail: string;
  memberId: string;
  onClose: () => void;
}

// Local Variables
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '.bottom-text': {
    marginTop: theme.spacing(1.5),
  },
  '.email-card': {
    alignItems: 'center',
    backgroundColor: lighten(theme.palette.stripeBlue['300'], 0.8),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  '.email-card-content': {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  '.error-alert': {
    marginTop: theme.spacing(1.5),
  },
  '.swap-icon': {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}));

// Component Definition
const DialogRemoveAuthUser = ({
  authUserEmail,
  isOpen,
  memberEmail,
  memberId,
  onClose,
}: Props): JSX.Element => {
  const [
    handleRemoveAuthUser,
    {
      error,
      loading,
    },
  ] = useRemoveAuthUser({
    onCompleted: () => {
      onClose();
    },
  });

  const handleClickRemoveButton = useCallback((): void => {
    const variables: GQL.IRemoveAuthUserOnMutationArguments = {
      input: {
        memberId,
      },
    };

    handleRemoveAuthUser({
      variables,
    });
  }, [onClose]);

  return (
    <StyledDialog
      maxWidth="xs"
      onClose={onClose}
      open={isOpen}
    >
      <DialogTitle>
        Remove Auth User
      </DialogTitle>

      <DialogContent>
        <DialogContentText paragraph>
          This will remove the connection between the Auth User (for login) and the Member,
          allowing the member to connect to another Auth User.
        </DialogContentText>

        <DialogContentText paragraph>
          We normally need this when a parent has signed up as their own child.
        </DialogContentText>

        <EnhancedCard className="email-card">
          <EnhancedCardContent className="email-card-content">
            <DialogContentText>
              {authUserEmail}
            </DialogContentText>

            <div>
              <SwapVerticalIcon
                className="swap-icon"
                fontSize="large"
              />
            </div>

            <DialogContentText>
              {memberEmail}
            </DialogContentText>
          </EnhancedCardContent>
        </EnhancedCard>

        <DialogContentText className="bottom-text">
          Are you sure you want to remove the Auth User (for login) from this Member?
        </DialogContentText>

        <Collapse in={Boolean(error)}>
          <EnhancedAlert
            className="error-alert"
            severity="error"
            title="Error"
          >
            {error?.message}
          </EnhancedAlert>
        </Collapse>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>
          Cancel
        </Button>

        <SaveButton
          isSaving={loading}
          onClick={handleClickRemoveButton}
          variant="contained"
        >
          Remove
        </SaveButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default DialogRemoveAuthUser;
