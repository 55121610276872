// External Dependencies
import { AxiosResponse } from 'axios';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';

// Local Dependencies
import { PrestoLogoVerticalSvg } from '../components/shared';
import LoginForm from '../components/LoginForm';
import SignUpForm from '../components/SignUpForm';
import { setUser } from '../state/actions';
import {
  getToken,
  setTokenInStorage,
} from '../utils/cookies';
import {
  LoginFormValues,
  SignupFormValues,
  login,
  signUp,
} from '../utils/api';
import { redirectUser } from '../utils/lib/redirect_user';

// Local Variables
const useStyles = makeStyles((theme) => ({
  button: {
    border: `1px solid ${theme.palette.accentBlue}`,
    color: theme.palette.accentBlue,
    marginTop: theme.spacing(3),
  },
  logoContainer: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(4),
    },
    display: 'flex',
    justifyContent: 'center',
    maxWidth: 360,
    zIndex: 1,
  },
  paper: {
    [theme.breakpoints.down('md')]: {
      width: 360,
    },
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0 auto',
    padding: '24px 0',
    textAlign: 'center',
    width: 400,
    zIndex: 1,
  },
}));

// Component Definition
const LoginPage: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentToken = getToken();

  useEffect(() => {
    if (currentToken) {
      redirectUser(navigate);
    }
  }, [currentToken]);

  const [error, setError] = useState('');
  const [showLoginForm, setShowLoginForm] = useState(true);

  const toggleForm = () => {
    setError('');
    setShowLoginForm(!showLoginForm);
  };

  const setUserAndToken = ({
    token,
    userFromResponse,
  }: any) => {
    setTokenInStorage(token);
    dispatch(setUser(userFromResponse));
    redirectUser(navigate);
  };

  const handleApiResponse = (
    loginResponse: AxiosResponse,
    errorMessage: string,
  ) => {
    const responseDataKeys = Object.keys(loginResponse.data);

    // The API sometimes returns 'error' and other times 'errors
    if (responseDataKeys.includes('error')
      || responseDataKeys.includes('errors')) {
      setError(errorMessage);
      throw new Error(loginResponse.data.error);
    } else {
      const userFromResponse = loginResponse.data;
      const token = loginResponse.headers['x-access-token'];
      setUserAndToken({
        token,
        userFromResponse,
      });
    }
  };

  const handleSignup = async (values: SignupFormValues) => {
    try {
      const loginResponse = await signUp(values);

      const errorMessage = 'There was an issue signing you up';

      handleApiResponse(loginResponse, errorMessage);
    } catch (err) {
      console.error('Error during sign up', err);
    }
  };

  const handleLogin = async (values: LoginFormValues) => {
    try {
      const loginResponse = await login(values);

      const errorMessage = 'There was an issue signing you in';

      handleApiResponse(loginResponse, errorMessage);
    } catch (err) {
      console.error('Error during sign in', err);
    }
  };

  const toggleButtonText = showLoginForm
    ? 'Create account'
    : 'Go to Login';

  return (
    <Paper
      className={classes.paper}
      variant="outlined"
    >
      <Box
        className={classes.logoContainer}
        my={6}
      >
        <PrestoLogoVerticalSvg />
      </Box>
      {showLoginForm ? (
        <LoginForm
          loginError={error}
          onSubmit={handleLogin}
        />
      ) : (
        <SignUpForm
          error={error}
          onSubmit={handleSignup}
        />
      )}

      <Button
        className={classes.button}
        onClick={toggleForm}
        size="small"
        variant="outlined"
      >
        {toggleButtonText}
      </Button>
    </Paper>
  );
};

export default LoginPage;
