// External Dependencies
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { prestoAdminApiClient } from 'utils/api/apiClient';

// Hook to fetch an AppSetting by its ID
export const useGetAppSettingById = (id: string) => {
  return useQuery({
    queryFn: () =>
      prestoAdminApiClient.v1.appSetting.getById({ params: { id } }),
    queryKey: ['appSettings', id],
  });
};

// Hook to update an AppSetting
export const useUpdateAppSetting = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { id: string; value: string }) =>
      prestoAdminApiClient.v1.appSetting.updateById({
        body: { value: data.value },
        params: { id: data.id },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(['appSettings']);
    },
  });
};
