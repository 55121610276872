// External Dependencies
import { Form, Formik } from 'formik';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Box from '@mui/material/Box';

// Internal Dependencies
import EnhancedCard from 'components/shared/EnhancedCard';
import CustomInput from 'components/shared/CustomInput';
import CustomSelect from 'components/shared/CustomSelect';
import { Flex } from 'components/shared';
import SaveButton from 'components/shared/SaveButton';

// Local Typings
export interface ApiFormValues {
  body: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  path: string;
}
interface Props {
  isSubmitting: boolean;
  onSubmit: (values: ApiFormValues) => void;
}

// Local Variables
const methodOptions = [
  {
    id: 'GET',
    label: 'GET',
  },
  {
    id: 'POST',
    label: 'POST',
  },
  {
    id: 'PUT',
    label: 'PUT',
  },
  {
    id: 'DELETE',
    label: 'DELETE',
  },
];

// Component Definition
const ApiForm = ({
  isSubmitting,
  onSubmit,
}: Props): JSX.Element => {
  return (
    <Formik<ApiFormValues>
      initialValues={{
        body: '',
        method: 'GET',
        path: '',
      }}
      onSubmit={onSubmit}
    >
      {() => (
        <Form>
          <EnhancedCard>
            <CardContent>
              <Box
                display="flex"
                flexDirection="column"
                gap={2}
              >
                <CustomInput
                  label="Path"
                  name="path"
                  placeholder="/admin/v1/organizations"
                />

                <CustomSelect
                  label="Method"
                  name="method"
                  options={methodOptions}
                  required
                />

                <CustomInput
                  label="JSON Body"
                  minRows={4}
                  multiline
                  name="body"
                  placeholder="{}"
                />
              </Box>
            </CardContent>

            <CardActions>
              <Flex
                justifyContent="flex-end"
                width="100%"
              >
                <SaveButton
                  isSaving={isSubmitting}
                >
                  Submit
                </SaveButton>
              </Flex>
            </CardActions>
          </EnhancedCard>
        </Form>
      )}
    </Formik>
  );
};

export default ApiForm;
