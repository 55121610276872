// External Dependencies
import { FC } from 'react';
import { Formik, Form } from 'formik';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';

// Internal Dependencies
import {
  CustomFormButton,
  CustomInput,
  ErrorMessage,
} from './shared';
import {
  validateEmail,
  validatePasswordLength,
} from '../utils';

// Local Typings
interface Props {
  loginError: string;
  onSubmit: (values: any) => void;
}

// Local Variables
const useStyles = makeStyles((theme) => ({
  customButton: {
    margin: 10,
    padding: '6px 48px',
  },
  form: {
    borderBottom: `1px solid ${theme.palette.grey['300']}`,
    boxSizing: 'border-box',
    maxWidth: 368,
    padding: theme.spacing(3),
  },
  input: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
}));

// Component Definition
const LoginForm: FC<Props> = ({
  loginError,
  onSubmit,
}) => {
  const classes = useStyles();

  const validate = (values: any) => {
    const errors: any = {};

    if (validateEmail(values.email)) {
      errors.email = 'Invalid email';
    }

    if (validatePasswordLength(values.password)) {
      errors.password = 'Password must be at least 6 characters long';
    }

    // map over values and make all required
    Object.entries(values).forEach(([k, v]) => {
      if (!v) {
        errors[k] = 'Required';
      }
    });

    return errors;
  };

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit(values);
        setSubmitting(false);
      }}
      validate={validate}
    >
      {({ isSubmitting }) => (
        <Form className={classes.form}>
          <Box
            maxWidth={360}
            pb={4}
          >
            <CustomInput
              className={classes.input}
              fullWidth
              label="Email"
              name="email"
              type="email"
            />
            <CustomInput
              className={classes.input}
              fullWidth
              label="Password"
              name="password"
              type="password"
            />
          </Box>

          <CustomFormButton
            buttonText="Sign in"
            disabled={isSubmitting}
            rootClassName={classes.customButton}
          />

          <ErrorMessage
            condition={Boolean(loginError)}
            message={loginError}
          />
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
