// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { useQueryEnhanced } from 'hooks';

// Local Typings
interface OrganizationTypesResponse {
  organizationTypes: GQL.IOrganizationType[];
}

export const GET_ORGANIZATION_TYPES = gql`
  query OrganizationTypes {
    organizationTypes {
      id
      label
    }
  }
`;

export const useGetOrganizationTypes = () =>
  useQueryEnhanced<OrganizationTypesResponse>(
    GET_ORGANIZATION_TYPES,
  );
