// External Dependencies
import {
  Avatar,
  Box,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material';
import { ChangeEvent, FC, useState } from 'react';
import { alpha } from '@mui/material/styles';
import CalendarEditIcon from 'mdi-material-ui/CalendarEdit';
import styled, { useTheme } from 'styled-components';

// Internal Dependencies
import {
  EnhancedCard,
  Subtitle,
} from '../../components/shared';
import { updateSubscription } from '../../utils/api';

// Local Typings
interface Props {
  organization: GQL.IOrganization;
}

// Local Variables
const StyledEnhancedCard = styled(EnhancedCard)(({ theme }) => ({
  '.MuiAvatar-root': {
    backgroundColor: theme.palette.stripeBlue['600'],
  },
  '.MuiButton-containedPrimary': {
    backgroundColor: theme.palette.stripeBlue['500'],
  },
  '.list': {
    border: `1px solid ${theme.palette.prestoPrimaryDark}`,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '16px auto',
    padding: theme.spacing(2),
  },
  '.submitButton': {
    marginLeft: theme.spacing(1),
  },
  '.subscriptionCard': {
    backgroundColor: alpha(theme.palette.accentBlue, 0.1),
    width: '45%',
  },
  input: {
    [theme.breakpoints.down('md')]: {
      display: 'block',
      marginLeft: 0,
      marginTop: theme.spacing(1),
    },
    border: `1px solid ${theme.palette.prestoPrimaryMain}`,
    borderRadius: theme.shape.borderRadius,
    fontSize: theme.spacing(2),
    marginLeft: theme.spacing(2),
    padding: '4px 8px',
  },
}));

// Component Definition
const EditOrgSubscription: FC<Props> = ({ organization }) => {
  const theme = useTheme();

  const [
    subscriptions,
    setSubscriptions,
  ] = useState<GQL.IOrganizationSubscription[]>(organization.subscriptions.slice());

  const handleChange = (id: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const subscriptionListCopy = [...subscriptions];

    // Find the subscription that needs an update
    const subscriptionIndex = subscriptionListCopy.findIndex((sub) => sub.id === id);
    const foundSubscription = subscriptionListCopy.slice(subscriptionIndex, subscriptionIndex + 1);

    const updatedSubscription = {
      ...foundSubscription[0],
      extendedGracePeriodDeadline: event.target.value,
    };

    subscriptionListCopy[subscriptionIndex] = updatedSubscription;

    setSubscriptions(subscriptionListCopy);
  };

  const updateSubscriptionDetails = (id: string) => async () => {
    const subscription = subscriptions.find((sub) => sub.id === id);

    // TODO: make this a graphql mutation and pass "Organization" as a refetch query
    await updateSubscription(id, {
      extendedGracePeriodDeadline: subscription?.extendedGracePeriodDeadline,
    });
  };

  return (
    <section>
      <Box mb={2}>
        <Subtitle hasGutterTop>Subscription</Subtitle>
      </Box>

      <StyledEnhancedCard>
        <CardHeader subheader="Edit Subscription Dates" />

        <CardContent>
          <div className="list">
            {subscriptions.map((subscription) => (
              <EnhancedCard
                className="subscriptionCard"
                key={subscription.id}
              >
                <CardHeader
                  avatar={(
                    <Avatar>
                      <CalendarEditIcon htmlColor={theme.palette.common.white} />
                    </Avatar>
                  )}
                  title="Subscription"
                />

                <CardContent>
                  <Box mb={2}>
                    <Typography>
                      Extend Grace Period Deadline
                    </Typography>
                  </Box>

                  <label htmlFor="grace-period">
                    End date
                    <input
                      id={`grace-period-${subscription.id}`}
                      name="extendedGracePeriodDeadline"
                      onChange={handleChange(subscription.id)}
                      type="date"
                      value={subscription.extendedGracePeriodDeadline || ''}
                    />
                  </label>
                </CardContent>

                <CardActions>
                  <Button
                    className="submitButton"
                    color="primary"
                    onClick={updateSubscriptionDetails(subscription.id)}
                    size="small"
                    type="submit"
                    variant="contained"
                  >
                    Update
                  </Button>
                </CardActions>
              </EnhancedCard>
            ))}
          </div>
        </CardContent>
      </StyledEnhancedCard>
    </section>
  );
};

export default EditOrgSubscription;
