import gql from 'graphql-tag';
import { useQueryEnhanced } from 'hooks';

interface SendGridContractsResponse {
  sendGridContracts: GQL.ISendGridContract[];
}

const GET_SENDGRID_CONTRACTS = gql`
  query SendGridContracts {
    sendGridContracts {
      fields {
        fieldName
        fieldType
        isRequired
      }
      template
    }
  }
`;

export const useGetSendGridContracts = () =>
  useQueryEnhanced<SendGridContractsResponse>(GET_SENDGRID_CONTRACTS);
