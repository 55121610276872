// External Dependencies
import gql from 'graphql-tag';
import { UserAlertFlags } from '@presto-assistant/api_types';

// Internal Dependencies
import { useQueryEnhanced } from 'hooks';

// Local Typings
interface UsersByFraudAlertFlagResponse {
  usersByAlertFlag: GQL.IUser[];
}

export const GET_USERS_BY_ALERT_FLAGS = gql`
  query UsersByFraudAlertFlags($alertFlagId: ID!) {
    usersByAlertFlag(alertFlagId: $alertFlagId) {
      id
      email
    }
  }
`;

export const getUsersByFraudAlertFlag = () =>
  useQueryEnhanced<UsersByFraudAlertFlagResponse>(
    GET_USERS_BY_ALERT_FLAGS,
    {
      variables: { alertFlagId: UserAlertFlags.Fraud },
    },
  );
