// External Dependencies
import gql from 'graphql-tag';
import { UserAlertFlags } from '@presto-assistant/api_types';

// Internal Dependencies
import { MutationOptions, useMutationEnhanced } from 'hooks';
import { GET_USERS_BY_ALERT_FLAGS } from 'gql/queries/alertFlags';

// Local Typings
interface SetUserAlertFlagResponse {
  setUserAlertFlag: boolean;
}

interface RemoveUserAlertFlagResponse {
  removeUserAlertFlag: boolean;
}

const REMOVE_USER_ALERT_FLAG = gql`
  mutation RemoveUserAlertFlag($input: RemoveUserAlertFlagInput!) {
    removeUserAlertFlag(input: $input)
  }
`;

const SET_USER_ALERT_FLAG = gql`
  mutation SetUserAlertFlag($input: SetUserAlertFlagInput!) {
    setUserAlertFlag(input: $input)
  }
`;

export const useRemoveUserAlertFlag = (
  options?: MutationOptions<
    RemoveUserAlertFlagResponse,
    GQL.IRemoveUserAlertFlagOnMutationArguments
  >,
) =>
  useMutationEnhanced<
    RemoveUserAlertFlagResponse,
    GQL.IRemoveUserAlertFlagOnMutationArguments
  >(
    REMOVE_USER_ALERT_FLAG,
    {
      ...options,
      awaitRefetchQueries: true,
      refetchQueries: [{
        query: GET_USERS_BY_ALERT_FLAGS,
        variables: { alertFlagId: UserAlertFlags.Fraud },
      }],
    },
  );

export const useSetUserAlertFlag = (
  options?: MutationOptions<
    SetUserAlertFlagResponse,
    GQL.ISetUserAlertFlagOnMutationArguments
  >,
) =>
  useMutationEnhanced<
    SetUserAlertFlagResponse,
    GQL.ISetUserAlertFlagOnMutationArguments
  >(
    SET_USER_ALERT_FLAG,
    {
      ...options,
      awaitRefetchQueries: true,
      refetchQueries: [{
        query: GET_USERS_BY_ALERT_FLAGS,
        variables: { alertFlagId: UserAlertFlags.Fraud },
      }],
    },
  );
