// External Dependencies
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';

// Local Typings
interface Props {
  children: any;
  disabled?: boolean;
  isSaving?: boolean;
  onClick?: (evt: React.MouseEvent) => void;
  variant?: ButtonProps['variant'];
}

// Local Variables
const StyledDiv = styled.div({
  marginLeft: 8,
  textAlign: 'center',
  width: 68,
});
const StyledButton = styled(Button)(({ theme }) => ({
  '.root': {
    color: theme.palette.common.white,
  },
  '.textPrimary': {
    color: theme.palette.prestoPrimaryMain,
  },
}));

// Component Definition
const SaveButton = ({
  children,
  disabled,
  isSaving,
  onClick,
  variant = 'contained',
  ...props
}: Props & ButtonProps): JSX.Element => {
  if (isSaving) {
    return (
      // Adding a wrapper here lets us give the SVG elemeent
      //  about the same amount of space as the SAVE button
      <StyledDiv>
        <CircularProgress
          size={24}
          thickness={6}
        />
      </StyledDiv>
    );
  }

  return (
    <StyledButton
      classes={{
        root: 'root',
        textPrimary: 'textPrimary',
      }}
      color="primary"
      disabled={disabled}
      onClick={onClick}
      type="submit"
      variant={variant}
      {...props}
    >
      {children}
    </StyledButton>
  );
};

export default SaveButton;
