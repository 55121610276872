// External Dependencies
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

// Internal Dependencies
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import TableDataGrid from 'components/shared/TableDataGrid';
import { useGetLicenses } from 'hooks/api/district';

// Local Dependencies
import { useColumns } from './hooks';

// Local Typings
interface Props {
  districtId: string;
}

// Component Definition
const DistrictLicensesTable = ({
  districtId,
}: Props): JSX.Element => {
  const navigate = useNavigate();

  const {
    data: licensesResponse,
    isLoading,
  } = useGetLicenses(districtId);

  const handleNavigateToNewLicense = useCallback(() => {
    navigate(`/districts/${districtId}/licenses/new`);
  }, [districtId, navigate]);

  const columns = useColumns();

  return (
    <DataGridContainer>
      <TableDataGrid
        addButtonProps={{
          label: 'License',
          onClick: handleNavigateToNewLicense,
        }}
        columns={columns}
        loading={isLoading}
        rows={licensesResponse?.data.data.licenses ?? []}
        tableResource="districtLicenses"
        withSearch
      />
    </DataGridContainer>
  );
};

export default DistrictLicensesTable;
