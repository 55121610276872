// External Dependencies
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

// Internal Dependencies
import {
  BackToButton,
  Subtitle,
} from 'components/shared';
import { useGetDistrictById } from 'gql/queries/districts';

// Local Dependencies
import DistrictLicensesTable from './DistrictLicensesTable';

// Component Definition
const DistrictLicenses = (): JSX.Element => {
  const { districtId } = useParams();

  const { data: districtData } = useGetDistrictById(districtId);

  if (!districtId) {
    return <CircularProgress />;
  }

  return (
    <>
      <Box marginBottom={3}>
        <BackToButton
          context="District Details"
          navigateTo={`/districts/${districtId}`}
        />
      </Box>

      <Box marginTop={3}>
        <Subtitle>
          {districtData ? districtData.districtById.label : 'District'} Licenses
        </Subtitle>
      </Box>

      <DistrictLicensesTable districtId={districtId} />
    </>
  );
};

export default DistrictLicenses;
