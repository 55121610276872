// External Dependencies
import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';

// Internal Dependencies
import { prestoAdminApiClient } from 'utils/api/apiClient';

export const useGetMemberByEmail = ({
  email,
}: {
  email: string;
}) => {
  const queryFn = useCallback(async () => {
    return prestoAdminApiClient.v1.members.getMembersByEmail({ email });
  }, [email]);

  return useQuery({
    enabled: Boolean(email),
    queryFn,
    queryKey: ['searchMemberByEmail', email],
  });
};
