// External Dependencies
import { Box, Button } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import { Formik, Form } from 'formik';

// Internal Dependencies
import {
  CustomCheckbox,
  CustomInput,
  Flex,
  FullWidthDiv,
  Subtitle,
} from 'components/shared';
import { useCreateOverride } from 'gql/mutations/featureFlags';
import OrganizationsTypeAhead from 'components/shared/OrganizationTypeAhead';
import { ApolloError } from '@apollo/client';

// Local Typings
interface FormValues {
  isActive: boolean;
  organizationId: string;
  reason: string;
}
interface Props {
  existingOverrideOrgIds: string[];
  featureFlagId: number;
  flagLabel: string;
  onCancel: () => void;
  onError: (error: ApolloError | null) => void;
  onSuccess: () => void;
}

// Local Variables
const handleSelectOrganization = (
  handleChange: (e: Partial<ChangeEvent<any>>) => void,
) => (org: any) => {
  handleChange({
    target: {
      name: 'organizationId',
      value: org.id,
    },
  });
};

// Component Definition
const CreateOverrideForm: FC<Props> = ({
  existingOverrideOrgIds,
  featureFlagId,
  flagLabel,
  onCancel,
  onError,
  onSuccess,
}) => {
  const [createOverride] = useCreateOverride({
    onCompleted: onSuccess,
    onError,
  });

  const handleSubmit = (values: FormValues) => {
    const formValues = {
      ...values,
      featureFlagId,
    };

    return createOverride({
      variables: {
        input: { ...formValues },
      },
    });
  };

  return (
    <FullWidthDiv> {/* used to center the container */}
      <>
        <Box
          mb={2}
          mt={3}
        >
          <Subtitle>
            {`Create Override for ${flagLabel}`}
          </Subtitle>
        </Box>

        <Formik<FormValues>
          initialValues={{
            isActive: false,
            organizationId: '',
            reason: '',
          }}
          onSubmit={handleSubmit}
        >
          {({ handleChange }) => (
            <Form>
              <CustomInput
                label="Reason"
                name="reason"
              />

              <OrganizationsTypeAhead
                disabledIds={existingOverrideOrgIds}
                onSelectOrganization={handleSelectOrganization(handleChange)}
              />

              <Flex justifyContent="flex-end">
                <CustomCheckbox
                  label="Is Active?"
                  name="isActive"
                />
              </Flex>

              <Flex justifyContent="flex-end">
                <Button
                  color="primary"
                  onClick={onCancel}
                  variant="outlined"
                >
                  Cancel
                </Button>

                <Button
                  color="primary"
                  sx={{ marginLeft: 2 }}
                  type="submit"
                  variant="contained"
                >
                  Create Override
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      </>
    </FullWidthDiv>
  );
};

export default CreateOverrideForm;
