// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { useQueryEnhanced } from 'hooks';

// Local Typings
interface AnnualValueResponse {
  annualValue: GQL.IAnnualValue[];
}
interface DailyActiveUserCountsResponse {
  dailyActiveUserCounts: GQL.IDailyActiveUserCount[];
}
interface MonthlyActiveUserCountsResponse {
  monthlyActiveUserCounts: GQL.IMonthlyActiveUserCount[];
}

const GET_ANNUAL_VALUE = gql`
  query AnnualValue($queryParams: AnnualValueQueryParams!) {
    annualValue(queryParams: $queryParams) {
      annualValueInDollars
      date
      id
    }
  }
`;

const GET_DAILY_ACTIVE_USER_COUNTS = gql`
  query DailyActiveUsers($queryParams: DailyActiveUsersQueryParams!) {
    dailyActiveUserCounts(queryParams: $queryParams) {
      count
      date
      id
    }
  }
`;

const GET_MONTHLY_ACTIVE_USER_COUNTS = gql`
  query MonthlyActiveUsers($queryParams: MonthlyActiveUsersQueryParams!) {
    monthlyActiveUserCounts(queryParams: $queryParams) {
      count
      date
      id
    }
  }
`;

export const useGetAnnualValue = (queryParams: GQL.IAnnualValueQueryParams) =>
  useQueryEnhanced<AnnualValueResponse>(
    GET_ANNUAL_VALUE,
    {
      variables: {
        queryParams,
      },
    },
  );

export const useGetDailyActiveUserCounts = (queryParams: GQL.IDailyActiveUsersQueryParams) =>
  useQueryEnhanced<DailyActiveUserCountsResponse, GQL.IDailyActiveUserCountsOnQueryArguments>(
    GET_DAILY_ACTIVE_USER_COUNTS,
    {
      variables: {
        queryParams,
      },
    },
  );

export const useGetMonthyActiveUserCounts = () =>
  useQueryEnhanced<MonthlyActiveUserCountsResponse>(
    GET_MONTHLY_ACTIVE_USER_COUNTS,
  );
