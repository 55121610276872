// External Dependencies
import Cookies from 'universal-cookie';

// Local Dependencies
import { prestoAdminApiClient } from './api/apiClient';

const cookies = new Cookies();

export const TOKEN_KEY = 'token';

export const getToken = () => {
  const token = cookies.get(TOKEN_KEY);

  if (token) {
    prestoAdminApiClient.setToken(token);
  }

  return token;
};

export const clearTokens = () => {
  cookies.remove(TOKEN_KEY, { path: '/' });
};

export const setTokenInStorage = (token: string) => {
  prestoAdminApiClient.setToken(token);

  cookies.set(TOKEN_KEY, token, { path: '/' });
};
