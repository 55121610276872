// External Dependencies
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import {
  BackToButton,
  DetailCard,
  Subtitle,
} from 'components/shared';
import { isMobileOrTabletScreenSize } from 'state/selectors';
import { tableQueryParams } from 'state/table/selectors';
import { useGetDistrictById } from 'gql/queries/districts';
import { useIsOpen } from 'hooks/useIsOpen';
import { useIsPrestoOwner } from 'hooks/useIsPrestoOwner';

// Local Dependencies
import DistrictDangerZone from './DistrictDangerZone';
import DistrictInfoContent from './DistrictInfoContent';
import VerifyDistrictDialog from './VerifyDistrictDialog';
import DistrictLicensesCard from './DistrictLicensesCard';

// Component Definition
const DistrictShow: FC = () => {
  const { id } = useParams();

  const isMobileOrTabletScreen = useSelector(isMobileOrTabletScreenSize);

  const districtsParams = useSelector(tableQueryParams('districts'));

  const isPrestoOwner = useIsPrestoOwner();

  const {
    isOpen: isVerifyDistrictDialogOpen,
    toggleIsOpen: toggleVerifyDistrictDialogIsOpen,
  } = useIsOpen();

  const { data } = useGetDistrictById(id);

  if (!data) {
    return null;
  }

  const { districtById } = data;
  const isDistrictVerified = districtById.isVerified;

  const subheader = (
    <Box
      display={isMobileOrTabletScreen ? 'block' : 'flex'}
      justifyContent="space-between"
      marginY={1}
    >
      <div>
        <Typography color="textPrimary">{districtById.addressOne}</Typography>
        <Typography color="textPrimary">{districtById.city}, {districtById.state.label} {districtById.zipcode}</Typography>
        <Typography color="textPrimary">{districtById.phoneNumber}</Typography>
      </div>

      <Collapse in={isPrestoOwner && !isDistrictVerified}>
        <Button
          onClick={toggleVerifyDistrictDialogIsOpen}
          size={isMobileOrTabletScreen ? 'small' : 'medium'}
          startIcon={<ThumbUpIcon />}
          variant="outlined"
        >
          Verify District
        </Button>
      </Collapse>
    </Box>
  );

  return (
    <>
      <Box marginBottom={3}>
        <BackToButton
          context="All Districts"
          navigateTo={`/districts${districtsParams}`}
        />
      </Box>

      <Box marginBottom={1}>
        <Subtitle>District Info</Subtitle>
      </Box>

      <Box>
        <DetailCard
          content={(
            <DistrictInfoContent
              district={districtById}
            />
          )}
          isDistrict
          subheader={subheader}
          title={districtById.label}
        />
      </Box>

      <DistrictLicensesCard
        districtId={districtById.id}
        licensePriceInCents={districtById.licensePriceInCents}
      />

      {isPrestoOwner && (
        <DistrictDangerZone
          districtId={districtById.id}
          districtName={districtById.label}
        />
      )}

      <VerifyDistrictDialog
        districtId={districtById.id}
        onClose={toggleVerifyDistrictDialogIsOpen}
        open={isVerifyDistrictDialogOpen}
      />
    </>
  );
};

export default DistrictShow;
