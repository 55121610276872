// External Dependencies
import { ApolloError } from '@apollo/client';
import { Box, Button, Typography } from '@mui/material';
import { CustomFormButton, CustomInput } from 'components/shared';
import {
  FieldArray, Form, Formik, FormikHelpers,
} from 'formik';
import { camelToSentenceCase } from 'utils/lib/camel_to_sentence_case';
import { useCreateSendGridEmail } from 'gql/mutations/sendGrid';
import { validateEmail } from 'utils';
import { FC, useMemo, useState } from 'react';

// Local Typings
interface Props {
  contract: GQL.ISendGridContract;
  onSuccess: () => void;
}

// Component Definition
const SendEmailForm: FC<Props> = ({ contract, onSuccess }) => {
  const [submitError, setSubmitError] = useState<string | null>(null);

  const initialValues = useMemo(() => contract.fields.reduce((prev, curr) => ({
    ...prev,
    [curr.fieldName]: '',
  }), {
    to: [''],
  }), [contract]);

  const handleError = (error: ApolloError) => {
    setSubmitError(error.graphQLErrors[0]?.message ?? null);
  };

  const [createSendGridEmail] = useCreateSendGridEmail({
    onCompleted: onSuccess,
    onError: handleError,
  });

  const handleSubmit = async (
    {
      to,
      ...fields
    }: { to: string[] },
    formikHelpers: FormikHelpers<{
      to: string[];
    }>,
  ) => {
    try {
      setSubmitError(null);
      createSendGridEmail({
        variables: {
          input: {
            fields: Object.entries(fields).map(([k, v]) => ({
              fieldName: k,
              fieldValue: v as unknown as string,
            })),
            template: contract.template,
            to,
          },
        },
      });
    } catch {
      formikHelpers.setSubmitting(false);
    }
  };

  return (
    <Box p={2}>
      <Formik<{ to: string[] }>
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({
          isSubmitting,
          values,
        }) => {
          const hasInvalidEmail = values.to.some((v) => validateEmail(v));

          return (
            <Form>
              {contract.fields.map((field) => (
                <CustomInput
                  key={field.fieldName}
                  label={camelToSentenceCase(field.fieldName)}
                  name={field.fieldName}
                  required={field.isRequired}
                />
              ))}

              <fieldset>
                <legend>
                  Recipients
                </legend>

                <FieldArray name="to">
                  {(arrayHelpers) => (
                    <>
                      {values.to.map((emailAddress, index) => (
                        <CustomInput
                          // eslint-disable-next-line react/no-array-index-key
                          key={`email-${index}`}
                          // eslint-disable-next-line react/no-array-index-key
                          label={`Email ${index + 1}`}
                          name={`to.${index}`}
                        />
                      ))}

                      <Button
                        color="primary"
                        disabled={hasInvalidEmail}
                        onClick={() => arrayHelpers.insert(values.to.length, '')}
                      >
                        Add another email
                      </Button>
                    </>
                  )}
                </FieldArray>
              </fieldset>

              {submitError && (
                <Typography
                  color="error"
                  sx={{ paddingTop: 2 }}
                >
                  {submitError}
                </Typography>
              )}

              <Box mt={2}>
                <CustomFormButton
                  buttonText="Send email"
                  disabled={isSubmitting || hasInvalidEmail}
                />
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default SendEmailForm;
