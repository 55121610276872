// External Dependencies
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux';
import { FC, useCallback } from 'react';
import ArrowTopRightThickIcon from 'mdi-material-ui/ArrowTopRightThick';

// Internal Dependencies
import { fetchUserToken } from 'utils/api';
import { isMobileOrTabletScreenSize } from 'state/selectors';

// Local Typings
interface Props {
  userId: string;
}

// Component Definition
const BecomeThisUserButton: FC<Props> = ({ userId }) => {
  const isMobileOrTabletScreen = useSelector(isMobileOrTabletScreenSize);

  const handleClick = useCallback(async () => {
    const tokenForBecomeUser = await fetchUserToken(userId);

    window.open(
      `${process.env.REACT_APP_CLIENT_URL}?token=${tokenForBecomeUser}`,
      '_blank',
    );
  }, [userId]);

  const buttonOrTooltipLabel = 'Switch to this user';

  return isMobileOrTabletScreen ? (
    <Tooltip
      arrow
      title={buttonOrTooltipLabel}
    >
      <IconButton
        color="primary"
        onClick={handleClick}
        size="large"
      >
        <ArrowTopRightThickIcon color="primary" />
      </IconButton>
    </Tooltip>
  ) : (
    <Button
      color="primary"
      onClick={handleClick}
      size="small"
      startIcon={<ArrowTopRightThickIcon />}
      sx={{ marginRight: 1 }}
      variant="outlined"
    >
      {buttonOrTooltipLabel}
    </Button>
  );
};

export default BecomeThisUserButton;
