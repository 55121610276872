// User
export const selectCurrentUser = (state: any) =>
  state.user.data;

export const selectIsPrestoOwner = (state: any) =>
  Boolean(state.user.tokenData?.isPrestoOwner);

// Device
export const isMobileScreenSize = (state: any): boolean =>
  !['xl', 'lg', 'md'].includes(state.device.deviceSize);

export const isMobileOrTabletScreenSize = (state: any): boolean =>
  !['xl', 'lg'].includes(state.device.deviceSize);

export const isDesktopScreenSize = (state: any): boolean =>
  ['xl', 'lg'].includes(state.device.deviceSize);
