// External Dependencies
import {
  ApolloError,
  LazyQueryHookOptions,
  LazyQueryResultTuple,
  MutationHookOptions,
  MutationTuple,
  NoInfer,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import { DocumentNode } from 'graphql';
import { QueryClient, useQueryClient } from '@tanstack/react-query';

// Internal Dependencies
import { parseSearch } from 'utils';
import client from 'gql/client';

// Local Typings
export interface MutationOptions<T, S> extends MutationHookOptions<T, S> {
  clearCachePredicates?: string[]
}
export type MutationEnhancedOptions<TData = any, TVariables = any>
  = MutationHookOptions<TData, TVariables> & { clearCachePredicates?: string[] };

// Local Variables
const ANNOYING_GRAPHQL_ERROR_HEADER = 'GraphQL error: ';
const removeAnnoyingHeader = (error?: ApolloError): ApolloError | undefined =>
  error && {
    ...error,
    message: error.message.replace(ANNOYING_GRAPHQL_ERROR_HEADER, ''),
  };

// Exports
export function evictQueryCache(queryClient: QueryClient, clearCachePredicates: string[]) {
  // Clear any REST queries used by @tanstack/react-query
  queryClient.invalidateQueries({
    predicate: (query) => {
      const queryKey = query.queryKey as string[];

      if (clearCachePredicates && queryKey) {
        return clearCachePredicates.some((predicate) => queryKey[0].startsWith(predicate));
      }

      return false;
    },
  });

  const rootQuery = (client.cache as any).data.data.ROOT_QUERY;
  const rootQueryKeys = Object.keys(rootQuery);

  // Clear any GraphQL queries used by Apollo Client
  rootQueryKeys.forEach((key) => {
    if (clearCachePredicates.some((predicate) => key.startsWith(predicate))) {
      client.cache.evict({ fieldName: key, id: 'ROOT_QUERY' });
    }
  });

  client.cache.gc();
}

export function useQueryEnhanced<
  TData = any,
  TVariables extends OperationVariables = OperationVariables
>(
  query: DocumentNode,
  options: QueryHookOptions<TData, TVariables> | undefined = {},
): QueryResult<TData, TVariables> {
  const data = useQuery<TData, TVariables>(query, options);
  data.error = removeAnnoyingHeader(data.error);

  return data;
}

export function useMutationEnhanced<
  TData = any,
  TVariables = OperationVariables
>(
  mutation: DocumentNode,
  {
    clearCachePredicates,
    ...options
  }: MutationEnhancedOptions<TData, TVariables> = {},
): MutationTuple<TData, TVariables> {
  const queryClient = useQueryClient();

  const [
    enhancedMutation,
    {
      error,
      ...remaining
    },
  ] = useMutation<TData, TVariables>(
    mutation,
    {
      ...options,
      onCompleted: (data: TData) => {
        if (clearCachePredicates) {
          evictQueryCache(queryClient, clearCachePredicates);
        }

        if (options.onCompleted) {
          options.onCompleted(data);
        }
      },
      onError: (e: ApolloError) => {
        if (clearCachePredicates) {
          evictQueryCache(queryClient, clearCachePredicates);
        }

        const cleanError = removeAnnoyingHeader(e) as ApolloError;

        if (options.onError) {
          options.onError(cleanError);
        } else {
          console.error('Something went wrong', cleanError);
        }
      },
    },
  );
  const errorEnhanced = removeAnnoyingHeader(error);

  return [
    enhancedMutation,
    {
      ...remaining,
      error: errorEnhanced,
    },
  ];
}

export function useLazyQueryEnhanced<
  TData = any,
  TVariables extends OperationVariables = Record<string, any>
>(
  query: DocumentNode,
  options?: LazyQueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>,
): LazyQueryResultTuple<TData, TVariables> {
  const [
    enhancedQuery,
    {
      error,
      ...remaining
    },
  ] = useLazyQuery<TData, TVariables>(
    query,
    {
      ...options,
      onCompleted: (data: TData) => {
        if (options?.onCompleted) {
          options.onCompleted(data);
        }
      },
      onError: (e: ApolloError) => {
        const cleanError = removeAnnoyingHeader(e) as ApolloError;

        if (options?.onError) {
          options.onError(cleanError);
        }
      },
    },
  );
  const errorEnhanced = removeAnnoyingHeader(error);

  return [
    enhancedQuery,
    {
      ...remaining,
      error: errorEnhanced,
    } as any, // incompatible client issue. Should be able to be inferred otherwise
  ];
}

export const getIndexQueryParams = (searchString: string) => {
  const search = parseSearch(searchString);

  const queryParams: GQL.IIndexQueryParams = {
    limit: search.limit ?? 10,
    orderBy: search.orderBy ? [{
      asc: search.asc ?? true,
      column: search.orderBy,
    }] : [],
    page: search.page ?? 1,
    q: `${search.q ?? ''}`,
  };

  return queryParams;
};
