// External Dependencies
import { GridColDef } from '@mui/x-data-grid-pro';
import { ListAdminOrganizationsResponseItem } from '@presto-assistant/api_types/api/admin/organizations';
import { displaySchoolYearString } from '@presto-assistant/api_types/utils';
import { useMemo } from 'react';

// Internal Dependencies
import { SelectOption } from 'components/shared/Select';
import { dateTimeColumn } from 'utils';
import { useGetOrganizationTypes } from 'gql/queries/organization-type-queries';
import { useGetOrganizationEntityTypes } from 'gql/queries/organization-entity-type-queries';
import { useGetStateOptions } from 'hooks/useGetStateOptions';

// Local Variables
const organizationStatuses = {
  FREE_TRIAL: 'Free Trial',
  INACTIVE: 'Inactive',
  LICENSED: 'Licensed',
} as const;

export const useColumns = () => {
  const { data: orgTypeData } = useGetOrganizationTypes();

  const {
    data: entityTypeData,
  } = useGetOrganizationEntityTypes();

  const mappedCampusTypes = entityTypeData?.organizationEntityTypes.map((entityType) => ({
    id: entityType.id.toString(),
    label: entityType.label,
    value: entityType.label,
  })) ?? [];
  const mappedOrganizationTypes = orgTypeData?.organizationTypes.map((orgType) => ({
    id: orgType.id,
    label: orgType.label,
    value: orgType.label,
  })) ?? [];

  const organizationStatusOptions: SelectOption[] = useMemo(() =>
    Object.values(organizationStatuses).map((organizationStatus, index) => ({
      id: index,
      label: organizationStatus,
      value: organizationStatus,
    })), []);

  const stateOptions = useGetStateOptions();
  const mappedStateOptions = stateOptions?.options.map((option) => ({
    id: option.id.toString(),
    label: option.label,
    value: option.label,
  }));

  return useMemo(() => {
    const columns: GridColDef<ListAdminOrganizationsResponseItem>[] = [
      {
        field: 'label',
        headerName: 'Name',
        minWidth: 328,
      },
      {
        field: 'entityTypeId',
        headerName: 'Campus Type',
        minWidth: 144,
        type: 'singleSelect',
        valueGetter: (params) => {
          const { entityTypeId } = params.row;

          return mappedCampusTypes.find((option) => option.id.toString() === entityTypeId.toString())?.label ?? '';
        },
        valueOptions: mappedCampusTypes,
      },
      {
        field: 'organizationTypeId',
        headerName: 'Org Type',
        minWidth: 108,
        type: 'singleSelect',
        valueGetter: (params) => {
          const { organizationTypeId } = params.row;

          return mappedOrganizationTypes.find((option) => option.id.toString() === organizationTypeId.toString())?.label ?? '';
        },
        valueOptions: mappedOrganizationTypes,
      },
      {
        field: 'districtLabel',
        headerName: 'District',
        minWidth: 212,
      },
      {
        field: 'city',
        headerName: 'City',
        minWidth: 160,
      },
      {
        field: 'stateId',
        headerName: 'State',
        minWidth: 120,
        type: 'singleSelect',
        valueGetter: (params) => {
          const { stateId } = params.row;

          return stateOptions?.options.find((option) => option.id === stateId)?.label ?? '';
        },
        valueOptions: mappedStateOptions,
        valueSetter: (params) => ({
          ...params.row,
          state: {
            __typename: 'State',
            abbreviation: '',
            id: mappedStateOptions?.find((option) => option.label === params.value)?.id ?? '',
            label: params.value,
          },
        }),
      },
      {
        field: 'directorInfo',
        headerName: 'Director',
        minWidth: 320,
        valueGetter: (params) => {
          const { directorInfo } = params.row;

          if (directorInfo === '()') {
            return 'Not assigned';
          }

          return directorInfo;
        },
      },
      {
        field: 'isCurrentlyFreeTrial',
        headerName: 'Status',
        minWidth: 120,
        type: 'singleSelect',
        valueGetter: (params) => {
          const {
            hasActiveLicense,
            isCurrentlyFreeTrial,
            isInactive,
          } = params.row;

          let status;

          if (isInactive) {
            status = organizationStatuses.INACTIVE;
          }
          if (hasActiveLicense) {
            status = organizationStatuses.LICENSED;
          }
          if (isCurrentlyFreeTrial) {
            status = organizationStatuses.FREE_TRIAL;
          }

          return status;
        },
        valueOptions: organizationStatusOptions,
      },
      {
        field: 'paymentDueInDays',
        headerName: 'Days Until Payment Due',
        minWidth: 180,
        type: 'number',
      },
      {
        field: 'hasGoogleCalendarConnection',
        headerName: 'Google Cal',
        type: 'boolean',
      },
      {
        field: 'hasStripeConnection',
        headerName: 'Stripe',
        type: 'boolean',
      },
      {
        field: 'hasVancoRevtrakConnection',
        headerName: 'RevTrak',
        type: 'boolean',
      },
      {
        field: 'currentSchoolYearEnding',
        headerName: 'Current School Year',
        valueGetter: (params) => {
          const { currentSchoolYearEnding } = params.row;

          if (currentSchoolYearEnding) {
            return displaySchoolYearString(currentSchoolYearEnding);
          }

          return '';
        },
      },
      {
        field: 'isVerified',
        headerName: 'Verified',
        type: 'boolean',
      },
      {
        field: 'successorOrganizationLabel',
        headerName: 'Successor Org',
        minWidth: 272,
      },
      dateTimeColumn({
        field: 'createdAt',
        headerName: 'Date Added',
      }),
      {
        field: 'id',
        headerName: 'Org Id',
      },
    ];

    return columns;
  }, [
    mappedCampusTypes,
    mappedOrganizationTypes,
    mappedStateOptions,
    organizationStatusOptions,
    stateOptions?.options,
  ]);
};
