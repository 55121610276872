// External Dependencies
import {
  useEffect, useRef, useState,
} from 'react';
import Box from '@mui/material/Box';
import styled from 'styled-components';

// Internal Dependencies
import useWindowSize from 'hooks/useWindowSize';

// Local Typings
export interface Props {
  children: React.ReactNode;
  height?: string | number;
}
interface StyledRootProps {
  $height: string | number;
}

// Local Variables
const StyledRoot = styled(Box)<StyledRootProps>(({ $height }) => ({
  height: $height,
  overflow: 'inherit',
  width: '100%',
}));

// Component Definition
const DataGridContainer = ({
  children,
  height,
}: Props): JSX.Element => {
  const [localHeight, setLocalHeight] = useState(640);

  const ref = useRef<HTMLDivElement>();

  const windowSize = useWindowSize();

  useEffect(() => {
    // If the parent element provided a height, skip this
    if (!height) {
      const windowHeight = window.innerHeight;
      const boundingClientRect = ref.current?.getBoundingClientRect();

      if (boundingClientRect) {
        const elementPositionTop = boundingClientRect.top;
        const paddingBottom = 40;
        const calculatedHeight = windowHeight - elementPositionTop - paddingBottom;

        // Ensure the table height is a minimum of 640
        // Not passing this is a minHeight value so the parent has the option to
        // override with a smaller height as a prop
        setLocalHeight(Math.max(calculatedHeight, 640));
      }
    }
  }, [height, windowSize.innerHeight]);

  return (
    <StyledRoot
      $height={height ?? localHeight}
      ref={ref}
    >
      {children}
    </StyledRoot>
  );
};

export default DataGridContainer;
