// External Dependencies
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { ShowPageDataDisplay } from 'components/shared';

// Local Typings
interface Props {
  organization: Organization;
}

interface Organization extends GQL.IOrganization {
  stripeId?: string | undefined;
}

// Component Definition
const OrganizationInfoContent = ({ organization }: Props): JSX.Element => {
  const { activeSubscription } = organization;

  return (
    <Box
      component="section"
      display="flex"
      flexWrap="wrap"
      gap={3}
      paddingLeft={{
        sm: 7,
        xs: 3,
      }}
    >
      <Box>
        <Typography
          component="h3"
          variant="h6"
        >
          Presto Info
        </Typography>

        <ShowPageDataDisplay
          label="Organzation Code"
          value={organization.code}
        />
        <ShowPageDataDisplay
          label="Organzation ID"
          value={organization.id}
        />
        <ShowPageDataDisplay
          label="Verified"
          type="boolean"
          value={organization.isVerified}
        />
        <ShowPageDataDisplay
          label="End Date"
          type="date"
          value={activeSubscription ? activeSubscription?.endDate : ''}
        />
        <ShowPageDataDisplay
          label="Subscription Status"
          value={organization.activeSubscription ? 'Active' : 'Inactive'}
        />
        <ShowPageDataDisplay
          label="Current Free Trial"
          type="boolean"
          value={organization.isFreeTrial}
        />
        <ShowPageDataDisplay
          label="Free Trial Days Remaining"
          value={organization.daysUntilPaymentIsDue ?? ''}
        />
      </Box>

      <Divider sx={{ marginY: 2 }} />

      <Box>
        <Typography
          component="h3"
          variant="h6"
        >
          Integrations
        </Typography>

        <ShowPageDataDisplay
          label="Google Calendar"
          type="boolean"
          value={organization.hasGoogleCalendar}
        />
        <ShowPageDataDisplay
          label="Stripe"
          type="boolean"
          value={Boolean(organization.hasStripeId)}
        />
        <ShowPageDataDisplay
          label="Vanco RevTrak"
          type="boolean"
          value={Boolean(organization.hasVancoPcct)}
        />
      </Box>
    </Box>
  );
};

export default OrganizationInfoContent;
