// External Dependencies
import { FC } from 'react';
import { UserAlertFlags } from '@presto-assistant/api_types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AccountCancelIcon from 'mdi-material-ui/AccountCancel';

// Internal Dependencies
import { EnhancedAlert, SaveButton } from 'components/shared';
import {
  useRemoveUserAlertFlag,
  useSetUserAlertFlag,
} from 'gql/mutations/alertFlags';
import {
  GET_USERS_BY_ALERT_FLAGS,
  getUsersByFraudAlertFlag,
} from 'gql/queries/alertFlags';
import { useIsOpen } from 'hooks/useIsOpen';
import { useIsPrestoOwner } from 'hooks/useIsPrestoOwner';
import ToolbarMoreActionsIconMenu from 'components/shared/TableDataGrid/ToolbarMoreActionsIconMenu';

// Local Typings
interface Props {
  authUserId: string | null;
  userFullName: string;
}

// Component Definition
const PersonListItemMoreActionsIconMenu: FC<Props> = ({
  authUserId,
  userFullName,
}) => {
  const { data } = getUsersByFraudAlertFlag();

  const isPrestoOwner = useIsPrestoOwner();

  const {
    isOpen: isFlagUserConfirmationDialogOpen,
    toggleIsOpen: toggleFlagUserConfirmationDialogIsOpen,
  } = useIsOpen();

  const [
    handleRemoveUserAlertFlag,
    {
      error: removeError,
      loading: isRemovingFlag,
    },
  ] = useRemoveUserAlertFlag({
    onCompleted: toggleFlagUserConfirmationDialogIsOpen,
    refetchQueries: [{ query: GET_USERS_BY_ALERT_FLAGS }],
  });
  const [
    handleSetUserAlertFlag,
    {
      error: addError,
      loading: isAddingFlag,
    },
  ] = useSetUserAlertFlag({
    onCompleted: toggleFlagUserConfirmationDialogIsOpen,
    refetchQueries: [{ query: GET_USERS_BY_ALERT_FLAGS }],
  });

  const isLoading = isRemovingFlag || isAddingFlag;

  const isCurrentUserMarkedAsFraud = data?.usersByAlertFlag.some(
    (user) => user.id === authUserId,
  );

  const toggleFraudVerb = `${isCurrentUserMarkedAsFraud ? 'Remove' : 'Add'}`;

  const handleClick = async () => {
    if (authUserId && isCurrentUserMarkedAsFraud) {
      await handleRemoveUserAlertFlag({
        variables: { input: { userId: authUserId } },
      });
    } else if (authUserId) {
      await handleSetUserAlertFlag({
        variables: {
          input: {
            userAlertFlagId: UserAlertFlags.Fraud.toString(),
            userId: authUserId,
          },
        },
      });
    }
  };

  const buttonOrTooltipLabel = `${toggleFraudVerb} Fraud Status`;

  return (
    <>
      <ToolbarMoreActionsIconMenu
        iconButtonProps={{
          size: 'large',
        }}
        moreActions={[
          {
            action: toggleFlagUserConfirmationDialogIsOpen,
            icon: <AccountCancelIcon />,
            isDisabled: !isPrestoOwner || (isPrestoOwner && !authUserId),
            text: buttonOrTooltipLabel,
          },
        ]}
      />

      <Dialog
        onClose={toggleFlagUserConfirmationDialogIsOpen}
        open={isFlagUserConfirmationDialogOpen}
      >
        <DialogTitle>{buttonOrTooltipLabel}</DialogTitle>

        <DialogContent>
          <DialogContentText>
            {`Do you want to ${toggleFraudVerb.toLocaleLowerCase()} the fraud flag for ${userFullName}?`}
          </DialogContentText>

          <Collapse in={Boolean(addError || removeError)}>
            <Box marginTop={2}>
              <EnhancedAlert severity="error">
                {addError ? addError?.message : removeError?.message}
              </EnhancedAlert>
            </Box>
          </Collapse>
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            onClick={toggleFlagUserConfirmationDialogIsOpen}
          >
            Cancel
          </Button>
          <SaveButton
            color="primary"
            isSaving={isLoading}
            onClick={handleClick}
            variant="contained"
          >
            {`Yes, ${toggleFraudVerb} Flag`}
          </SaveButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PersonListItemMoreActionsIconMenu;
