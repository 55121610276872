// External Dependencies
import { createTheme, lighten } from '@mui/material/styles';
import {
  deepPurple,
  green,
  pink,
  purple,
} from '@mui/material/colors';

// Local Variables
// Borrowed from the many variations in the Stripe web site
// Carried over from the web app
const stripeBlue300 = '#7D96F1';
const stripeBlue400 = '#6974DE';
const stripeBlue500 = '#5469D4';
const stripeBlue600 = '#2F3D89';
const stripeBlue700 = '#2d2d54';

// Stock colors
// Nice color names courtesy of https://coolors.co
const languidLavender = '#e0d6eb';

// Build the theme
export default createTheme({
  palette: {
    accentBlue: '#7795F8',
    dropzoneBorder: pink[200],
    dropzoneHover: lighten(pink[50], 0.6),
    feedbackDetails: lighten(purple['50'], 0.4),
    link: languidLavender,
    navMenuItemText: green['900'],
    prestoPrimaryDark: '#280154',
    prestoPrimaryLight: '#6102CC',
    prestoPrimaryMain: '#450291',
    prestoSecondary200: '#EAE5EE',
    prestoSecondaryLight: '#A3DEAA',
    prestoSecondaryMain: '#61DE55',
    primary: deepPurple,
    secondary: green,
    stripeBlue: {
      300: stripeBlue300,
      400: stripeBlue400,
      500: stripeBlue500,
      600: stripeBlue600,
      700: stripeBlue700,
    },
  },
});
