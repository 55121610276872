// External Dependencies
import { CSSObject, Theme } from '@mui/material/styles';
import {
  ReactElement, useMemo,
} from 'react';
import { Link } from 'react-router-dom';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { useSelector } from 'react-redux';
import AlarmIcon from '@mui/icons-material/Alarm';
import AppBar from '@mui/material/AppBar';
import AppsIcon from '@mui/icons-material/Apps';
import Box from '@mui/material/Box';
import BusinessIcon from '@mui/icons-material/Business';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import DnsIcon from '@mui/icons-material/Dns';
import Drawer from '@mui/material/Drawer';
import FeedbackIcon from '@mui/icons-material/FeedbackOutlined';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import PublishIcon from '@mui/icons-material/Publish';
import ScienceIcon from '@mui/icons-material/Science';
import StoreIcon from 'mdi-material-ui/Store';
import ToolsIcon from 'mdi-material-ui/Tools';
import Typography from '@mui/material/Typography';
import ListItemText, { ListItemTextProps } from '@mui/material/ListItemText';
import styled, { useTheme } from 'styled-components';

// Internal Dependencies
import {
  isMobileScreenSize,
  selectIsPrestoOwner,
} from 'state/selectors';
import {
  NavMenuItem,
} from 'components/shared';
import { tableQueryParams } from 'state/table/selectors';
import { useGetCurrentUser } from 'hooks/useGetCurrentUser';
import {
  DRAWER_WIDTH,
  MOBILE_DRAWER_WIDTH,
} from 'utils/constants';

// Local Dependencies
import AccountSubMenu from './AccountSubMenu';

// Local Typings
interface Props {
  hasToken: boolean;
  isDrawerOpen: boolean;
  onCloseDrawer: () => void;
  onOpenDrawer: () => void;
}

interface Link {
  icon: (props: SvgIconProps) => ReactElement<SvgIconProps>;
  label: string;
  path: string;
}

interface StyledAppBarDrawerProps {
  $isMobileScreen?: boolean;
  $isOpen: boolean;
}

// Local Variables
const openedMixin = (theme: Theme): CSSObject => ({
  [theme.breakpoints.up('sm')]: {
    width: DRAWER_WIDTH,
  },
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    duration: theme.transitions.duration.enteringScreen,
    easing: theme.transitions.easing.sharp,
  }),
  width: MOBILE_DRAWER_WIDTH,
});

const closedMixin = (theme: Theme): CSSObject => ({
  [theme.breakpoints.up('sm')]: {
    // Our constant CLOSED_DRAWER_WIDTH matches this value
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    duration: theme.transitions.duration.leavingScreen,
    easing: theme.transitions.easing.sharp,
  }),
  // Our constant CLOSED_MOBILE_DRAWER_WIDTH matches this value
  width: `calc(${theme.spacing(7)} + 1px)`,
});

const DrawerHeader = styled.div(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.prestoPrimaryDark,
  display: 'flex',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar as any,
}));

// Vertical bar across the top of the screen
const StyledAppBar = styled(AppBar)<StyledAppBarDrawerProps>(({ $isOpen, theme }) => ({
  '.app-bar-menu-icon-button': {
    marginLeft: theme.spacing(1.5),
    ...($isOpen && {
      display: 'none',
      transition: theme.transitions.create(['margin-left'], {
        duration: theme.transitions.duration.leavingScreen,
        easing: theme.transitions.easing.sharp,
      }),
    }),
  },
  '.app-name': {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
    color: theme.palette.common.white,
    fontFamily: '"Racing Sans One", sans-serif',
    fontSize: '2rem',
    fontStyle: 'normal',
    fontWeight: 400,
    marginLeft: $isOpen ? theme.spacing(3) : theme.spacing(2),
    transition: theme.transitions.create(['margin-left'], {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    }),
  },
  '.link': {
    color: 'inherit',
    textDecoration: 'none',
  },
  alignItems: 'center',
  backgroundColor: theme.palette.prestoPrimaryMain,
  display: 'flex',
  flexDirection: 'row',
  img: {
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(3),
    },
    height: theme.spacing(4),
    marginLeft: theme.spacing(2),
  },
  justifyContent: 'space-between',
  minHeight: theme.spacing(7),
  overflow: 'hidden',
  padding: theme.spacing(0.5),
  transition: theme.transitions.create(['width', 'margin-left'], {
    duration: theme.transitions.duration.leavingScreen,
    easing: theme.transitions.easing.sharp,
  }),
  zIndex: theme.zIndex.drawer + 1,
  ...($isOpen && {
    [theme.breakpoints.up('sm')]: {
      minHeight: theme.spacing(8),
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
    },
    marginLeft: DRAWER_WIDTH,
    transition: theme.transitions.create(['width', 'margin-left'], {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.sharp,
    }),
    width: `calc(100% - ${MOBILE_DRAWER_WIDTH}px)`,
  }),
  ...(!$isOpen && {
    [theme.breakpoints.up('sm')]: {
      minHeight: theme.spacing(8),
    },
  }),
}));

// Horizontal drawer on the left side of the screen
const StyledDrawer = styled(Drawer)<StyledAppBarDrawerProps>(
  ({
    $isMobileScreen,
    $isOpen,
    theme,
  }) => ({
    '.list-header': {
      paddingLeft: theme.spacing(2),
    },
    '.nav-bar-nav-menu-item': {
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(1.2),
      },
      paddingLeft: theme.spacing(1.5),
    },
    '.space-top': {
      marginTop: theme.spacing(1.5),
    },
    boxSizing: 'border-box',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    width: $isMobileScreen ? MOBILE_DRAWER_WIDTH : DRAWER_WIDTH,
    ...($isOpen && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }) as any,
    ...(!$isOpen && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const adminLinks: Link[] = [
  {
    icon: ToolsIcon,
    label: 'Admin Actions',
    path: '/admin_actions',
  },
  {
    icon: AlarmIcon,
    label: 'Scheduled Cron Jobs',
    path: '/scheduled_cron_jobs',
  },
];

const settingsLinks: Link[] = [
  {
    icon: DnsIcon,
    label: 'Server Settings',
    path: '/server_settings',
  },
  {
    icon: ScienceIcon,
    label: 'API Playground',
    path: '/api_playground',
  },
];

const primaryTypographyProps = {
  fontWeight: 700,
  variant: 'subtitle2',
} as ListItemTextProps['primaryTypographyProps'];

// Component Definition
const NavBar = ({
  hasToken,
  isDrawerOpen,
  onCloseDrawer,
  onOpenDrawer,
}:Props): JSX.Element | null => {
  const theme = useTheme();

  const isMobileScreen = useSelector(isMobileScreenSize);

  const user = useGetCurrentUser();
  const isPrestoOwner = useSelector(selectIsPrestoOwner);

  const districtsParams = useSelector(tableQueryParams('districts'));
  const feedbackParams = useSelector(tableQueryParams('feedback'));
  const importsParams = useSelector(tableQueryParams('imports'));

  const whiteColor = theme.palette.common.white;

  const supportLinks : Link[] = useMemo(() => [
    {
      icon: SearchIcon,
      label: 'Search',
      path: '/search',
    },
    {
      icon: BusinessIcon,
      label: 'Districts',
      path: `/districts${districtsParams}`,
    },
    {
      icon: AppsIcon,
      label: 'Organizations',
      path: '/organizations',
    },
    {
      icon: PublishIcon,
      label: 'Imports',
      path: `/imports${importsParams}`,
    },
    {
      icon: FeedbackIcon,
      label: 'User Feedback',
      path: `/feedback${feedbackParams}`,
    },
    {
      icon: StoreIcon,
      label: 'Vendors',
      path: '/vendors',
    },
  ], [districtsParams, feedbackParams, importsParams]);

  const drawerElement = useMemo(() => (
    <StyledDrawer
      $isMobileScreen={isMobileScreen}
      $isOpen={isDrawerOpen}
      variant="permanent"
    >
      <DrawerHeader>
        <IconButton onClick={onCloseDrawer}>
          {theme.direction === 'rtl'
            ? (
              <ChevronRightIcon
                fontSize="large"
                htmlColor={whiteColor}
              />
            )
            : (
              <ChevronLeftIcon
                fontSize="large"
                htmlColor={whiteColor}
              />
            )}
        </IconButton>
      </DrawerHeader>

      <Divider />

      <List dense={isMobileScreen}>
        <Collapse in={isDrawerOpen}>
          <ListItemText
            className="list-header"
            primary="Support"
            primaryTypographyProps={primaryTypographyProps}
          />
        </Collapse>

        {supportLinks.map((link) => (
          <NavMenuItem
            className="nav-bar-nav-menu-item"
            icon={link.icon}
            key={link.label}
            text={link.label}
            to={link.path}
          />
        ))}

        <Divider />

        <Collapse in={isDrawerOpen}>
          <ListItemText
            className="list-header space-top"
            primary="Admin"
            primaryTypographyProps={primaryTypographyProps}
          />
        </Collapse>

        {adminLinks.map((link) => (
          <NavMenuItem
            className="nav-bar-nav-menu-item"
            icon={link.icon}
            key={link.label}
            text={link.label}
            to={link.path}
          />
        ))}

        <Divider />

        {isPrestoOwner && (
          <>
            <Collapse in={isDrawerOpen}>
              <ListItemText
                className="list-header space-top"
                primary="Settings"
                primaryTypographyProps={primaryTypographyProps}
              />
            </Collapse>

            {settingsLinks.map((link) => (
              <NavMenuItem
                className="nav-bar-nav-menu-item"
                icon={link.icon}
                key={link.label}
                text={link.label}
                to={link.path}
              />
            ))}
          </>
        )}
      </List>
    </StyledDrawer>
  ), [
    isDrawerOpen,
    isMobileScreen,
    isPrestoOwner,
    onCloseDrawer,
    supportLinks,
    theme.direction,
    whiteColor,
  ]);

  if (!user) {
    return null;
  }

  return (
    <div>
      <StyledAppBar
        $isOpen={isDrawerOpen}
        position="fixed"
      >
        <Box
          alignItems="center"
          display="flex"
        >
          {hasToken && Boolean(user) && (
            <IconButton
              aria-label="open drawer"
              className="app-bar-menu-icon-button"
              color="inherit"
              edge="start"
              onClick={onOpenDrawer}
            >
              <MenuIcon />
            </IconButton>
          )}
          <img
            alt="Presto Logo"
            src="https://res.cloudinary.com/presto-assistant/image/upload/v1730047475/marketing/presto-stacked-logos-4x_szpnqk.png"
          />

          <Typography
            className="app-name"
            color="primary"
            variant="h5"
          >
            <Link
              className="link"
              to="/dashboard"
            >
              <span>
                Sparkle{' '}
                {!isMobileScreen && (
                  <span
                    aria-label="Sparkle emoji"
                    role="img"
                  >
                    ✨
                  </span>
                )}
              </span>
            </Link>
          </Typography>
        </Box>

        <AccountSubMenu user={user} />
      </StyledAppBar>

      {hasToken && Boolean(user) && drawerElement}
    </div>
  );
};

export default NavBar;
