// External Dependencies
import { FC } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';

// Internal Dependencies
import { isMobileScreenSize } from 'state/selectors';
import { useIsOpen } from 'hooks/useIsOpen';
import { DangerZone } from 'components/shared';

// Local Dependencies
import DeleteOrganizationDialog from './DeleteOrganizationDialog';

// Local Typings
interface Props {
  orgId: GQL.IOrganization['id'];
  orgName: GQL.IOrganization['label'];
}

// Component Definition
const OrganizationDangerZone: FC<Props> = ({ orgId, orgName }) => {
  const isMobileScreen = useSelector(isMobileScreenSize);

  const {
    isOpen: isDeleteDialogOpen,
    toggleIsOpen: toggleIsDeleteDialogOpen,
  } = useIsOpen();

  const dangerZoneItems = [
    {
      buttonText: isMobileScreen ? 'Start' : 'Start Deletion',
      description: `Mark the ${orgName} organization as deleted.`,
      onClick: toggleIsDeleteDialogOpen,
      title: 'Delete this organization',
    },
  ];

  return (
    <Box marginTop={2}>
      <DangerZone
        items={dangerZoneItems}
      />

      <DeleteOrganizationDialog
        isOpen={isDeleteDialogOpen}
        onClose={toggleIsDeleteDialogOpen}
        organizationId={orgId}
      />
    </Box>
  );
};

export default OrganizationDangerZone;
