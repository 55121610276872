// External Dependencies
import { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import { Button, ButtonProps } from '@mui/material';

// Local Typings
export interface AddButtonProps extends ButtonProps {
  disabled?: boolean;
  label: string;
  marginRight?: number;
}

interface OwnStyleProps {
  marginRight?: number;
}

// Local Variables
const useStyles = makeStyles({
  button: {
    marginRight: (props: OwnStyleProps) => props.marginRight,
  },
});

// Component Definition
const AddButton: FC<AddButtonProps> = (props) => {
  const {
    label,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    marginRight,
    ...otherProps
  } = props;

  const classes = useStyles({
    ...props,
    marginRight,
  });

  return (
    <Button
      className={classes.button}
      color="primary"
      size="small"
      startIcon={<AddIcon fontSize="small" />}
      variant="outlined"
      {...otherProps}
    >
      {label}
    </Button>
  );
};

export default AddButton;
