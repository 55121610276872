// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { FRAGMENT_DISTRICT } from 'gql/queries/districts';
import { useMutationEnhanced } from 'hooks';

// Mutation Definitions
export const VERIFY_DISTRICT = gql`
  mutation VerifyDistrict (
    $input: VerifyDistrictInput!
  ) {
    verifyDistrict(input: $input) {
      ...DistrictFragment
    }
  }
  ${FRAGMENT_DISTRICT}
`;

export const useVerifyDistrict = (args: {
  onCompleted: () => void;
}) =>
  useMutationEnhanced<
    GQL.IDistrict,
    GQL.IVerifyDistrictOnMutationArguments
  >(
    VERIFY_DISTRICT,
    {
      clearCachePredicates: ['districts'],
      onCompleted: args.onCompleted,
    },
  );
