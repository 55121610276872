// External Dependencies
import { FC } from 'react';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

// Local Typings
export interface DangerZoneItemProps {
  buttonText: string;
  description: string;
  onClick: () => void;
  title: string;
}
interface Props extends DangerZoneItemProps {
  divider?: boolean;
  isMobileWidth: boolean;
}

// Component Definition
const DangerZoneItem: FC<Props> = ({
  buttonText,
  description,
  divider = false,
  isMobileWidth,
  onClick,
  title,
}) => (
  <ListItem
    alignItems={isMobileWidth ? 'flex-start' : 'center'}
    divider={divider}
    secondaryAction={(
      <Button
        color="error"
        onClick={onClick}
        size="small"
        variant="outlined"
      >
        {buttonText}
      </Button>
    )}
  >
    <ListItemText
      primary={title}
      secondary={description}
      sx={{ maxWidth: '70%' }}
    />
  </ListItem>
);

export default DangerZoneItem;
