// External Dependencies
import { UserAlertFlags } from '@presto-assistant/api_types';
import { useCallback, useEffect, useState } from 'react';
import { lighten } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import ThumbUpIcon from 'mdi-material-ui/ThumbUp';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import {
  ConfirmationDialog,
  EnhancedCard,
  EnhancedCardContent,
  ShowPageDataDisplay,
} from 'components/shared';
import { useMarkEmailVerified } from 'hooks/api/authUsers';
import { useIsOpen } from 'hooks/useIsOpen';
import { useGetMemberByEmail } from 'hooks/api/members';

// Local Dependencies
import MemberSearchChip from '../shared/MemberSearchChip';

// Local Typings
interface Props {
  authUser: Partial<DB.AuthUser>;
}

// Local Variables
const StyledEnhancedCard = styled(EnhancedCard)(({ theme }) => ({
  '.auth-member-card-header': {
    background: lighten(theme.palette.prestoPrimaryLight, 0.8),
  },
  '.auth-user-search-result-header': {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
    marginBottom: 0,
  },
  '.verify-email-button': {
    borderRadius: 12,
    height: 40,
    paddingLeft: 12,
    paddingRight: 16,
    textTransform: 'none',
  },
  borderRadius: 12,
})) as typeof Typography;

// Component Definition
const AuthUserSearchResultCard = ({ authUser }: Props): JSX.Element | null => {
  const [shouldRefetchMemberSearch, setShouldRefetchMemberSearch] = useState(false);

  const {
    handleClose: closeVerifyEmailDialog,
    isOpen: isVerifyEmailDialogOpen,
    toggleIsOpen: toggleVerifyEmailDialogIsOpen,
  } = useIsOpen();

  const isFraudUser = authUser?.alertFlagId === UserAlertFlags.Fraud;

  const {
    error,
    isLoading: isMarkingEmailVerified,
    mutate: markEmailVerified,
  } = useMarkEmailVerified(authUser?.id ?? '');

  const handleVerifyEmail = useCallback(() => {
    markEmailVerified();
    setShouldRefetchMemberSearch(true);
  }, [markEmailVerified]);

  const {
    dataUpdatedAt: memberSearchDataUpdatedAt,
    isLoading: isLoadingMemberSearch,
  } = useGetMemberByEmail({
    email: shouldRefetchMemberSearch
      ? authUser?.email ?? ''
      : '',
  });

  useEffect(() => {
    setShouldRefetchMemberSearch(false);
    closeVerifyEmailDialog();
  }, [
    closeVerifyEmailDialog,
    memberSearchDataUpdatedAt,
  ]);

  const isLoading = isMarkingEmailVerified
    || (shouldRefetchMemberSearch && isLoadingMemberSearch);

  return (
    <>
      <Collapse in={Boolean(authUser)}>
        <StyledEnhancedCard>
          <EnhancedCardContent className="auth-member-card-header">
            <Box
              display="flex"
              justifyContent="space-between"
            >
              <Typography
                className="auth-user-search-result-header"
                component="h3"
                paragraph
                variant="h6"
              >
                Log in information
              </Typography>

              <Box
                display="flex"
                gap={1.5}
              >
                {isFraudUser && (
                  <MemberSearchChip
                    color="error"
                    label="Fraud"
                    variant="outlined"
                  />
                )}
                <MemberSearchChip label={authUser.onboardingRole ?? ''} />
              </Box>
            </Box>
          </EnhancedCardContent>

          <EnhancedCardContent>
            <ShowPageDataDisplay
              label="Last Logged In"
              type="datetime"
              value={authUser.loggedInAt as unknown as string}
            />

            {authUser.deletedAt && (
              <>
                <ShowPageDataDisplay
                  label="Deleted At"
                  type="datetime"
                  value={authUser.deletedAt as unknown as string}
                />
                <ShowPageDataDisplay
                  label="Deleted Note"
                  value={authUser.deletedNote}
                />
              </>
            )}

            <Box
              alignItems="center"
              display="flex"
              gap={1.5}
            >
              <ShowPageDataDisplay
                label="Email Verified"
                type="boolean"
                value={authUser.isEmailVerified}
              />

              {!authUser.isEmailVerified && (
                <Button
                  className="verify-email-button"
                  onClick={toggleVerifyEmailDialogIsOpen}
                  size="small"
                  startIcon={<ThumbUpIcon />}
                  variant="outlined"
                >
                  Verify email
                </Button>
              )}
            </Box>

            <ShowPageDataDisplay
              label="Created At"
              type="datetime"
              value={authUser.createdAt as unknown as string}
            />
            <ShowPageDataDisplay
              label="Updated At"
              type="datetime"
              value={authUser.updatedAt as unknown as string}
            />
            {authUser.deletedAt && (
              <ShowPageDataDisplay
                label="Deleted At"
                type="datetime"
                value={authUser.deletedAt as unknown as string}
              />
            )}
          </EnhancedCardContent>
        </StyledEnhancedCard>
      </Collapse>

      <ConfirmationDialog
        confirmButtonAction={handleVerifyEmail}
        confirmButtonText="Verify"
        description={(
          <>
            <Typography>
              Are you sure you want to verify this user&apos;s email?
            </Typography>

            <Collapse in={Boolean(error)}>
              <Typography color="error">
                Error when verifying the email: {error as string}
              </Typography>
            </Collapse>
          </>
        )}
        isSubmitting={isLoading}
        maxWidth="xs"
        onClose={toggleVerifyEmailDialogIsOpen}
        open={isVerifyEmailDialogOpen}
        title="Verify Email?"
      />
    </>
  );
};

export default AuthUserSearchResultCard;
