// External Dependencies
import { ReactElement } from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import {
  EnhancedCard,
} from 'components/shared';

// Local Typings
interface Props {
  disabled?: boolean;
  icon: (props: SvgIconProps) => ReactElement<SvgIconProps>;
  onClick: () => void;
  title: string;
}

// Local Variables
const StyledEnhancedCard = styled(EnhancedCard)(({ theme }) => ({
  '&:hover': {
    border: `2px solid ${theme.palette.prestoPrimaryLight}`,
  },
  '.option-card-icon': {
    color: theme.palette.prestoPrimaryMain,
    height: 64,
    width: 64,
  },
  // eslint-disable-next-line sort-keys
  '.disabled-option-card-icon': {
    color: theme.palette.text.disabled,
  },
  '.option-card-title': {
    fontSize: '1.25rem',
  },
  // eslint-disable-next-line sort-keys
  '.disabled-option-card-title': {
    color: theme.palette.text.disabled,
  },

  // eslint-disable-next-line sort-keys
  '&.disabled-option-card': {
    '&:hover': {
      border: `1px solid ${theme.palette.text.disabled}`,
    },
    border: `1px solid ${theme.palette.text.disabled}`,
    cursor: 'not-allowed',
  },

  alignItems: 'center',
  border: `1px solid ${theme.palette.prestoPrimaryLight}`,
  borderRadius: 12,
  boxSizing: 'border-box',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  height: 192,
  justifyContent: 'space-around',
  padding: theme.spacing(2, 1),
  textAlign: 'center',
  width: 192,
}));

// Component Definition
const OptionCard = ({
  disabled,
  icon: Icon,
  onClick,
  title,
}: Props): JSX.Element => {
  return (
    <StyledEnhancedCard
      className={clsx('option-card', disabled && 'disabled-option-card')}
      onClick={disabled ? undefined : onClick}
    >
      <Icon
        className={clsx('option-card-icon', disabled && 'disabled-option-card-icon')}
      />

      <Typography
        className={clsx('option-card-title', disabled && 'disabled-option-card-title')}
        color="textPrimary"
        variant="h5"
      >
        {title}
      </Typography>
    </StyledEnhancedCard>
  );
};

export default OptionCard;
