// External Dependencies
import { ReactElement } from 'react';
import { alpha } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import DomainIcon from '@mui/icons-material/Domain';
import OrgListIcon from '@mui/icons-material/Apps';
import makeStyles from '@mui/styles/makeStyles';
import styled from 'styled-components';

// Internal Dependencies
import { EnhancedCard } from 'components/shared';

// Local Typings
interface Props {
  content: ReactElement;
  isDistrict?: boolean;
  subheader: ReactElement | string;
  title: string;
}

// Local Variables
const useStyles = makeStyles((theme) => ({
  avatarDistrict: {
    backgroundColor: theme.palette.stripeBlue['600'],
  },
  avatarOrg: {
    backgroundColor: theme.palette.stripeBlue['500'],
  },
  title: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: 500,
  },
}));

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  alignItems: 'flex-start',
  backgroundColor: alpha(theme.palette.accentBlue, 0.1),
}));

// Component Definition
const DetailCard = ({
  content,
  isDistrict = false,
  subheader,
  title,
}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <EnhancedCard>
      <StyledCardHeader
        avatar={(
          <Avatar
            aria-label={`${isDistrict ? 'District' : 'Organization'} info`}
            className={isDistrict ? classes.avatarDistrict : classes.avatarOrg}
          >
            {isDistrict ? <DomainIcon /> : <OrgListIcon />}
          </Avatar>
        )}
        classes={{ title: classes.title }}
        subheader={subheader}
        title={title}
      />

      <Divider />

      <CardContent>
        {content}
      </CardContent>
    </EnhancedCard>
  );
};

export default DetailCard;
