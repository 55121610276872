// External Dependencies
import { FC, useMemo } from 'react';

// Internal Dependencies
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import TableDataGrid from 'components/shared/TableDataGrid';
import { useGetFeedbackIndexItems } from 'gql/queries/feedback';

// Local Dependencies
import { useColumns } from './hooks';

// Local Variables
const handleRowClick = (id: string) => `/feedback/${id}`;

// Component Definition
const FeedbackTable: FC = () => {
  const { data, loading } = useGetFeedbackIndexItems();

  const columns = useColumns();

  const addButtonProps = useMemo(() => ({
    disabled: true,
    label: 'Feedback Item',
  }), []);

  return (
    <DataGridContainer>
      <TableDataGrid
        addButtonProps={addButtonProps}
        clickRowTo={handleRowClick}
        columns={columns}
        loading={loading}
        rows={data?.feedbackIndex.data ?? []}
        withSearch
      />
    </DataGridContainer>
  );
};

export default FeedbackTable;
