// External Dependencies
import { FC, useCallback, useState } from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select as MUISelect,
  SelectProps as MUISelectProps,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

// Local Typings
export interface SelectOption {
  id: number | string;
  label: string;
}
// error and required are throwing strange warnings if we expect a boolean instead of a function.
// The reason is unknown for now. This works as expected if passing type any.
export interface SelectProps extends MUISelectProps {
  error?: any;
  errorMessage?: string | null;
  forceError?: boolean;
  fullWidth?: boolean;
  hideLabel?: boolean;
  label: string;
  name: string;
  options: SelectOption[];
  required?: any;
  testId?: string;
  value: number | string;
}

// Local Variables
// The height of a menu item
const ITEM_HEIGHT = 48;

const useStyles = makeStyles({
  formControl: {
    minWidth: 200,
    textAlign: 'left',
  },
  label: {
    marginLeft: 12,
  },
  select: {
    // This lets the user see a partial item and indicates scroll as an option
    maxHeight: ITEM_HEIGHT * 4.6,
  },
  shrinkLabel: {
    marginTop: 8,
  },
});

// Component Definition
const Select: FC<SelectProps> = ({
  error = false,
  errorMessage = null,
  forceError = false,
  fullWidth = true,
  hideLabel = false,
  label,
  name,
  onChange,
  options,
  required = false,
  testId,
  value,
  variant = 'filled',
  ...props
}) => {
  const classes = useStyles();

  const [isTouched, setIsTouched] = useState(false);

  const handleBlur = useCallback(() => {
    setIsTouched(true);
  }, []);

  const hasError = (error && isTouched) || forceError;

  return (
    <FormControl
      className={classes.formControl}
      fullWidth={fullWidth}
    >
      {!hideLabel && (
        <InputLabel
          className={classes.label}
          classes={{ shrink: classes.shrinkLabel }}
          htmlFor={name}
        >
          {label}
        </InputLabel>
      )}
      <MUISelect
        MenuProps={{ PopoverClasses: { paper: classes.select } }}
        error={hasError}
        inputProps={{
          'data-testid': testId,
          id: name,
        }}
        name={name}
        native
        onBlur={handleBlur}
        onChange={onChange}
        type="text"
        value={value}
        variant={variant}
        {...props}
      >
        {!required && (
          <option
            aria-label="None"
            value=""
          />
        )}
        {options.length && options.map(({ id, label: optionLabel }) => (
          <option
            key={id}
            value={id}
          >
            {optionLabel}
          </option>
        ))}
      </MUISelect>
      {(hasError || forceError) && (
        <FormHelperText>
          <Typography color="error">
            {errorMessage}
          </Typography>
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default Select;
