// External Dependencies
import makeStyles from '@mui/styles/makeStyles';

// Local Typings
interface Props {
  children: React.ReactNode;
}

// Local Variables
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

// Component Definition
const FullWidthDiv = ({ children }: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {children}
    </div>
  );
};

export default FullWidthDiv;
