// External Dependencies
import { FeedbackStatus } from '@presto-assistant/api_types';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';

// Internal Dependencies
import { dateTimeColumn, getFullName } from 'utils';

// Local Variables
const statusOptions = [
  { id: FeedbackStatus.Open, label: 'Open' },
  { id: FeedbackStatus.Ignored, label: 'Ignored' },
  { id: FeedbackStatus.Resolved, label: 'Resolved' },
];

export const useColumns = (extraColumns?: GridColDef[]) => {
  return useMemo(() => {
    const columns: GridColDef<GQL.IFeedbackIndexItem>[] = [
      {
        field: 'status',
        headerName: 'Status',
        type: 'singleSelect',
        valueGetter: (params) => params.row.status.label,
        valueOptions: statusOptions.map((category) => ({
          label: category.label,
          value: category.label,
        })),
        width: 80,
      },
      dateTimeColumn({
        field: 'submittedAt',
        headerName: 'Submitted On',
      }),
      {
        field: 'submittedBy',
        headerName: 'Submitted By',
        valueGetter: (params) => getFullName({
          firstName: params.row.submittedBy.firstName,
          lastName: params.row.submittedBy.lastName,
        }),
        width: 120,
      },
      {
        field: 'subject',
        headerName: 'Subject',
        minWidth: 200,
      },
      {
        field: 'organization',
        headerName: 'Organization',
        minWidth: 150,
        valueGetter: (params) => params.row.organization?.label,
      },
      {
        field: 'district',
        headerName: 'District',
        minWidth: 150,
        valueGetter: (params) => params.row.district?.label,
      },
      ...(extraColumns ?? []),
    ];

    return columns;
  }, [
    extraColumns,
  ]);
};
