// External Dependencies
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { GetMembersByEmailRequest } from '@presto-assistant/api_types/api/admin/members';
import { Form, Formik } from 'formik';
import { useCallback } from 'react';

// Internal Dependencies
import { CustomInput } from 'components/shared';

// Local Typings
interface Props {
  isLoading: boolean;
  searchedEmail: string;
  setSearchedEmail: (email: string) => void;
}

// Component Definition
const MemberSearchInput = ({
  isLoading,
  searchedEmail,
  setSearchedEmail,
}: Props): JSX.Element | null => {
  const handleFormikSubmit = useCallback((
    values: GetMembersByEmailRequest['body'],
  ) => {
    setSearchedEmail(values.email);
  }, []);

  const hasSearchedEmail = Boolean(searchedEmail);

  const handleClickResetSearch = useCallback((onResetForm?: () => void) => {
    onResetForm?.();
    setSearchedEmail('');
  }, [setSearchedEmail]);

  const handleMouseDownResetSearch = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Formik<GetMembersByEmailRequest['body']>
      enableReinitialize
      initialValues={{
        email: searchedEmail,
      }}
      onSubmit={handleFormikSubmit}
    >
      {({ resetForm }) => (
        <Form>
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            justifyContent="center"
          >
            <CustomInput
              InputProps={{
                endAdornment: hasSearchedEmail ? (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="reset email search"
                      edge="end"
                      onClick={() => handleClickResetSearch(resetForm)}
                      onMouseDown={handleMouseDownResetSearch}
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ) : undefined,
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{
                readOnly: isLoading || searchedEmail.length > 0,
              }}
              label="Email"
              name="email"
            />

            {hasSearchedEmail && isLoading ? (
              <Box
                display="flex"
                justifyContent="center"
              >
                <CircularProgress size={32} />
              </Box>
            ) : (
              <Button
                disabled={hasSearchedEmail}
                startIcon={<SearchIcon />}
                sx={{ padding: '8px 24px' }}
                type="submit"
                variant="contained"
              >
                Search
              </Button>
            )}
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default MemberSearchInput;
