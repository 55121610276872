// External Dependencies
import { FC, useCallback } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import EmailIcon from '@mui/icons-material/Email';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SettingsIcon from '@mui/icons-material/Settings';
import MoneyIcon from '@mui/icons-material/CurrencyExchange';

// Internal Dependencies
import {
  EnhancedCard,
  Subtitle,
} from 'components/shared';
import { tableQueryParams } from 'state/table/selectors';

// Component Definition
const AdminActions: FC = () => {
  const navigate = useNavigate();

  const stripeWebhookEventsParams = useSelector(tableQueryParams('stripeWebhookEvents'));

  const handleNavigate = useCallback((path: string) => () => {
    navigate(path);
  }, [navigate]);

  return (
    <>
      <Subtitle>
        Admin Actions
      </Subtitle>

      <EnhancedCard sx={{ marginTop: 2 }}>
        <List>
          <ListItemButton
            onClick={handleNavigate('/admin_actions/send_email')}
          >
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>

            <ListItemText primary="Send Email" />
          </ListItemButton>

          <ListItemButton
            onClick={handleNavigate(`/admin_actions/stripe_webhook_events${stripeWebhookEventsParams}`)}
          >
            <ListItemIcon>
              <AttachMoneyIcon />
            </ListItemIcon>

            <ListItemText primary="Reprocess Stripe Webhook Events" />
          </ListItemButton>

          <ListItemButton
            onClick={handleNavigate('/admin_actions/update_app_settings')}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>

            <ListItemText primary="Update App Settings" />
          </ListItemButton>

          <ListItemButton
            onClick={handleNavigate('/admin_actions/fee_calculator')}
          >
            <ListItemIcon>
              <MoneyIcon />
            </ListItemIcon>

            <ListItemText primary="Fee Calculator" />
          </ListItemButton>
        </List>
      </EnhancedCard>
    </>
  );
};

export default AdminActions;
