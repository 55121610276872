// External Dependencies
import { FastField } from 'formik';
import { TextField } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { isMobileScreenSize } from 'state/selectors';

// Local Variables
const styles = {
  input: {
    margin: '8px 0',
    width: '100%',
  },
};

const getError = ({ errors, nameScope }) => {
  let error = nameScope[0] ? errors[nameScope[0]] : undefined;
  nameScope.forEach((name, index) => {
    if (index > 0) {
      error = error && error[name];
    }
  });
  return error;
};

const getTouched = ({ nameScope, touched }) => {
  let touch = nameScope[0] ? touched[nameScope[0]] : undefined;
  nameScope.forEach((name, index) => {
    if (index > 0) {
      touch = touch && touch[name];
    }
  });
  return touch;
};

// Component Definition
const CustomInputComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { errors, touched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const isMobileScreen = useSelector(isMobileScreenSize);

  const nameScope = field.name.split('.');
  const error = getError({ errors, nameScope });
  const touch = getTouched({ nameScope, touched });
  return (
    <TextField
      error={!!touch && !!error}
      helperText={touch && error ? error : ''}
      size={isMobileScreen ? 'small' : 'medium'}
      type="text"
      variant="outlined"
      {...field}
      {...props}
    />
  );
};

const CustomInput = ({ classes, ...props }) => (
  <FastField
    className={classes.input}
    component={CustomInputComponent}
    {...props}
  />
);

export default withStyles(styles)(CustomInput);
