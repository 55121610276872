import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
    overflowX: 'auto',
    width: '100%',
  },
  table: {
    minWidth: 700,
  },
  tableCellHead: {
    color: theme.palette.common.black,
    fontWeight: 500,
  },
  tableHead: {
    backgroundColor: theme.palette.prestoSecondary200,
  },
  tableRow: {
    cursor: 'pointer',
  },
});

// Component Definition
const CustomTable = ({
  classes,
  headers,
  onRowClick,
  rows,
}) => (
  <Paper
    className={classes.root}
    variant="outlined"
  >
    <Table className={classes.table}>
      <TableHead className={classes.tableHead}>
        <TableRow>
          {headers.map((header, index) => (
            <TableCell
              className={classes.tableCellHead}
              // eslint-disable-next-line react/no-array-index-key
              key={`${header.label}${index}`}
            >
              {header.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        {rows.map((row, rowIndex) => (
          <TableRow
            className={classes.tableRow}
            hover={Boolean(onRowClick)}
            // eslint-disable-next-line react/no-array-index-key
            key={`${row.id}${rowIndex}`}
            onClick={() => onRowClick?.(row.id)}
          >
            {headers.map((header, index) => (
              <TableCell
                component="th"
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                scope="row"
              >
                {row[header.rowKey] || header.renderCell?.(row)}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </Paper>
);

CustomTable.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    table: PropTypes.string.isRequired,
    tableCellHead: PropTypes.string.isRequired,
    tableHead: PropTypes.string.isRequired,
    tableRow: PropTypes.string.isRequired,
  }).isRequired,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      renderCell: PropTypes.func,
      rowKey: PropTypes.string,
    }),
  ),
  onRowClick: PropTypes.func,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default withStyles(styles)(CustomTable);
