// Local Typings
interface NameArguments {
  firstName: string;
  lastName: string;
}

const getFullName = ({
  firstName,
  lastName,
}: NameArguments): string =>
  `${firstName} ${lastName}`;

export default getFullName;
