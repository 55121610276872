// External Dependencies
import clsx from 'clsx';
import { Button } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

// Internal Dependencies
import { formButton } from '../../utils/constants/styles';

// Local Variables
const styles = { formButton };

// Component Definition
const CustomFormButton = ({
  buttonText,
  classes,
  rootClassName = '',
  ...props
}) => (
  <Button
    className={clsx(classes.formButton, rootClassName)}
    color="primary"
    type="submit"
    variant="contained"
    {...props}
  >
    {buttonText}
  </Button>
);

export default withStyles(styles)(CustomFormButton);
