// External Dependencies
import { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '0.5rem',
  },
  root: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    padding: '16px 0px',
    textAlign: 'center',
  },
}));

// Component Definition
const Footer: FC = () => {
  const classes = useStyles();
  return (
    <footer className={classes.root}>
      &copy; {new Date().getFullYear()} Presto Assistant, LLC.{' '}
      All Rights Reserved.
    </footer>
  );
};

export default Footer;
