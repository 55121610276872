// External Dependencies
import { ListInventoryServiceVendorsResponseItem } from '@presto-assistant/api_types/api/admin/inventoryVendor';
import { useMemo } from 'react';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import { dateTimeColumn } from 'utils';

// Local Variables
export const useColumns = (
  extraColumns?: DataGridColDef<ListInventoryServiceVendorsResponseItem>[],
) => {
  return useMemo(() => {
    const columns: DataGridColDef<ListInventoryServiceVendorsResponseItem>[] = [
      {
        field: 'label',
        headerName: 'Name',
        minWidth: 232,
      },
      {
        field: 'addressOne',
        headerName: 'Address 1',
      },
      {
        field: 'addressTwo',
        headerName: 'Address 2',
      },
      {
        field: 'city',
        headerName: 'City',
      },
      {
        field: 'stateId',
        headerName: 'State',
        minWidth: 72,
      },
      {
        field: 'zipcode',
        headerName: 'Zipcode',
      },
      {
        field: 'phoneNumber',
        headerName: 'Phone',
      },
      dateTimeColumn({
        field: 'createdAt',
        headerName: 'Added',
      }),
    ];

    return columns;
  }, [
    extraColumns,
  ]);
};
