// External Dependencies
import {
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import { FC, useState } from 'react';

// Internal Dependencies
import {
  BackToButton,
  Subtitle,
} from 'components/shared';
import { camelToSentenceCase } from 'utils/lib/camel_to_sentence_case';
import { useGetSendGridContracts } from 'gql/queries/sendGrid';

// Local Dependencies
import SendEmailForm from './SendEmailForm';

// Component Definition
const SendEmail: FC = () => {
  const [selectedContract, setSelectedContract] = useState<GQL.ISendGridContract | null>(null);
  const [isSent, setIsSent] = useState(false);

  const { data } = useGetSendGridContracts();

  const handleClickTemplate = (contract: GQL.ISendGridContract) => () => {
    setIsSent(false);
    setSelectedContract(contract);
  };

  const handleClickBackButton = () => {
    setSelectedContract(null);
  };

  const handleSuccess = () => {
    setIsSent(true);
    setSelectedContract(null);
  };

  return (
    <>
      <BackToButton
        context={selectedContract ? 'Templates' : 'Admin Actions'}
        navigateTo="/admin_actions"
        onClickButton={selectedContract ? handleClickBackButton : undefined}
      />

      <Subtitle hasGutterTop>
        {selectedContract
          ? camelToSentenceCase(selectedContract.template)
          : 'Send Grid Templates'}
      </Subtitle>

      <Paper sx={{ marginTop: 2 }}>
        {isSent && (
          <Typography
            align="center"
            color="primary"
            component="h2"
            sx={{ padding: 3 }}
          >
            Email sent!
          </Typography>
        )}

        {selectedContract ? (
          <SendEmailForm
            contract={selectedContract}
            onSuccess={handleSuccess}
          />
        ) : (
          <List>
            {data?.sendGridContracts.map((contract, index) => (
              <ListItem
                button
                divider={index !== useGetSendGridContracts.length - 1}
                key={contract.template}
                onClick={handleClickTemplate(contract)}
              >
                <ListItemText primary={camelToSentenceCase(contract.template)} />
              </ListItem>
            ))}
          </List>
        )}
      </Paper>
    </>
  );
};

export default SendEmail;
