// Local Typings
type OrganizationRole =
  | 'Adult'
  | 'Director'
  | 'Student';

export const getOrganizationRole = ({
  isAdmin,
  userRoleId,
}: {
  isAdmin: boolean | undefined;
  userRoleId: number | undefined;
}): OrganizationRole | undefined => {
  let role: OrganizationRole | undefined;

  if (isAdmin === undefined || userRoleId === undefined) {
    role = undefined;
  }

  if (userRoleId === 2) {
    if (isAdmin) {
      role = 'Director';
    } else {
      role = 'Adult';
    }
  }

  if (userRoleId === 1) {
    role = 'Student';
  }

  return role;
};
