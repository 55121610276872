// External Dependencies
import Typography from '@mui/material/Typography';
import { useEffect, useMemo } from 'react';
import { CronJobsIndexResponseItem } from '@presto-assistant/api_types/api/admin/cronJobIndex';
import Button from '@mui/material/Button';
import { GridColDef } from '@mui/x-data-grid-pro';

// Internal Dependencies
import { dateTimeColumn } from 'utils';
import { Flex, Subtitle } from 'components/shared';
import {
  CronJobsResponse,
  getCronJobs,
} from 'utils/api/cronJobs';
import { useFetcher } from 'hooks';
import { createDataGridActionsColumn } from 'components/shared/TableDataGrid/helpers';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import TableDataGrid from 'components/shared/TableDataGrid';
import { sendRequest } from 'utils/api';
import { useIsPrestoOwner } from 'hooks/useIsPrestoOwner';

// Local Variables
const triggerCronJob = (cronJobId: number) => {
  sendRequest({
    data: { cronJobId },
    endpoint: '/admin/cron_jobs/trigger_cron_job',
    method: 'POST',
  });
};

const actionsColumn = createDataGridActionsColumn<CronJobsIndexResponseItem>(
  [
    {
      action: (row) => {
        triggerCronJob(row.id);
      },
      text: 'Trigger',
    },
  ],
);

// Component Definition
const ScheduledCronJobsPage = (): JSX.Element => {
  const {
    data,
    error,
    fetchData,
    loading,
  } = useFetcher<CronJobsResponse>({
    apiRequest: getCronJobs,
  });

  const isPrestoOwner = useIsPrestoOwner();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: GridColDef<CronJobsIndexResponseItem>[] = useMemo(() => ([
    {
      field: 'label',
      headerName: 'Task',
      minWidth: 400,
    },
    {
      field: 'scheduleDescription',
      headerName: 'Frequency',
    },
    dateTimeColumn({
      field: 'lastRanAt',
      headerName: 'Last Ran',
    }),
    {
      field: 'lastRanInitiatedBy',
      headerName: 'Initiated By',
    },
    {
      field: 'lastRanSucceeded',
      headerName: 'Succeeded',
      renderCell: (params) => {
        if (params.value === null || params.value === undefined) {
          return null;
        }

        return params.value ? 'Yes' : 'No';
      },
    },
    {
      field: 'lastRanDurationInMs',
      headerName: 'Duration (ms)',
      type: 'number',
    },
    ...(isPrestoOwner && actionsColumn ? [actionsColumn] : []),
  ]), [isPrestoOwner]);

  return (
    <>
      <Flex justifyContent="space-between">
        <Subtitle>
          Scheduled Jobs
        </Subtitle>

        {isPrestoOwner && (
          <Button
            href={`${process.env.REACT_APP_API_URL}/admin/queues/queue/cron`}
          >
            View Queued Jobs
          </Button>
        )}
      </Flex>

      {loading && (
        <Typography>
          Loading...
        </Typography>
      )}

      {Boolean(error) && (
        <Typography color="error">
          Error: {error as unknown as string}
        </Typography>
      )}

      <DataGridContainer>
        <TableDataGrid
          columns={columns}
          loading={loading}
          rows={data?.jobs ?? []}
          tableResource="cronJobs"
          withSearch
        />
      </DataGridContainer>
    </>
  );
};

export default ScheduledCronJobsPage;
