// External Dependencies
import { createActionCreator } from 'deox';

// Internal Dependencies
import { MutationFlag } from 'types/api';

// Local Typings
export interface TableSelection {
  ids: string[];
  selectionType: keyof typeof MutationFlag;
}

export type TableResource =
  | 'cronJobs'
  | 'districtLicenses'
  | 'districts'
  | 'feedback'
  | 'imports'
  | 'stripeWebhookEvents'
  | 'organizations'
  | 'inventoryVendors';

// Actions
export const updateIsPaginatedListDataLoaded = createActionCreator(
  'UPDATE_IS_PAGINATED_LIST_DATA_LOADED',
  (resolve) => ({
    isPaginatedListDataLoaded,
  }: {
    isPaginatedListDataLoaded: boolean;
  }) => resolve(isPaginatedListDataLoaded),
);

export const updateTableQueryParams = createActionCreator(
  'UPDATE_TABLE_QUERY_PARAMS',
  (resolve) => ({
    key,
    value = '',
  }: {
    key: TableResource;
    value: string | undefined;
  }) => {
    return resolve({ [key]: value });
  },
);

export const updateTableSelections = createActionCreator(
  'UPDATE_TABLE_SELECTIONS',
  (resolve) => ({
    key,
    value,
  }: {
    key: TableResource;
    value: TableSelection;
  }) => resolve({ [key]: value }),
);
