// External Dependencies
import { NavigateFunction } from 'react-router-dom';

// Local Dependencies
import parseSearch from './parse_search';
import { stringifyQueryParams } from './stringify_query_params';

// Util Definitions
export const getSearchWithRedirectUrl = () => {
  const searchParams = parseSearch(window.location.search);

  const redirectUrl = searchParams.redirectUrl ?? window.location.pathname;

  delete searchParams.redirectUrl;

  const existingParsedQueryString = stringifyQueryParams(searchParams);

  const queryParams = {
    ...(redirectUrl && {
      redirect_url: redirectUrl,
    }),
    ...(existingParsedQueryString && {
      search_params: encodeURIComponent(existingParsedQueryString),
    }),
  };

  return stringifyQueryParams(queryParams);
};

const redirectToParams = (navigate: NavigateFunction) => {
  const { search } = window.location;

  const queryParams = parseSearch(search);

  if (queryParams.redirectUrl) {
    const queryString = queryParams.searchParams
      ? `?${queryParams.searchParams}`
      : '';

    navigate(`${queryParams.redirectUrl}${queryString}`);
  }
};

export const redirectUser = (navigate: NavigateFunction) => {
  const hasRedirectUrl = window.location.search.includes('redirect_url');

  if (hasRedirectUrl) {
    return redirectToParams(navigate);
  }

  return navigate('/dashboard');
};
