// External Dependencies
import { useNavigate } from 'react-router-dom';
import { FC } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

// Local Typings
interface Props {
  organizations: GQL.IOrganization[];
}

// Component Definition
const DistrictOrgList: FC<Props> = ({ organizations }) => {
  const navigate = useNavigate();

  return (
    <List dense>
      {organizations.map((org, index, fullList) => {
        const handleNavigateToOrgShowPage = () => {
          navigate(`/organizations/${org.id}`);
        };

        const creatorInformation = org.creator
          ? `${org.creator.firstName} ${org.creator.lastName} (${org.creator.email})`
          : 'No director';

        return (
          <ListItem
            button
            divider={index !== fullList.length - 1}
            key={org.id}
            onClick={handleNavigateToOrgShowPage}
          >
            <ListItemText
              primary={org.label}
              secondary={(
                <>
                  <Typography
                    color="textSecondary"
                    component="span"
                    variant="body2"
                  >
                    {`${org.entityType.label} ${org.organizationType.label}`}
                  </Typography>

                  <br />

                  <Typography
                    color="textSecondary"
                    component="span"
                    variant="body2"
                  >
                    {creatorInformation}
                  </Typography>
                </>
              )}
            />
          </ListItem>
        );
      })}
    </List>
  );
};

export default DistrictOrgList;
