import { combineReducers } from 'redux';
import { createReducer } from 'deox';

import { AdminTokenPayload, parseJwt } from 'utils/lib/token';

import { getToken } from '../../utils/cookies';
import {
  logoutCurrentUser,
  setUser,
} from '../actions';

// Local Typings
interface Action {
  payload: any;
  type: string;
}
// Taken from shape of data in the Redux dev tools
export interface AdminUser {
  id: string;
  email: string;
  createdAt: Date;
  updatedAt: Date | undefined;
  deletedAt: Date | null | undefined;
  deletedById: string | null;
  deletedNote: string | null;
  firstName: string;
  lastName: string;
  passwordHash: string;
}
export interface UserState {
  data: AdminUser | null;
  tokenData: AdminTokenPayload | null;
}

// Local Variables
const USER_INITIAL_STATE: UserState = {
  data: null,
  tokenData: null,
};

// Reducers
const data = createReducer(USER_INITIAL_STATE.data, (handleAction) => [
  handleAction(setUser, (_state, { payload }: Action) => payload),
  handleAction(logoutCurrentUser, () => USER_INITIAL_STATE.data),
]);
const tokenData = createReducer(USER_INITIAL_STATE.tokenData, (handleAction) => [
  handleAction(setUser, () => {
    const token = getToken();

    const parsedToken: AdminTokenPayload | null = token ? parseJwt(token) : null;

    return parsedToken;
  }),
  handleAction(logoutCurrentUser, () => USER_INITIAL_STATE.tokenData),
]);

export default combineReducers<UserState>({ data, tokenData });
