// External Dependencies
import { useMemo } from 'react';

// Internal Dependencies
import { useGetDistricts } from 'gql/queries/districts';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import TableDataGrid from 'components/shared/TableDataGrid';

// Local Dependencies
import { useColumns } from './hooks';

// Local Variables
const handleRowClick = (id: string) => `/districts/${id}`;

// Component Definition
const DistrictsTable = (): JSX.Element => {
  const { data, loading } = useGetDistricts();

  const columns = useColumns();

  const addButtonProps = useMemo(() => ({
    disabled: true,
    label: 'District',
  }), []);

  return (
    <DataGridContainer>
      <TableDataGrid
        addButtonProps={addButtonProps}
        clickRowTo={handleRowClick}
        columns={columns}
        loading={loading}
        rows={data?.districts ?? []}
        tableResource="districts"
        withSearch
      />
    </DataGridContainer>
  );
};

export default DistrictsTable;
