// External Dependencies
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import StoreIcon from 'mdi-material-ui/Store';
import ToolsIcon from 'mdi-material-ui/Tools';

// Internal Dependencies
import { Flex, Subtitle } from 'components/shared';
import OptionCard from 'components/shared/OptionCard';

// Component Definition
const Vendors = (): JSX.Element => {
  const navigate = useNavigate();

  const handlePressInventoryVendors = useCallback(() => {
    navigate('/vendors/inventory_vendors');
  }, [navigate]);

  const handlePressInventoryServiceVendors = useCallback(() => {
    navigate('/vendors/inventory_service_vendors');
  }, [navigate]);

  return (
    <>
      <Subtitle>
        Vendors
      </Subtitle>

      <Flex gap={4}>
        <OptionCard
          icon={StoreIcon}
          onClick={handlePressInventoryVendors}
          title="Inventory Vendors"
        />

        <OptionCard
          disabled
          icon={ToolsIcon}
          onClick={handlePressInventoryServiceVendors}
          title="Inventory Service Vendors"
        />
      </Flex>
    </>
  );
};

export default Vendors;
