// External Dependencies
import { useEffect, useRef, useState } from 'react';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { getFullName } from '@presto-assistant/api_types/utils';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';

// Internal Dependencies
import { EnhancedCard, Flex } from 'components/shared';
import { useGetImportNotes } from 'hooks/api/imports';
import { useGetCurrentUser } from 'hooks/useGetCurrentUser';

// Local Dependencies
import CreateNoteInput from './CreateNoteInput';
import MessageBlock from './MessageBlock';

// Local Typings
interface Props {
  importId: string;
}

// Local Variables
const noteContainerHeight = 520;

// Component Definition
const ImportNotes = ({
  importId,
}: Props): JSX.Element => {
  const [hasLoadedNotes, setHasLoadedNotes] = useState(false);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const user = useGetCurrentUser();

  const {
    data: importNotesData,
  } = useGetImportNotes(importId);

  useEffect(() => {
    if (wrapperRef.current) {
      // smooth scroll
      wrapperRef.current.scrollTo({
        behavior: hasLoadedNotes ? 'smooth' : 'auto',
        top: wrapperRef.current.scrollHeight,
      });

      // set a timeout to ensure the scroll has completed
      // this prevents smooth scroll on initial load
      setTimeout(() => {
        setHasLoadedNotes(true);
      }, 1_000);
    }
  }, [importNotesData]);

  return (
    <Container maxWidth="sm">
      <EnhancedCard>
        <CardContent>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              paddingBottom: 2,
            }}
          >
            <Typography
              component="h3"
              variant="h6"
            >
              Notes
            </Typography>
          </Box>

          <Box
            ref={wrapperRef}
            sx={{
              height: noteContainerHeight,
              overflow: 'auto',
            }}
          >
            {importNotesData?.data.data.importNotes.length === 0 ? (
              <Flex
                alignContent="center"
                height={noteContainerHeight}
                justifyContent="center"
                width="100%"
              >
                <Typography
                  align="center"
                  color="textSecondary"
                >
                  <Typography component="span">
                    No messages yet
                  </Typography>

                  <Typography
                    component="span"
                    display="block"
                    marginTop={2}
                  >
                    <SpeakerNotesOffIcon fontSize="large" />
                  </Typography>
                </Typography>
              </Flex>
            ) : (
              <List dense>
                {importNotesData?.data.data.importNotes.map((note) => (
                  <MessageBlock
                    author={getFullName({
                      firstName: note.authoredByAdminUserFirstName,
                      lastName: note.authoredByAdminUserLastName,
                    })}
                    createdAt={note.createdAt}
                    key={note.id}
                    note={note.note}
                    outbound={note.authoredByAdminUserId === (user as any)?.id}
                  />
                ))}
              </List>
            )}
          </Box>

          <CreateNoteInput importId={importId} />
        </CardContent>
      </EnhancedCard>
    </Container>
  );
};

export default ImportNotes;
