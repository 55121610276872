// External Dependencies
import { GridColDef } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';

// Internal Dependencies
import { dateColumn, getFullName } from 'utils';
import {
  convertCentsToDollars,
  displayPriceStringFromDollarAmount,
} from 'utils/lib/currency';
import { useGetDistrictTotals } from 'hooks/useGetDistrictTotals';

// Local Variables
export const useColumns = (extraColumns?: GridColDef[]) => {
  const {
    totalDistrictValueInCents,
  } = useGetDistrictTotals();

  return useMemo(() => {
    const columns: GridColDef<GQL.IDistrictIndex>[] = [
      {
        field: 'label',
        headerName: 'Name',
        minWidth: 352,
      },
      {
        field: 'director',
        headerName: 'Director',
        valueGetter: (params) => getFullName({
          firstName: params.row.dfaMemberFirstName,
          lastName: params.row.dfaMemberLastName,
        }),
        width: 160,
      },
      {
        field: 'dfaMemberEmail',
        headerName: 'Director Email',
        minWidth: 160,
      },
      {
        field: 'isVerified',
        headerName: 'Verified',
        minWidth: 88,
        type: 'boolean',
      },
      {
        align: 'right',
        field: 'licensePriceInCents',
        headerName: 'License Price',
        maxWidth: 120,
        valueFormatter: (params) =>
          displayPriceStringFromDollarAmount(convertCentsToDollars(params.value)),
      },
      {
        align: 'right',
        field: 'numberOfActiveLicenses',
        headerName: '# Active Licenses',
        maxWidth: 150,
      },
      {
        align: 'right',
        field: 'numberOfComplimentaryActiveLicenses',
        headerName: '# Comp. Licenses',
        maxWidth: 150,
      },
      {
        align: 'right',
        field: 'numberOfUnredeemedLicenses',
        headerName: '# Unredeemed Licenses',
        minWidth: 180,
      },
      {
        align: 'right',
        field: 'numberOfOrganizations',
        headerName: '# Organizations',
        maxWidth: 150,
      },
      {
        align: 'right',
        field: 'districtValue',
        headerName: 'Annual Value',
        maxWidth: 140,
        valueFormatter: (params) =>
          displayPriceStringFromDollarAmount(convertCentsToDollars(params.value)),
        valueGetter: (params) => params.row.numberOfActiveLicenses * params.row.licensePriceInCents,
      },
      {
        align: 'right',
        field: 'districtValuePercentage',
        headerName: 'Annual Value %',
        maxWidth: 140,
        valueFormatter: (params) => `${(params.value as number).toFixed(2)}%`,
        valueGetter: (params) => {
          const totalValueInCents = params.row.numberOfActiveLicenses
            * params.row.licensePriceInCents;

          return Math.round((totalValueInCents / totalDistrictValueInCents) * 10_000) / 100;
        },
      },
      {
        align: 'right',
        field: 'totalPaidLifetimeInCents',
        headerName: 'Total Paid Lifetime',
        maxWidth: 140,
        valueFormatter: (params) =>
          displayPriceStringFromDollarAmount(convertCentsToDollars(params.value)),
        valueGetter: (params) => params.row.totalPaidLifetimeInCents,
      },
      dateColumn({
        field: 'latestPaymentDate',
        headerName: 'Latest Payment Date',
      }),
      {
        field: 'taxExemptFormS3Key',
        headerName: 'Tax-Exempt',
        type: 'boolean',
      },
      ...(extraColumns ?? []),
    ];

    return columns;
  }, [
    extraColumns,
    totalDistrictValueInCents,
  ]);
};
