// External Dependencies
import {
  CardContent,
  CardHeader,
  Container,
  Typography,
  lighten,
} from '@mui/material';
import { Component, ErrorInfo, ReactElement } from 'react';
import styled from 'styled-components';

// Internal Dependencies
import {
  EnhancedAlert,
  EnhancedCard,
} from 'components/shared';

// Local Dependencies
import Footer from './Footer';

// Local Variables
const StyledRoot = styled.div(({ theme }) => ({
  '.MuiCardHeader-root': {
    margin: 0,
    padding: 0,
  },
  '.MuiCardHeader-title': {
    background: theme.palette.prestoPrimaryMain,
    color: theme.palette.common.white,
    padding: theme.spacing(2.5, 2),
    textTransform: 'uppercase',
  },
  '.MuiContainer-root': {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    width: '100%',
  },
  '.MuiPaper-root': {
    position: 'relative',
    zIndex: 2,
  },
  '.backgroundPattern': {
    backgroundColor: theme.palette.common.white,
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.grey[200],
    height: 872,
    left: 0,
    overflow: 'hidden',
    position: 'absolute',
    right: 0,
    top: -300,
    transform: 'skewY(-12deg)',
    zIndex: 0,
  },
  '.errorContent': {
    color: theme.palette.error.main,
    fontSize: '1rem',
  },
  '.errorMessage': {
    marginBottom: theme.spacing(1),
    position: 'relative',
    zIndex: 2,
  },
  '.errorMessageText': {
    fontWeight: 600,
    position: 'relative',
    zIndex: 2,
  },
  '.heading': {
    margin: '48px 0',
    position: 'relative',
    zIndex: 2,
  },
  backgroundColor: lighten(theme.palette.prestoPrimaryMain, 0.85),
  main: {
    flexGrow: 1,
    zIndex: 2,
  },
  maxWidth: '100%',
}));

// Local Typings
interface Props {
  children: ReactElement;
}
interface State {
  error: Error;
  hasError: boolean;
}

// Component Definition
class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: {
        message: '',
        name: '',
        stack: '',
      },
      hasError: false,
    };
  }

  static getDerivedStateFromError(error: Error) {
    console.log('ErrorBoundary : error', error);
    // Update state so the next render will show the fallback UI.
    return { error, hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    const { children } = this.props;
    const { error, hasError } = this.state;

    if (hasError) {
      return (
        <StyledRoot>
          <Container maxWidth="md">
            <main>
              <Typography
                className="heading"
                component="h1"
                variant="h3"
              >
                Something went wrong.
                <span
                  aria-label="pleading face emoji"
                  role="img"
                >
                  🥹
                </span>
              </Typography>

              <EnhancedAlert
                severity="warning"
                title="Oh no"
              >
                <Typography>
                  It seems an error occurred.
                </Typography>
              </EnhancedAlert>

              <EnhancedCard sx={{ mt: 3 }}>
                <CardHeader
                  component="h2"
                  title="Error Details"
                  titleTypographyProps={{
                    variant: 'h5',
                  }}
                />

                <CardContent>
                  <Typography gutterBottom>
                    The error message below might help figure out what happened:
                  </Typography>

                  <blockquote className="errorContent">
                    <div className="errorMessage">
                      <code className="errorMessageText">{error.message}</code>
                    </div>

                    <div>
                      <code>{error.stack}</code>
                    </div>
                  </blockquote>
                </CardContent>
              </EnhancedCard>

              <div className="backgroundPattern" />
            </main>

            <Footer />
          </Container>
        </StyledRoot>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
