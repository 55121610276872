// External Dependencies
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import { CreateImportNoteRequest } from '@presto-assistant/api_types/api/admin/import';
import { Form, Formik, FormikHelpers } from 'formik';
import { useCallback } from 'react';

// Internal Dependencies
import { useCreateImportNote } from 'hooks/api/imports';
import { CustomInput, Flex } from 'components/shared';

// Local Typings
interface Props {
  importId: string;
}

// Component Definition
const CreateNoteInput = ({
  importId,
}: Props): JSX.Element => {
  const {
    mutate: createImportNote,
  } = useCreateImportNote();

  const handleFormikSubmit = useCallback((
    values: CreateImportNoteRequest['body'],
    formikHelpers: FormikHelpers<CreateImportNoteRequest['body']>,
  ) => {
    createImportNote({
      body: values,
      params: {
        importId,
      },
    }, {
      onSuccess: () => {
        formikHelpers.resetForm();
      },
    });
  }, [importId]);

  return (
    <Formik<CreateImportNoteRequest['body']>
      initialValues={{
        note: '',
      }}
      onSubmit={handleFormikSubmit}
    >
      {({
        values,
      }) => (
        <Form>
          <Flex flexWrap="nowrap">
            <CustomInput
              maxRows={4}
              multiline
              name="note"
              placeholder="Type your message..."
              size="small"
            />

            <IconButton
              color="primary"
              disabled={!values.note.trim()}
              size="small"
              type="submit"
            >
              <SendIcon />
            </IconButton>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default CreateNoteInput;
