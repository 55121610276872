// External Dependencies
import { createActionCreator } from 'deox';

// Local Dependencies
import constants from './constants';

// Local Variables
const {
  LOGOUT_CURRENT_USER,
  SET_USER,
  UPDATE_DEVICE_INNER_WIDTH,
} = constants;

// Actions
export const logoutCurrentUser = createActionCreator(LOGOUT_CURRENT_USER);

export const setUser = createActionCreator(
  SET_USER,
  (resolve) => (user: any) =>
    resolve(user),
);

export const updateDeviceInnerWidth = createActionCreator(
  UPDATE_DEVICE_INNER_WIDTH,
  (resolve) => (innerWidth: number) => resolve(innerWidth),
);
