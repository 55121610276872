// External Dependencies
import {
  Link,
  useMatch,
} from 'react-router-dom';
import { SvgIconProps } from '@mui/material';
import { FC, ReactElement, forwardRef } from 'react';
import styled from 'styled-components';

// Local Dependencies
import EnhancedMenuItem from './EnhancedMenuItem';

// Local Typings
interface Props {
  children?: ReactElement | ReactElement[];
  className?: string;
  contentRef?: React.Ref<HTMLDivElement>;
  icon?: (props: SvgIconProps) => ReactElement<SvgIconProps>;
  isActive?: boolean;
  match?: string;
  onClick?: () => void;
  text?: string;
  to: string;
}

type Ref = React.HTMLAttributes<HTMLAnchorElement>

// Local Variables
const StyledLink = styled(Link)({
  color: 'inherit',
  textDecoration: 'none',
});

// Component Definition
const NavMenuItem: FC<Props> = forwardRef<
  Ref,
  Props
>((
  {
    children,
    isActive,
    match,
    text,
    to,
    ...props
  },
  ref,
) => {
  const isMatched = useMatch(`${match ?? to}/*`);

  return (
    <StyledLink
      ref={ref as any}
      to={to}
    >
      <EnhancedMenuItem
        isActive={isActive || Boolean(isMatched)}
        text={text}
        {...props}
      >
        {children}
      </EnhancedMenuItem>
    </StyledLink>
  );
});

export default NavMenuItem;
