// Local Dependencies
import CodeBlock from './CodeBlock';

// Local Typings
export interface ApiRequestDetailsProps {
  body: string;
  method: string;
  path: string;
  response: object;
  status: number;
}
interface Props {
  apiRequestDetails: ApiRequestDetailsProps;
}

// Component Definition
const ApiRequestDetails = ({
  apiRequestDetails,
}: Props): JSX.Element => {
  return (
    <>
      <CodeBlock
        blockTitle="Request Details"
        contents={{
          body: apiRequestDetails.body,
          method: apiRequestDetails.method,
          path: apiRequestDetails.path,
        }}
        noMarginTop
      />

      <CodeBlock
        blockTitle="Response Status"
        contents={{ status: apiRequestDetails.status }}
      />

      <CodeBlock
        blockTitle="Response Body"
        contents={apiRequestDetails.response}
      />
    </>
  );
};

export default ApiRequestDetails;
