import gql from 'graphql-tag';
import { useQueryEnhanced } from 'hooks';

interface StripeWebhookEventResponse {
  stripeWebhookEvent: GQL.IStripeWebhookEvent;
}

interface StripeWebhookEventsResponse {
  stripeWebhookEvents: GQL.IStripeWebhookEvent[];
}

interface StripeWebhookEventTypesResponse {
  stripeWebhookEventTypes: string[];
}

const GET_STRIPE_WEBHOOK_EVENT = gql`
  query StripeWebhookEvent($id: ID!) {
    stripeWebhookEvent(id: $id) {
      createdAt
      eventData
      eventDate
      eventId
      eventType
      id
      processedAt
    }
  }
`;

const GET_STRIPE_WEBHOOK_EVENTS = gql`
  query StripeWebhookEvents($input: StripeWebhookEventsInput!) {
    stripeWebhookEvents(input: $input) {
      createdAt
      eventData
      eventDate
      eventId
      eventType
      id
      processedAt
    }
  }
`;

const GET_STRIPE_WEBHOOK_EVENT_TYPES = gql`
  query StripeWebhookEventTypes {
    stripeWebhookEventTypes
  }
`;

export const useGetStripeWebhookEvents = (input: GQL.IStripeWebhookEventsInput) =>
  useQueryEnhanced<StripeWebhookEventsResponse>(
    GET_STRIPE_WEBHOOK_EVENTS,
    {
      variables: {
        input,
      },
    },
  );

export const useGetStripeWebhookEvent = (id: string) =>
  useQueryEnhanced<StripeWebhookEventResponse>(
    GET_STRIPE_WEBHOOK_EVENT,
    {
      skip: !id,
      variables: {
        id,
      },
    },
  );

export const useGetStripeWebhookEventTypes = () =>
  useQueryEnhanced<StripeWebhookEventTypesResponse>(GET_STRIPE_WEBHOOK_EVENT_TYPES);
