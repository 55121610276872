// External Dependencies
import { ImportOriginalSources, ImportResources, ImportStatuses } from '@presto-assistant/api_types';
import { ImportsGetAllResponseItem } from '@presto-assistant/api_types/api/admin/import';
import { getFullNameWithEmail } from '@presto-assistant/api_types/utils';
import { toTitleCase } from '@presto-assistant/api_types/utils/toTitleCase';
import { useMemo } from 'react';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import { dateTimeColumn } from 'utils';
import { mapEnum } from 'utils/lib/mapEnum';

// Local Variables
export const useColumns = (extraColumns?: DataGridColDef<ImportsGetAllResponseItem>[]) => {
  const importOriginalSourceOptions = useMemo(() => mapEnum(ImportOriginalSources).map((source) => {
    return {
      label: toTitleCase(source.label),
      value: source.label,
    };
  }), []);

  const importResourceOptions = useMemo(() => mapEnum(ImportResources).map((resource) => {
    return {
      label: toTitleCase(resource.label),
      value: resource.label,
    };
  }), []);

  const importStatusOptions = useMemo(() => mapEnum(ImportStatuses).map((status) => {
    return {
      label: toTitleCase(status.label),
      value: status.label,
    };
  }), []);

  return useMemo(() => {
    const columns: DataGridColDef<ImportsGetAllResponseItem>[] = [
      dateTimeColumn({
        field: 'createdAt',
        headerName: 'Submitted',
      }),
      {
        field: 'organizationLabel',
        headerName: 'Organization',
        minWidth: 220,
      },
      {
        field: 'createdByMemberId',
        headerName: 'Created By',
        minWidth: 300,
        valueGetter: (params) => getFullNameWithEmail({
          email: params.row.createdByMemberEmail,
          firstName: params.row.createdByMemberFirstName,
          lastName: params.row.createdByMemberLastName,
        }),
      },
      {
        field: 'importResourceId',
        headerName: 'Resource',
        type: 'singleSelect',
        valueGetter: (params) =>
          ImportResources[params.value],
        valueOptions: importResourceOptions,
      },
      {
        field: 'importStatusId',
        headerName: 'Status',
        minWidth: 160,
        type: 'singleSelect',
        valueGetter: (params) =>
          ImportStatuses[params.value],
        valueOptions: importStatusOptions,
      },
      {
        field: 'importOriginalSourceId',
        headerName: 'Original Source',
        type: 'singleSelect',
        valueGetter: (params) =>
          ImportOriginalSources[params.value],
        valueOptions: importOriginalSourceOptions,
      },
      {
        field: 'claimedByAdminUserId',
        headerName: 'Claimed By',
        minWidth: 300,
        valueGetter: (params) => (params.row.claimedByAdminUserId
          ? getFullNameWithEmail({
            email: params.row.claimedByAdminUserEmail ?? '',
            firstName: params.row.claimedByAdminUserFirstName ?? '',
            lastName: params.row.claimedByAdminUserLastName ?? '',
          }) : null),
      },
      {
        field: 'id',
        headerName: 'ID',
        minWidth: 352,
      },
      ...(extraColumns ?? []),
    ];

    return columns;
  }, [
    extraColumns,
  ]);
};
