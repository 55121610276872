// External Dependencies
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// Internal Dependencies
import {
  APP_BAR_HEIGHT,
  CLOSED_DRAWER_WIDTH,
  CLOSED_MOBILE_DRAWER_WIDTH,
  DRAWER_WIDTH,
  MOBILE_APP_BAR_HEIGHT,
  MOBILE_DRAWER_WIDTH,
} from 'utils/constants';
import { isMobileScreenSize } from 'state/selectors';

// Local Typings
interface Props {
  children: ReactElement | ReactElement[];
  isDrawerOpen: boolean;
  hideNavigation?: boolean;
}
interface StyledDivProps {
  $drawerWidth: number;
}

// Local Variables
const StyledDiv = styled.div<StyledDivProps>(({
  $drawerWidth,
  theme,
}) => ({
  '&#simple-container': {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5),
      paddingLeft: `calc(${theme.spacing(2.5)} + ${$drawerWidth}px)`,
      paddingTop: `calc(${theme.spacing(4)} + ${MOBILE_APP_BAR_HEIGHT}px)`,
    },
    backgroundColor: theme.palette.grey[50],
    display: 'flex',
    flexFlow: 'column',
    flexGrow: 1,
    padding: theme.spacing(1.5, 2.5),
    paddingLeft: `calc(${theme.spacing(2.5)} + ${$drawerWidth}px)`,
    paddingTop: `calc(${theme.spacing(4)} + ${APP_BAR_HEIGHT}px)`,
    transition: theme.transitions.create(['padding-left'], {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    }),
  },
}));

const StyledMain = styled.main({
  '&#main': {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
  },
});

// Component Definition
const SimpleContainer = ({
  children,
  hideNavigation,
  isDrawerOpen,
}: Props): JSX.Element => {
  const isMobileScreen = useSelector(isMobileScreenSize);

  let drawerWidth = isMobileScreen ? CLOSED_MOBILE_DRAWER_WIDTH : CLOSED_DRAWER_WIDTH;

  if (isDrawerOpen) {
    drawerWidth = isMobileScreen ? MOBILE_DRAWER_WIDTH : DRAWER_WIDTH;
  }

  return (
    <StyledDiv
      $drawerWidth={hideNavigation ? 0 : drawerWidth}
      id="simple-container"
    >
      <StyledMain id="main">
        {children}
      </StyledMain>
    </StyledDiv>
  );
};

export default SimpleContainer;
