// External Dependencies
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import { lighten } from '@mui/material/styles';
import { displaySchoolYearString } from '@presto-assistant/api_types/utils';

// Internal Dependencies
import {
  EnhancedCardContent,
  ShowPageDataDisplay,
} from 'components/shared';
import { getOrganizationRole } from 'utils/lib/get_organization_role';

// Local Typings
interface Props {
  key: string;
  membership?: DB.OrganizationMembership;
}

// Local Variables
const StyledEnhancedCardContent = styled(EnhancedCardContent)(({ theme }) => ({
  '.membership-data-item': {
    marginBottom: 0,
    marginTop: 0,
  },
  '.membership-role': {
    marginLeft: theme.spacing(0.5),
  },
  '.membership-school-year': {
    fontWeight: theme.typography.fontWeightMedium,
  },
  '.membership-top-row': {
    marginBottom: theme.spacing(1.5),
  },
  background: lighten(theme.palette.stripeBlue['300'], 0.92),
  borderTop: `1px solid ${theme.palette.divider}`,
  width: '100%',
})) as typeof Typography;

// Component Definition
const MembershipItem = ({ key, membership }: Props): JSX.Element | null => {
  if (!membership) {
    return null;
  }

  const organizationRole = getOrganizationRole({
    isAdmin: membership.admin,
    userRoleId: membership.userRoleId,
  });

  return (
    <StyledEnhancedCardContent key={key}>
      <Box
        className="membership-top-row"
        display="flex"
      >
        <Typography
          className="membership-school-year"
          gutterBottom
        >
          {displaySchoolYearString(membership.schoolYearEnding ?? 0)}
        </Typography>

        {organizationRole && (
          <Typography className="membership-role">
            — {organizationRole}
          </Typography>
        )}
      </Box>

      <div className="auth-user-data">
        <ShowPageDataDisplay
          className="membership-data-item"
          label="Is Director"
          type="boolean"
          value={membership.admin}
        />

        <ShowPageDataDisplay
          className="membership-data-item"
          label="Active"
          type="boolean"
          value={membership.isActive}
        />

        <ShowPageDataDisplay
          className="membership-data-item"
          label="Grade"
          value={membership.grade}
        />

        <ShowPageDataDisplay
          className="membership-data-item"
          label="Received Welcome Email"
          type="boolean"
          value={Boolean(membership.receivedWelcomeEmailAt)}
        />
      </div>
    </StyledEnhancedCardContent>
  );
};

export default MembershipItem;
