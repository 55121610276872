// External Dependencies
import Chip, { ChipProps } from '@mui/material/Chip';
import { toTitleCase } from '@presto-assistant/api_types/utils/toTitleCase';

// Local Typings
interface Props {
  color?: ChipProps['color'];
  fontWeight?: 400 | 500 | 600;
  label: string;
  size?: ChipProps['size'];
  variant?: ChipProps['variant'];
}

// Component Definition
const MemberSearchChip = ({
  color = 'primary',
  fontWeight = 600,
  label,
  size = 'medium',
  variant = 'filled',
}: Props): JSX.Element => {
  return (
    <Chip
      color={color}
      label={toTitleCase(label)}
      size={size}
      sx={{
        fontSize: '1rem',
        fontWeight,
        letterSpacing: 1.1,
        padding: '4px 8px',
      }}
      variant={variant}
    />
  );
};

export default MemberSearchChip;
