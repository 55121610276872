// External Dependencies
import { FC, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';

// Internal Dependencies
import { logoutCurrentUser } from 'state/actions';
import {
  clearTokens,
  getToken,
} from 'utils/cookies';
import client from 'gql/client';

// Component Definition
const LogoutPage: FC = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  // The user might have been sent here with search params
  //  that we'll use for the redirect_url
  const { search } = useLocation();

  const [token, setToken] = useState(getToken());

  useEffect(() => {
    const handleLogout = async () => {
      await clearTokens();
      dispatch(logoutCurrentUser());
      setToken(null);
      client.clearStore();
      queryClient.clear();
    };

    handleLogout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Append the redirect_url to the login path if it exists
  const redirectUrlFromParams = `/login${search ?? ''}`;

  return token ? (
    <div>
      Logging out...
    </div>
  ) : (
    <Navigate
      replace
      to={redirectUrlFromParams}
    />
  );
};

export default LogoutPage;
