// Local Dependencies
import device from './device_reducer';
import table, { TableState } from '../table/reducers';
import user, { UserState } from './user_reducer';

// Local Typings
export interface State {
  device: any;
  table: TableState;
  user: UserState;
}

export default {
  device,
  table,
  user,
};
