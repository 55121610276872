// External Dependencies
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import {
  DetailCard,
  Subtitle,
} from 'components/shared';
import { isMobileOrTabletScreenSize } from 'state/selectors';
import { useIsOpen } from 'hooks/useIsOpen';
import { useIsPrestoOwner } from 'hooks/useIsPrestoOwner';

// Local Dependencies
import OrganizationDistrictContent from './OrganizationDistrictContent';
import OrganizationInfoContent from './OrganizationInfoContent';
import VerifyOrganizationDialog from './VerifyOrganizationDialog';

// Local Typings
interface Props {
  organization: GQL.IOrganization;
}

// Local Variables
const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  marginTop: theme.spacing(3),
}));

const StyledOrganizationInfoContent = styled(Box)({
  h3: {
    fontSize: '1.1rem',
  },
});

// Component Definition
const OrganizationInfo = ({ organization }: Props): JSX.Element => {
  const isMobileOrTabletScreen = useSelector(isMobileOrTabletScreenSize);

  const {
    isOpen: isVerifyOrganizationDialogOpen,
    toggleIsOpen: toggleVerifyOrganizationDialogIsOpen,
  } = useIsOpen();

  const isPrestoOwner = useIsPrestoOwner();

  const subheaderTopRow = `${organization.entityType.label} ${organization.organizationType.label}`;

  const isOrganizationVerified = organization.isVerified;

  const subheader = (
    <Box
      display={isMobileOrTabletScreen ? 'block' : 'flex'}
      justifyContent="space-between"
      marginY={1}
    >
      <div>
        <Box marginY={1}>
          <StyledTypography color="textPrimary">{subheaderTopRow}</StyledTypography>
        </Box>
        <Typography color="textPrimary">{organization.addressOne}</Typography>
        <Typography color="textPrimary">{organization.city}, {organization.state.label} {organization.zipcode}</Typography>
        <Typography color="textPrimary">{organization.phoneNumber}</Typography>
      </div>

      <Box
        display="flex"
        flexDirection={isMobileOrTabletScreen ? 'row' : 'column'}
        justifyContent="flex-end"
        marginTop={isMobileOrTabletScreen ? 2 : 'inherit'}
      >
        <Collapse in={isPrestoOwner && !isOrganizationVerified}>
          <Button
            onClick={toggleVerifyOrganizationDialogIsOpen}
            size={isMobileOrTabletScreen ? 'small' : 'medium'}
            startIcon={<ThumbUpIcon />}
            variant="outlined"
          >
            Verify Organization
          </Button>
        </Collapse>
      </Box>
    </Box>
  );

  return (
    <>
      <Box>
        <Subtitle>Organization Info</Subtitle>
      </Box>

      <StyledOrganizationInfoContent>
        <DetailCard
          content={(
            <>
              <OrganizationDistrictContent
                organization={organization}
              />

              <OrganizationInfoContent
                organization={organization}
              />
            </>
          )}
          subheader={subheader}
          title={organization.label}
        />
      </StyledOrganizationInfoContent>

      <VerifyOrganizationDialog
        onClose={toggleVerifyOrganizationDialogIsOpen}
        open={isVerifyOrganizationDialogOpen}
        organizationId={organization.id}
      />
    </>
  );
};

export default OrganizationInfo;
