// External Depencendies
import { Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FC, useLayoutEffect } from 'react';

// Internal Depencendies
import { updateDeviceInnerWidth } from 'state/actions';
import TaxExemptFormNew from 'pages/Districts/TaxExemptForm/New';
import AdminActions from 'pages/AdminActions';
import Auth from 'components/Auth';
import BaseLayout from 'components/BaseLayout';
import DashboardPage from 'pages/Dashboard';
import DistrictShow from 'pages/Districts/Show';
import DistrictLicenses from 'pages/Districts/Show/Licenses/All';
import DistrictLicensesNew from 'pages/Districts/Show/Licenses/New';
import DistrictsNew from 'pages/Districts/New';
import DistrictsPage from 'pages/Districts/All';
import FeatureFlagsPage from 'pages/ServerSettings/FeatureFlags';
import FeeCalculator from 'pages/AdminActions/FeeCalculator';
import FeedbackPage from 'pages/Feedback/All';
import FeedbackShowPage from 'pages/Feedback/Show';
import InventoryVendorsPage from 'pages/Vendors/InventoryVendors/All';
import LoginPage from 'pages/Login';
import LogoutPage from 'pages/Logout';
import OrganizationShow from 'pages/Organization';
import OrganizationsPage from 'pages/Organizations';
import ScheduledCronJobsPage from 'pages/ScheduledCronJobs';
import Search from 'pages/Search';
import SendEmail from 'pages/AdminActions/SendEmail';
import ServerSettingsPage from 'pages/ServerSettings';
import StripeWebhookEvents from 'pages/AdminActions/StripeWebhookEvents';
import StripeWebhookEvent from 'pages/AdminActions/StripeWebhookEvent';
import UpdateAppSettings from 'pages/AdminActions/UpdateAppSettings';
import usePrevious from 'hooks/usePrevious';
import useWindowSize from 'hooks/useWindowSize';
import ApiPlayground from './pages/ApiPlayground';
import Imports from './pages/Imports/All';
import ImportShow from './pages/Imports/Show';
import Vendors from './pages/Vendors';

// Component Definition
const Router: FC = () => {
  const dispatch = useDispatch();

  // Get the current device width to store in the Redux state tree
  const { innerWidth } = useWindowSize();

  const previousInnerWidth = usePrevious(innerWidth);

  useLayoutEffect(() => {
    if (previousInnerWidth !== innerWidth) {
      dispatch(updateDeviceInnerWidth(innerWidth));
    }
  }, [dispatch, innerWidth, previousInnerWidth]);

  return (
    <Routes>
      <Route
        element={<BaseLayout hideNavigation />}
        path="/"
      >
        <>
          <Route
            element={<LoginPage />}
            path="/"
          />
          <Route
            element={<LoginPage />}
            path="/login"
          />
        </>
      </Route>

      <Route
        element={<BaseLayout />}
        path="/"
      >
        <>
          {/* Private routes */}
          <Route
            element={<Auth component={LogoutPage} />}
            path="/logout"
          />
          <Route
            element={<Auth component={DashboardPage} />}
            path="/dashboard"
          />

          {/* Districts */}
          <Route
            path="/districts"
          >
            <Route
              element={<Auth component={DistrictsPage} />}
              path="/districts"
            />
            <Route
              element={<Auth component={DistrictShow} />}
              path="/districts/:id"
            />
            <Route
              element={<Auth component={DistrictsNew} />}
              path="/districts/new"
            />
            <Route
              element={<Auth component={DistrictLicenses} />}
              path="/districts/:districtId/licenses"
            />
            <Route
              element={<Auth component={DistrictLicensesNew} />}
              path="/districts/:districtId/licenses/new"
            />
            <Route
              element={<Auth component={TaxExemptFormNew} />}
              path="/districts/:districtId/tax-exempt-form/new"
            />
          </Route>

          {/* Organizations */}
          <Route
            path="/organizations"
          >
            <Route
              element={<Auth component={OrganizationsPage} />}
              path="/organizations"
            />
            <Route
              element={<Auth component={OrganizationShow} />}
              path="/organizations:id"
            />
          </Route>

          <Route path="/imports">
            <Route
              element={<Auth component={Imports} />}
              path="/imports"
            />

            <Route
              element={<Auth component={ImportShow} />}
              path="/imports/:id"
            />
          </Route>

          <Route
            element={<Auth component={ScheduledCronJobsPage} />}
            path="/scheduled_cron_jobs"
          />

          <Route
            element={<Auth component={ApiPlayground} />}
            path="/api_playground"
          />

          {/* Server Settings */}
          <Route
            path="/server_settings"
          >
            <Route
              element={<Auth component={ServerSettingsPage} />}
              path="/server_settings"
            />
            <Route
              element={<Auth component={FeatureFlagsPage} />}
              path="/server_settingsfeature_flags"
            />
          </Route>

          {/* Feedback */}
          <Route
            path="/feedback"
          >
            <Route
              element={<Auth component={FeedbackPage} />}
              path="/feedback"
            />
            <Route
              element={<Auth component={FeedbackShowPage} />}
              path="/feedback:id"
            />
          </Route>

          {/* Admin Actions */}
          <Route
            path="/admin_actions"
          >
            <Route
              element={<Auth component={AdminActions} />}
              path="/admin_actions"
            />
            <Route
              element={<Auth component={SendEmail} />}
              path="/admin_actions/send_email"
            />
            <Route
              element={<Auth component={StripeWebhookEvents} />}
              path="/admin_actions/stripe_webhook_events"
            />
            <Route
              element={<Auth component={StripeWebhookEvent} />}
              path="/admin_actions/stripe_webhook_events/:id"
            />
            <Route
              element={<Auth component={UpdateAppSettings} />}
              path="/admin_actions/update_app_settings"
            />
            <Route
              element={<Auth component={FeeCalculator} />}
              path="/admin_actions/fee_calculator"
            />
          </Route>

          <Route
            element={<Auth component={Search} />}
            path="/search"
          />

          <Route path="/vendors">
            <Route
              element={<Auth component={Vendors} />}
              path="/vendors"
            />
            <Route
              element={<Auth component={InventoryVendorsPage} />}
              path="/vendors/inventory_vendors"
            />
          </Route>
        </>
      </Route>
    </Routes>
  );
};

export default Router;
