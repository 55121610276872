// External Dependencies
import { useCallback, useState } from 'react';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import WarningIcon from '@mui/icons-material/Warning';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import axios from 'axios';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import EnhancedCard from 'components/shared/EnhancedCard';
import { EnhancedAlert } from 'components/shared';
import { sendRequest } from 'utils/api';
import { IS_PRODUCTION } from 'utils/constants';

// Local Dependencies
import CodeBlock from './CodeBlock';
import ApiForm, { ApiFormValues } from './ApiForm';
import LoginFormDialog from './LoginFormDialog';
import ApiRequestDetails, { ApiRequestDetailsProps } from './ApiRequestDetails';

// Local Typings
interface ActingAsUser {
  email: string;
  token: string;
}
interface LoginValues {
  email: string;
  password: string;
}

// Component Definition
const ApiPlayground = (): JSX.Element => {
  const [actingAsUser, setActingAsUser] = useState<ActingAsUser | null>(null);
  const [fetchError, setFetchError] = useState<string | null>(null);
  const [apiRequestDetails, setApiRequestDetails] = useState<ApiRequestDetailsProps | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoginFormOpen, setIsLoginFormOpen] = useState(false);

  const handleClickActingAsUserButton = useCallback(() => {
    setIsLoginFormOpen(true);
  }, []);

  const handleLoginSubmit = useCallback(async (values: LoginValues) => {
    setIsSubmitting(true);
    setFetchError(null);

    try {
      const baseUrl = process.env.REACT_APP_API_URL ?? '';
      const url = `${baseUrl}/auth/login`;

      const res = await axios.post(url, {
        email: values.email,
        password: values.password,
      });

      const token = res.headers['x-access-token'] as string;

      if (token) {
        setActingAsUser({
          email: values.email,
          token,
        });
      }
      setIsLoginFormOpen(false);
    } catch (error) {
      setFetchError((error as unknown as Error).message);
    } finally {
      setIsSubmitting(false);
    }
  }, []);

  const handleFormikSubmit = useCallback(async (values: ApiFormValues) => {
    setIsSubmitting(true);
    setFetchError(null);
    setApiRequestDetails(null);

    try {
      const res = await sendRequest({
        data: values.body ? JSON.parse(values.body) : null,
        endpoint: values.path,
        method: values.method,
        tokenOverride: actingAsUser?.token,
      });

      setApiRequestDetails({
        ...values,
        response: res.data as object,
        status: res.status,
      });
    } catch (error) {
      setFetchError((error as unknown as Error).message);
    } finally {
      setIsSubmitting(false);
    }
  }, [actingAsUser]);

  return (
    <Container maxWidth="lg">
      <Typography
        component="h2"
        variant="h4"
      >
        API Playground
      </Typography>

      <EnhancedAlert
        icon={<WarningIcon />}
        severity="warning"
        sx={{
          marginY: 2,
        }}
      >
        This page is for testing API requests. Use with caution. Real requests will be made.
      </EnhancedAlert>

      {!IS_PRODUCTION && (
        <EnhancedAlert
          action={actingAsUser ? (
            <Button
              onClick={() => setActingAsUser(null)}
            >
              Logout
            </Button>
          ) : (
            <Button
              color="primary"
              onClick={handleClickActingAsUserButton}
            >
              External user login
            </Button>
          )}
          icon={<LockIcon />}
          severity="info"
          sx={{
            marginBottom: 2,
          }}
        >
          {actingAsUser ? (
            <>
              Acting as user: <strong>{actingAsUser.email}</strong>
            </>
          ) : (
            'Acting as Presto owner'
          )}
        </EnhancedAlert>
      )}

      <LoginFormDialog
        isOpen={isLoginFormOpen}
        isSubmitting={isSubmitting}
        onClose={() => setIsLoginFormOpen(false)}
        onSubmit={handleLoginSubmit}
      />

      <ApiForm
        isSubmitting={isSubmitting}
        onSubmit={handleFormikSubmit}
      />

      {apiRequestDetails && (
        <EnhancedCard sx={{ marginTop: 2 }}>
          <CardContent>
            <ApiRequestDetails apiRequestDetails={apiRequestDetails} />
          </CardContent>
        </EnhancedCard>
      )}

      {fetchError && (
        <EnhancedCard sx={{ marginTop: 2 }}>
          <CardContent>
            <CodeBlock
              blockTitle="Error"
              contents={{ error: fetchError }}
              noMarginTop
            />
          </CardContent>
        </EnhancedCard>
      )}
    </Container>
  );
};

export default ApiPlayground;
