// External Dependencies
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

// Internal Dependencies
import { parseSearch } from 'utils';

// Hook Definition
export const useParsedSearch = (params?: string) => {
  const { search } = useLocation();
  return useMemo(() => parseSearch(params ?? search), [params, search]);
};
