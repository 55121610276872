// External Dependencies
import { FC } from 'react';
import {
  Typography,
} from '@mui/material';

// Local Typings
interface Props {
  condition: boolean;
  message: string;
}

// Component Definition
const ErrorMessage: FC<Props> = ({
  condition = false,
  message = '',
}) => (
  condition
    ? (
      <Typography color="error">
        {message}
      </Typography>
    )
    : null
);

export default ErrorMessage;
