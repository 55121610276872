// External Dependencies
import Typography from '@mui/material/Typography';
import { ExtendedMember } from '@presto-assistant/api_types/api/admin/members';

// Internal Dependencies
import {
  Address,
  EnhancedCardContent,
  ShowPageDataDisplay,
} from 'components/shared';

// Local Typings
interface MemberWithStateLabel extends Partial<ExtendedMember> {
  stateLabel?: string;
}

interface Props {
  member?: MemberWithStateLabel;
}

// Component Definition
const MemberProfileSection = ({ member }: Props): JSX.Element | null => {
  return (
    <EnhancedCardContent>
      <Typography
        className="subheader"
        gutterBottom
      >
        Member Profile Info
      </Typography>

      {member?.title && (
        <ShowPageDataDisplay
          label="Title"
          value={member?.title}
        />
      )}

      {member?.deletedAt && (
        <>
          <ShowPageDataDisplay
            label="Deleted At"
            type="datetime"
            value={member.deletedAt as unknown as string}
          />
          <ShowPageDataDisplay
            label="Deleted Note"
            value={member.deletedNote}
          />
        </>
      )}

      <ShowPageDataDisplay
        label="Current organization"
        type="boolean"
        value={member?.isCurrentOrganization}
      />

      <ShowPageDataDisplay
        label="Is Approved"
        type="boolean"
        value={member?.isApproved}
      />

      {member?.claimCode && (
        <ShowPageDataDisplay
          label="Claim Code"
          value={member.claimCode}
        />
      )}

      <ShowPageDataDisplay
        label="Date of Birth"
        type="date"
        value={member?.dateOfBirth as unknown as string}
      />
      <ShowPageDataDisplay
        label="Created At"
        type="datetime"
        value={member?.createdAt as unknown as string}
      />
      <ShowPageDataDisplay
        label="Updated At"
        type="datetime"
        value={member?.updatedAt as unknown as string}
      />

      <Typography
        className="member-search-result-address-subheader"
        color="textSecondary"
        gutterBottom
        variant="body2"
      >
        Address
      </Typography>

      <Address
        addressOne={member?.addressOne}
        addressTwo={member?.addressTwo}
        city={member?.city}
        className="member-search-result-address"
        phoneNumber={member?.phoneNumber}
        state={member?.stateLabel}
        zipcode={member?.zipcode}
      />
    </EnhancedCardContent>
  );
};

export default MemberProfileSection;
