// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { Subtitle } from 'components/shared';

// Local Dependencies
import FeedbackTable from './FeedbackTable';

// Component Definition
const Feedback: FC = () => {
  return (
    <>
      <Subtitle>
        User Feedback
      </Subtitle>

      <FeedbackTable />
    </>
  );
};

export default Feedback;
