// External Dependencies
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// Internal Dependencies
import { ShowPageDataDisplay } from 'components/shared';

// Local Typings
interface Props {
  organization: Organization;
}

interface Organization extends GQL.IOrganization {
  stripeId?: string | undefined;
}

// Component Definition
const OrganizationDistrictContent = ({ organization }: Props): JSX.Element => {
  const navigate = useNavigate();

  const navigateToDistrictShowPage = useCallback(() => {
    navigate(`/districts/${organization?.district?.id}`);
  }, [navigate, organization?.district?.id]);

  return (
    <Box
      component="section"
      paddingLeft={{
        sm: 7,
        xs: 3,
      }}
    >
      <Typography
        component="h3"
        variant="h6"
      >
        District Info
      </Typography>

      <Box
        alignItems="center"
        display="flex"
        flexWrap="wrap"
      >
        <ShowPageDataDisplay
          label="District"
          value={(
            <Box
              alignItems="center"
              display="flex"
            >
              <Typography>
                {organization?.district?.label}
              </Typography>

              {organization?.district && (
                <Button
                  color="primary"
                  endIcon={<ArrowForwardIcon />}
                  onClick={navigateToDistrictShowPage}
                  size="small"
                  sx={{ marginLeft: 4 }}
                  variant="outlined"
                >
                  View District
                </Button>
              )}
            </Box>
          )}
        />
      </Box>

      <Divider sx={{ marginY: 2 }} />
    </Box>
  );
};

export default OrganizationDistrictContent;
