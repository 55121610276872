// External Dependencies
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import DownloadIcon from '@mui/icons-material/CloudDownload';

// Local Typings
interface Props {
  feedback: GQL.IFeedback;
}

// Component Definition
const FeedbackAttachmentsCard: FC<Props> = ({ feedback }) => (
  <Paper variant="outlined">
    {feedback.attachments.length
      ? (
        <List>
          {feedback.attachments.map((attachment) => (
            <ListItem key={attachment.id}>
              <ListItemText
                primary={attachment.filename}
              />

              <ListItemSecondaryAction>
                <IconButton
                  download
                  href={attachment.url}
                  size="large"
                >
                  <DownloadIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )
      : (
        <Box padding={2}>
          <Typography>No attachments</Typography>
        </Box>
      )}
  </Paper>
);

export default FeedbackAttachmentsCard;
