// External Dependencies
import { FC, useCallback, useMemo } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { AxiosError } from 'axios';

// Internal Dependencies
import useTextField from 'hooks/useTextField';
import { EnhancedAlert, SaveButton } from 'components/shared';
import { useDeleteDistrict } from 'hooks/api/district';
import { convertAxiosErrorToYupError } from 'utils/convertAxiosErrorToYupError';

// Local Typings
interface Props {
  districtId: string;
  isOpen: boolean;
  onClose: () => void;
}

// Component Definition
const DeleteOrganizationDialog: FC<Props> = ({
  districtId,
  isOpen,
  onClose,
}) => {
  const noteField = useTextField();

  const {
    error,
    isLoading: isDeleting,
    mutate: deleteDistrict,
  } = useDeleteDistrict();

  const handleClickConfirm = useCallback(() => {
    deleteDistrict({
      id: districtId,
      payload: {
        note: noteField.value,
      },
    });
  }, [deleteDistrict, districtId, noteField.value]);

  const yupErrors = useMemo(() => {
    if (!error) {
      return null;
    }

    const yupError = convertAxiosErrorToYupError(error as AxiosError);

    if (yupError) {
      return Object.values(yupError);
    }

    return null;
  }, [error]);

  return (
    <Dialog open={isOpen}>
      <DialogTitle>Delete Distrct?</DialogTitle>

      <DialogContent>
        <DialogContentText gutterBottom>
          Are you sure you want to delete this district?
        </DialogContentText>

        <Box marginY={2}>
          <TextField
            {...noteField}
            fullWidth
            label="Note"
            minRows={4}
            multiline
          />
        </Box>

        {yupErrors?.map((errorMessage) => (
          <EnhancedAlert
            key={errorMessage as string}
            severity="error"
            sx={{ mt: 2 }}
          >
            {errorMessage}
          </EnhancedAlert>
        ))}
      </DialogContent>

      <DialogActions>
        <Button
          disabled={isDeleting}
          onClick={onClose}
        >
          Cancel
        </Button>

        <SaveButton
          isSaving={isDeleting}
          onClick={handleClickConfirm}
          variant="contained"
        >
          Yes, delete
        </SaveButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteOrganizationDialog;
