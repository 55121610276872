// External Dependencies
import { useEffect, useMemo, useState } from 'react';

// Internal Dependencies
import { getStates } from 'utils/api';

// Hook Definition
export const useGetStateOptions = () => {
  const [
    stateOptions,
    setStateOptions,
  ] = useState<DB.State[]>([]);

  useEffect(() => {
    const getStateOptions = async () => {
      const response = await getStates();

      const { data } = response;

      if (data?.states) {
        setStateOptions(data.states);
      }
    };

    getStateOptions();
  }, []);

  return useMemo(() => ({
    options: stateOptions,
  }), [stateOptions]);
};
