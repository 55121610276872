import gql from 'graphql-tag';
import { useMutationEnhanced } from 'hooks';
import { FEEDBACK_SHOW_FRAGMENT } from 'gql/queries/feedback';

// Local Typings
interface UpdateResponse {
  updateFeedback: GQL.IFeedback;
}

interface UpdateVariables {
  id: string;
  input: {
    statusId: number;
  }
}

// Local Variables
const UPDATE_FEEDBACK = gql`
  mutation UpdateFeedback(
    $id: ID!
    $input: UpdateFeedbackInput!
  ) {
    updateFeedback(id: $id input: $input) {
      ...FeedbackShowFragment
    }
  }
  ${FEEDBACK_SHOW_FRAGMENT}
`;

export const useUpdateFeedback = () =>
  useMutationEnhanced<UpdateResponse, UpdateVariables>(
    UPDATE_FEEDBACK,
    {
      clearCachePredicates: ['feedbackIndex'],
    },
  );
