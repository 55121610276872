// External Dependencies
import { CronJobsIndexResponseItem } from '@presto-assistant/api_types/api/admin/cronJobIndex';

// Local Dependencies
import { getRequest } from '.';

// Local Typings
export interface CronJobsResponse {
  jobs: CronJobsIndexResponseItem[];
}

export const getCronJobs = () =>
  getRequest<CronJobsResponse>({ endpoint: '/admin/cron_jobs' });
