// External Dependencies
import { useMutation, useQueryClient } from '@tanstack/react-query';

// Internal Dependencies
import { prestoAdminApiClient } from 'utils/api/apiClient';

export const useMarkEmailVerified = (authUserId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () =>
      prestoAdminApiClient.v1.authUsers.markEmailVerified({ params: { id: authUserId } }),
    onSuccess: () => {
      queryClient.invalidateQueries(['searchMemberByEmail']);
    },
  });
};
