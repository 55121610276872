import gql from 'graphql-tag';
import { useMutationEnhanced } from 'hooks';

interface CreateSendGridEmailResponse {
  createSendGridEmail: boolean;
}

interface CreateSendGridEmailVariables {
  input: GQL.ICreateSendGridEmailInput;
}

const CREATE_SEND_GRID_EMAIL = gql`
  mutation CreateSendGridEmail(
    $input: CreateSendGridEmailInput!
  ) {
    createSendGridEmail(input: $input)
}
`;

export const useCreateSendGridEmail = (args: {
  onCompleted: () => void;
  onError: (error: any) => void;
}) =>
  useMutationEnhanced<
    CreateSendGridEmailResponse,
    CreateSendGridEmailVariables
  >(
    CREATE_SEND_GRID_EMAIL,
    {
      onCompleted: args.onCompleted,
      onError: args.onError,
    },
  );
