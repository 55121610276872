// Internal Dependencies
import { Flex, Subtitle } from 'components/shared';

// Local Dependencies
import MemberSearch from './MemberSearch';

// Component Definition
const Search = (): JSX.Element => {
  return (
    <>
      <Subtitle>
        Search
      </Subtitle>

      <Flex gap={4}>
        <MemberSearch />
      </Flex>
    </>
  );
};

export default Search;
