// External Dependencies
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import FlagIcon from '@mui/icons-material/Flag';

// Internal Dependencies
import {
  EnhancedCard,
  Subtitle,
} from 'components/shared';
import { useIsPrestoOwner } from 'hooks/useIsPrestoOwner';
import RestrictedContentCard from 'components/shared/RestrictedContentCard';

// Component Definition
const ServerSettingsPage = (): JSX.Element => {
  const navigate = useNavigate();

  const navigateToFeatureFlags = useCallback(() => {
    navigate('/server_settings/feature_flags');
  }, [navigate]);

  const isPrestoOwner = useIsPrestoOwner();

  if (!isPrestoOwner) {
    return <RestrictedContentCard />;
  }

  return (
    <>
      <Subtitle>
        Server Settings
      </Subtitle>

      <EnhancedCard sx={{ marginTop: 2 }}>
        <List>
          <ListItem
            button
            onClick={navigateToFeatureFlags}
          >
            <ListItemIcon>
              <FlagIcon />
            </ListItemIcon>
            <ListItemText primary="Feature Flags" />
          </ListItem>
        </List>
      </EnhancedCard>
    </>
  );
};

export default ServerSettingsPage;
