// External Dependencies
import { FC } from 'react';
import {
  Button,
  IconButton,
  ListItemSecondaryAction,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ApolloError } from '@apollo/client';

// Internal Dependencies
import { Flex } from 'components/shared';
import { FeatureFlagOverride } from 'gql/queries/featureFlags';
import { useDeleteOverride } from 'gql/mutations/featureFlags';

// Local Typings
interface Props {
  deleteWarnId: string | null;
  onError: (error: ApolloError | null) => void;
  override: FeatureFlagOverride;
  setDeleteWarnId: React.Dispatch<React.SetStateAction<string | null>>;
}

// Component Definition
const FlagListSecondaryAction: FC<Props> = ({
  deleteWarnId,
  onError,
  override,
  setDeleteWarnId,
}) => {
  const [deleteOverride] = useDeleteOverride({
    onError,
  });

  const handleClickDelete = (id: string) => () => {
    deleteOverride({ variables: { id } });
  };

  const handleClickDeleteWarn = (id: string) => () => {
    setDeleteWarnId(id);
  };

  const handleClickDeleteCancel = () => {
    setDeleteWarnId(null);
  };

  return (
    <ListItemSecondaryAction>
      {deleteWarnId === override.id ? (
        <Flex>
          <Typography>
            Are you sure?
          </Typography>

          <Button
            color="primary"
            onClick={handleClickDeleteCancel}
            sx={{ mx: 2 }}
            variant="outlined"
          >
            Cancel
          </Button>

          <Button
            color="primary"
            onClick={handleClickDelete(override.id)}
            variant="contained"
          >
            Delete
          </Button>
        </Flex>
      ) : (
        <IconButton
          aria-label="delete"
          edge="end"
          onClick={handleClickDeleteWarn(override.id)}
          size="large"
        >
          <DeleteIcon />
        </IconButton>
      )}
    </ListItemSecondaryAction>
  );
};

export default FlagListSecondaryAction;
