// External Dependencies
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Local Dependencies
import Flex from './Flex';

// Local Typings
interface Props {
  children: React.ReactNode;
  hasGutterTop?: boolean;
}

// Local Variables
const StyledTypography = styled(Typography)(({
  theme,
}) => ({
  marginBottom: theme.spacing(1.5),
  paddingLeft: theme.spacing(2),
})) as typeof Typography;

// Component Definition
const Subtitle = ({
  children,
  hasGutterTop,
}: Props): JSX.Element => {
  return (
    <Flex>
      <StyledTypography
        component="h2"
        sx={{ marginTop: hasGutterTop ? 3 : 'inherit' }}
        variant="subtitle1"
      >
        {children}
      </StyledTypography>
    </Flex>
  );
};

export default Subtitle;
