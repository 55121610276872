// External Dependencies
import { SvgIconProps } from '@mui/material/SvgIcon';
import { ReactElement, Ref, useMemo } from 'react';
import { lighten } from '@mui/material/styles';
import ListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import styled from 'styled-components';

// Local Dependencies
import EnhancedMenuItemLeftIcon from './EnhancedMenuItemLeftIcon';

// Local Typings
interface StyledListItemButtonProps {
  $isActive?: boolean;
  $isFullWidth?: boolean;
}
interface Props {
  contentRef?: Ref<HTMLDivElement>;
  icon?: (props: SvgIconProps) => ReactElement<SvgIconProps>;
  isActive?: StyledListItemButtonProps['$isActive'];
  isFullWidth?: StyledListItemButtonProps['$isFullWidth'];
  onClick?: () => void;
  secondaryText?: string | ReactElement;
  text?: string;
}

const StyledListItemButton = styled(ListItemButton)<
  StyledListItemButtonProps & ListItemButtonProps
>(({
  $isActive,
  $isFullWidth,
  theme,
}) => ({
  '& .MuiListItemText-colorTextSecondary': {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.7rem',
    },
  },
  '& .MuiListItemText-primary': {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.9rem',
    },
  },
  '& .MuiListItemText-secondary': {
    color: $isActive ? lighten(theme.palette.navMenuItemText, 0.15) : 'initial',
  },

  // background: 'inherit',
  borderRadius: theme.shape.borderRadius,
  color: $isActive
    ? theme.palette.prestoPrimaryDark
    : theme.palette.text.primary,
  margin: theme.spacing(1),
  padding: theme.spacing(0.5, 1.5, 0.5, 1),
  width: $isFullWidth ? '100%' : undefined,
})) as typeof ListItemButton;

// Component Definition
const EnhancedMenuItem = ({
  contentRef,
  icon,
  isActive,
  isFullWidth = false,
  onClick,
  secondaryText = '',
  text,
  ...otherProps
}: Props): JSX.Element => {
  const styledListButtonItemProps: StyledListItemButtonProps = useMemo(() => ({
    $isActive: isActive,
    $isFullWidth: isFullWidth,
  }), [isActive, isFullWidth]);

  return (
    <StyledListItemButton
      {...styledListButtonItemProps}
      onClick={onClick}
      ref={contentRef}
      role="menuitem"
      selected={isActive}
      tabIndex={0}
      {...otherProps}
    >
      {icon && (
        <EnhancedMenuItemLeftIcon
          icon={icon}
          isActive={isActive}
        />
      )}
      <ListItemText
        primary={text}
        secondary={secondaryText}
        secondaryTypographyProps={{
          component: 'div',
        }}
      />
    </StyledListItemButton>
  );
};

export default EnhancedMenuItem as any;
