// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { BackToButton, Subtitle } from 'components/shared';

// Local Dependencies
import StripeWebhookEventsTable from './StripeWebhookEventsTable';

// Component Definition
const StripeWebhookEvents: FC = () => (
  <>
    <BackToButton
      context="Admin Actions"
      navigateTo="/admin_actions"
    />

    <Subtitle hasGutterTop>
      Stripe Events
    </Subtitle>

    <StripeWebhookEventsTable />
  </>
);

export default StripeWebhookEvents;
