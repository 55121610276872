// External Dependencies
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

// Internal Dependencies
import { BackToButton, Subtitle } from 'components/shared';
import { tableQueryParams } from 'state/table/selectors';
import { useGetFeedback } from 'gql/queries/feedback';

// Local Dependencies
import SubmissionDetailsCard from './SubmissionDetailsCard';
import FeedbackResponseCard from './FeedbackResponseCard';
import FeedbackAttachmentsCard from './FeedbackAttachmentsCard';
import FeedbackActions from './FeedbackActions';

// Component Definition
const FeedbackShow: FC = () => {
  const { id } = useParams();
  const { data } = useGetFeedback(id);

  const feedbackParams = useSelector(tableQueryParams('feedback'));

  return (
    <>
      <Box mb={2}>
        <BackToButton
          context="Feedback"
          navigateTo={`/feedback${feedbackParams}`}
        />
      </Box>

      {data?.feedback && (
        <>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
          >
            <Subtitle>Submission Details</Subtitle>

            <Chip
              color="primary"
              label={data.feedback.status.label}
            />
          </Box>

          <SubmissionDetailsCard feedback={data.feedback} />

          <Box mt={2}>
            <Subtitle>Feedback</Subtitle>
          </Box>

          <FeedbackResponseCard feedback={data.feedback} />

          <Box mt={3}>
            <Subtitle>Attachments</Subtitle>
          </Box>

          <FeedbackAttachmentsCard feedback={data.feedback} />

          <FeedbackActions feedback={data.feedback} />
        </>
      )}
    </>
  );
};

export default FeedbackShow;
