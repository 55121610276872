// External Dependencies
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { Subtitle } from 'components/shared';
import { useGetMemberByEmail } from 'hooks/api/members';

// Local Dependencies
import AuthUserSearchResultCard from './AuthUserSearchResultCard';
import MemberSearchResultCard from './MemberSearchResultCard';

// Local Typings
interface Props {
  searchedEmail: string;
  setIsLoadingData: (isLoading: boolean) => void;
  stateOptions: DB.State[];
}

// Component Definition
const MemberSearchResults = ({
  searchedEmail,
  setIsLoadingData,
  stateOptions,
}: Props): JSX.Element => {
  const {
    data: membersByEmailResponse,
    isLoading,
  } = useGetMemberByEmail({ email: searchedEmail });

  useEffect(() => {
    setIsLoadingData(isLoading);
  }, [isLoading, setIsLoadingData]);

  const authUsersData = membersByEmailResponse?.data.data?.authUsers;
  const membersData = membersByEmailResponse?.data.data?.members;

  const hasAuthUsersData = Boolean(authUsersData?.length);
  const hasMembersData = Boolean(membersData?.length);

  const membersDataWithState = membersData?.map((member) => ({
    ...member,
    stateLabel: stateOptions?.find((state) => member.stateId === state.id)?.label,
  }));

  return (
    <>
      {/* Empty state for auth user */}
      <Collapse in={Boolean(searchedEmail && !hasAuthUsersData)}>
        <Box marginY={2}>
          <Subtitle>No login information found</Subtitle>
        </Box>
      </Collapse>

      {/* Render auth user */}
      {searchedEmail && hasAuthUsersData && authUsersData && (
        <AuthUserSearchResultCard authUser={authUsersData?.[0]} />
      )}

      {/* Empty state for members */}
      <Collapse in={Boolean(searchedEmail && !hasMembersData)}>
        <Box marginY={2}>
          <Subtitle>No member information found</Subtitle>
        </Box>
      </Collapse>

      {/* Render all members */}
      <Collapse in={Boolean(searchedEmail && hasMembersData)}>
        <Box sx={{ marginLeft: 2, marginY: 3 }}>
          <Typography
            component="h3"
            variant="h5"
          >
            Member Data
          </Typography>
        </Box>

        {membersDataWithState && hasMembersData && membersDataWithState.map((member) => (
          <MemberSearchResultCard
            key={member.id}
            member={member}
          />
        ))}

        {!hasMembersData && (
          <Typography paragraph>
            No member found with the email address provided.
          </Typography>
        )}
      </Collapse>
    </>
  );
};

export default MemberSearchResults;
