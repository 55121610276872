// External Dependencies
import { FC } from 'react';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import {
  ConfirmationDialog,
  EnhancedAlert,
} from 'components/shared';
import { useVerifyDistrict } from 'gql/mutations/districts';

// Local Typings
interface Props {
  districtId: string;
  onClose: () => void;
  open: boolean;
}

// Component Definition
const VerifyDistrictDialog: FC<Props> = ({
  districtId,
  onClose,
  open,
}) => {
  const [
    verifyDistrict,
    {
      error,
      loading,
    },
  ] = useVerifyDistrict({
    onCompleted: onClose,
  });

  const handleVerifyDistrict = () => {
    verifyDistrict({
      variables: {
        input: {
          id: districtId,
          isVerified: true,
        },
      },
    });
  };

  return (
    <ConfirmationDialog
      confirmButtonAction={handleVerifyDistrict}
      confirmButtonText="Yes, Verify"
      description={(
        <>
          <Typography gutterBottom>
            You have verified that this district is legitimate.
          </Typography>

          <Typography>
            Once verified, this district will be able to use restricted features.
          </Typography>

          <Collapse in={Boolean(error)}>
            <EnhancedAlert
              severity="error"
              sx={{ marginTop: 2 }}
            >
              {error?.message}
            </EnhancedAlert>
          </Collapse>
        </>
      )}
      isSubmitting={loading}
      maxWidth="xs"
      onClose={onClose}
      open={open}
      title="Verify district?"
    />
  );
};

export default VerifyDistrictDialog;
