// External Dependencies
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';

// Internal Dependencies
import { BackToButton } from 'components/shared';
import { useGetOrganization } from 'gql/queries/organization';
import { useIsPrestoOwner } from 'hooks/useIsPrestoOwner';

// Local Dependencies
import DirectorList from './DirectorList';
import EditOrgSubscription from './EditOrgSubscription';
import OrganizationDangerZone from './OrganizationDangerZone';
import OrganizationInfo from './OrganizationInfo';

// Component Definition
const OrganizationShow = (): JSX.Element => {
  const { id } = useParams();

  const { data } = useGetOrganization(id);

  const isPrestoOwner = useIsPrestoOwner();

  return (
    <>
      <Box mb={2}>
        <BackToButton
          context="All Organizations"
          navigateTo="/organizations"
        />
      </Box>

      {data?.organization && (
        <>
          <OrganizationInfo organization={data.organization} />

          <DirectorList directors={data.organization.directors} />

          <EditOrgSubscription
            key={data.organization.id}
            organization={data.organization}
          />

          {isPrestoOwner && (
            <OrganizationDangerZone
              orgId={data.organization.id}
              orgName={data.organization.label}
            />
          )}
        </>
      )}
    </>
  );
};

export default OrganizationShow;
