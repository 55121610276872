// External Dependencies
import { FC } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';

// Internal Dependencies
import { isMobileScreenSize } from 'state/selectors';
import { useIsOpen } from 'hooks/useIsOpen';
import { DangerZone } from 'components/shared';

// Local Dependencies
import DeleteDistrictDialog from './DeleteDistrictDialog';

// Local Typings
interface Props {
  districtId: GQL.IDistrict['id'];
  districtName: GQL.IDistrict['label'];
}

// Component Definition
const DistrictDangerZone: FC<Props> = ({ districtId, districtName }) => {
  const isMobileScreen = useSelector(isMobileScreenSize);

  const {
    isOpen: isDeleteDialogOpen,
    toggleIsOpen: toggleIsDeleteDialogOpen,
  } = useIsOpen();

  const dangerZoneItems = [
    {
      buttonText: isMobileScreen ? 'Start' : 'Start Deletion',
      description: `Mark the ${districtName} district as deleted.`,
      onClick: toggleIsDeleteDialogOpen,
      title: 'Delete this district',
    },
  ];

  return (
    <Box marginTop={2}>
      <DangerZone
        items={dangerZoneItems}
      />

      <DeleteDistrictDialog
        districtId={districtId}
        isOpen={isDeleteDialogOpen}
        onClose={toggleIsDeleteDialogOpen}
      />
    </Box>
  );
};

export default DistrictDangerZone;
