// External Dependencies
import gql from 'graphql-tag';
import { useQueryEnhanced } from 'hooks';

// Local Typings
interface FeedbackIndexResponse {
  feedbackIndex: GQL.IFeedbackIndex;
}
interface FeedbackDetailsResponse {
  feedback: GQL.IFeedback;
}

export const GET_FEEDBACK_INDEX = gql`
  query FeedbackIndex {
    feedbackIndex {
      data {
        body
        district {
          id
          label
        }
        id
        organization {
          id
          label
        }
        status {
          id
          label
        }
        subject
        submittedAt
        submittedBy {
          id
          email
          firstName
          lastName
        }
      }
      fullCount
    }
  }
`;

export const FEEDBACK_SHOW_FRAGMENT = gql`
  fragment FeedbackShowFragment on Feedback {
    attachments {
      filename
      id
      url
    }
    body
    district {
      id
      label
    }
    id
    organization {
      id
      label
    }
    status {
      id
      label
    }
    subject
    submittedAt
    submittedBy {
      id
      email
      firstName
      lastName
    }
  }
`;

export const GET_FEEDBACK = gql`
  query Feedback($id: ID!) {
    feedback(id: $id) {
      ...FeedbackShowFragment
    }
  }
  ${FEEDBACK_SHOW_FRAGMENT}
`;

export const useGetFeedbackIndexItems = () =>
  useQueryEnhanced<FeedbackIndexResponse>(
    GET_FEEDBACK_INDEX,
  );

export const useGetFeedback = (id?: string) =>
  useQueryEnhanced<FeedbackDetailsResponse>(
    GET_FEEDBACK,
    {
      skip: !id,
      variables: { id },
    },
  );
