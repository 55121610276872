// External Dependencies
import { AxiosResponse } from 'axios';
import { useState } from 'react';

// Local Typings
interface UseFetcherArgs<Response> {
  apiRequest: () => Promise<AxiosResponse<Response>>;
  onError?: (error: Error) => void;
  onSuccess?: (response: Response) => void;
}

interface UseFetcherReturn<Response> {
  data: Response | null;
  error: Error | null;
  fetchData: () => void;
  loading: boolean;
}

export function useFetcher<T>(args: UseFetcherArgs<T>): UseFetcherReturn<T> {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);

  async function fetchData() {
    setLoading(true);

    try {
      const response = await args.apiRequest();

      setData(response.data);

      if (args.onSuccess) {
        args.onSuccess(response.data);
      }
    } catch (err: any) {
      setError(err);

      if (args.onError) {
        args.onError(err);
      }
    } finally {
      setLoading(false);
    }
  }

  return {
    data,
    error,
    fetchData,
    loading,
  };
}
