// External Dependencies
import { FC, useCallback, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers';

// Internal Dependencies
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import TableDataGrid from 'components/shared/TableDataGrid';
import { useGetStripeWebhookEvents } from 'gql/queries/stripeWebhookEvents';

// Local Dependencies
import { Box, TextField } from '@mui/material';
import moment, { Moment } from 'moment';
import { useColumns } from './hooks';

// Local Variables
const handleRowClick = (id: string) => `/admin_actions/stripe_webhook_events/${id}`;

// Component Definition
const StripeWebhookEventsTable: FC = () => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  const { data, loading } = useGetStripeWebhookEvents({
    month: selectedDate.getMonth(),
    year: selectedDate.getFullYear(),
  });

  const columns = useColumns();

  const handleSetDate = useCallback((dateValue: Moment | null) => {
    setSelectedDate(dateValue?.toDate() ?? new Date());
  }, []);

  return (
    <>
      <Box marginBottom={2}>
        <DatePicker
          label="Month"
          maxDate={moment().endOf('year')}
          minDate={moment('2020-01-01')}
          onChange={handleSetDate}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
            />
          )}
          value={selectedDate}
          views={['year', 'month']}
        />
      </Box>

      <DataGridContainer>
        <TableDataGrid
          clickRowTo={handleRowClick}
          columns={columns}
          loading={loading}
          rows={data?.stripeWebhookEvents ?? []}
          tableResource="stripeWebhookEvents"
          withSearch
        />
      </DataGridContainer>
    </>
  );
};

export default StripeWebhookEventsTable;
