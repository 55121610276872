// External Dependencies
import { createRef, Component } from 'react';
import { FastField } from 'formik';
import {
  FormControl, InputLabel, MenuItem, OutlinedInput, Select,
} from '@mui/material';

import withStyles from '@mui/styles/withStyles';

// Local Variables
const styles = {
  input: {
    width: '100%',
  },
};

// Local Functions
const getError = ({ errors, nameScope }) => {
  let error = nameScope[0] ? errors[nameScope[0]] : undefined;
  nameScope.forEach((name, index) => {
    if (index > 0) {
      error = error && error[name];
    }
  });
  return error;
};

const getTouched = ({ nameScope, touched }) => {
  let touch = nameScope[0] ? touched[nameScope[0]] : undefined;
  nameScope.forEach((name, index) => {
    if (index > 0) {
      touch = touch && touch[name];
    }
  });
  return touch;
};

// Component Definition
class CustomSelectComponent extends Component {
  constructor() {
    super();

    this.state = {
      labelWidth: 0,
    };

    this.inputLabelRef = createRef();
  }

  componentDidMount() {
    this.setState({
      labelWidth: this.inputLabelRef.current.offsetWidth,
    });
  }

  render() {
    const { labelWidth } = this.state;
    const {
      field, // { name, value, onChange, onBlur }
      form: { errors, touched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      label,
      ...props
    } = this.props;

    const nameScope = field.name.split('.');
    const error = getError({ errors, nameScope });
    const touch = getTouched({ nameScope, touched });
    return (
      <FormControl
        style={{ textAlign: 'left', width: '100%' }}
        variant="outlined"
      >
        <InputLabel
          htmlFor={field.name}
          ref={this.inputLabelRef}
        >
          {label}
        </InputLabel>
        <Select
          error={!!touch && !!error}
          input={(
            <OutlinedInput
              id={field.name}
              labelWidth={labelWidth}
              name={label}
            />
          )}
          type="text"
          variant="filled"
          {...field}
          {...props}
        />
      </FormControl>
    );
  }
}

const CustomSelect = ({
  classes, label, options, required, ...props
}) => (
  <FastField
    className={classes.input}
    component={CustomSelectComponent}
    label={label}
    {...props}
  >
    {!required && <MenuItem value="" />}
    {options.length && options.map(({ id, label: optionLabel }) => (
      <MenuItem
        key={id}
        value={id}
      >
        {optionLabel}
      </MenuItem>
    ))}
  </FastField>
);

export default withStyles(styles)(CustomSelect);
