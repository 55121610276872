// External Dependencies
import { FC } from 'react';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import {
  ConfirmationDialog,
} from 'components/shared';
import { APP_NAME } from 'utils/constants';

// Local Typings
interface Props {
  districtName?: string;
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
}

// Component Definition
const ConfirmUpdateDistrictTaxExemptFormDialog: FC<Props> = ({
  districtName,
  onClose,
  onConfirm,
  open,
}) => {
  const districtNameFragment = districtName ? `The ${districtName}` : 'This ';

  return (
    <ConfirmationDialog
      confirmButtonAction={onConfirm}
      confirmButtonText="Yes, Continue"
      description={(
        <>
          <Typography paragraph>
            {districtNameFragment} district currently has a tax-exempt form on file with {APP_NAME}.
          </Typography>

          <Typography>
            Continue on to replace this district&apos;s current tax-exempt
            form with a new one that you will upload.
          </Typography>
        </>
      )}
      maxWidth="xs"
      onClose={onClose}
      open={open}
      title="Update Tax-Exempt Form?"
    />
  );
};

export default ConfirmUpdateDistrictTaxExemptFormDialog;
