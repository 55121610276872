// Internal Dependencies
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import TableDataGrid from 'components/shared/TableDataGrid';
import { useGetAllInventoryVendors } from 'hooks/api/inventoryVendors';

// Local Dependencies
import { useColumns } from './hooks';

// Local Variables
const handleRowClick = (id: string) => `/vendors/inventory_vendors/${id}`;

// Component Definition
const InventoryVendorsTable = (): JSX.Element => {
  const {
    data: inventoryVendorsResponse,
    isLoading,
  } = useGetAllInventoryVendors();

  const columns = useColumns();

  return (
    <DataGridContainer>
      <TableDataGrid
        clickRowTo={handleRowClick}
        columns={columns}
        loading={isLoading}
        rows={inventoryVendorsResponse?.data.data ?? []}
        tableResource="inventoryVendors"
        withSearch
      />
    </DataGridContainer>
  );
};

export default InventoryVendorsTable;
