import { FastField } from 'formik';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';

const getError = ({ errors, nameScope }) => {
  let error = nameScope[0] ? errors[nameScope[0]] : undefined;
  nameScope.forEach((name, index) => {
    if (index > 0) {
      error = error && error[name];
    }
  });
  return error;
};

const getTouched = ({ nameScope, touched }) => {
  let touch = nameScope[0] ? touched[nameScope[0]] : undefined;
  nameScope.forEach((name, index) => {
    if (index > 0) {
      touch = touch && touch[name];
    }
  });
  return touch;
};

const CustomCheckboxComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { errors, touched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  ...props
}) => {
  const nameScope = field.name.split('.');
  const error = getError({ errors, nameScope });
  const touch = getTouched({ nameScope, touched });
  return (
    <div>
      <FormControl
        component="fieldset"
        error={!!touch && !!error}
        required
      >
        <FormControlLabel
          control={(
            <Checkbox
              color="primary"
              {...field}
              {...props}
            />
          )}
          label={label}
        />
        <FormHelperText>
          {!!touch && !!error && error}
        </FormHelperText>
      </FormControl>
    </div>
  );
};

const CustomCheckbox = ({ ...props }) => (
  <FastField
    component={CustomCheckboxComponent}
    {...props}
    type="checkbox"
  />
);

export default CustomCheckbox;
