// External Dependencies
import { FC } from 'react';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

// Internal Dependencies
import SaveButton from 'components/shared/SaveButton';

// Local Typings
interface Props {
  confirmButtonAction: () => void;
  confirmButtonText?: string;
  description: React.ReactNode;
  isSubmitting?: boolean;
  maxWidth?: DialogProps['maxWidth'];
  onClose: () => void;
  open: DialogProps['open'];
  title: string;
}

// Component Definition
const ConfirmationDialog: FC<Props> = ({
  confirmButtonAction,
  confirmButtonText = 'OK',
  description,
  isSubmitting = false,
  maxWidth = 'sm',
  onClose,
  open,
  title,
}) => {
  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      fullWidth
      maxWidth={maxWidth}
      onClose={onClose}
      open={open}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>

      <DialogContent>
        <DialogContentText
          component="div"
          id="alert-dialog-description"
        >
          {description}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          onClick={onClose}
        >
          Cancel
        </Button>

        <SaveButton
          autoFocus
          color="primary"
          isSaving={isSubmitting}
          onClick={confirmButtonAction}
          variant="contained"
        >
          {confirmButtonText}
        </SaveButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
