// External Dependencies
import { useMemo } from 'react';

// Internal Dependencies
import { useGetDistricts } from 'gql/queries/districts';

export const useGetDistrictTotals = () => {
  const { data } = useGetDistricts();

  const totalDistrictValueInCents = useMemo(() => (data?.districts ?? []).reduce((prev, curr) => {
    const districtAnnualRevenueInCents = curr.licensePriceInCents * curr.numberOfActiveLicenses;

    return prev + districtAnnualRevenueInCents;
  }, 0), [data]);

  const totalActiveLicenses = useMemo(
    () =>
      (data?.districts ?? []).reduce((prev, curr) => prev + curr.numberOfActiveLicenses, 0),
    [data],
  );

  return useMemo(() => ({
    totalActiveLicenses,
    totalDistrictValueInCents,
  }), [
    totalActiveLicenses,
    totalDistrictValueInCents,
  ]);
};
