// External Dependencies
import { FC } from 'react';
import { Formik, Form } from 'formik';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

// Internal Dependencies
import {
  CustomFormButton,
  CustomInput,
  ErrorMessage,
} from './shared';
import {
  validateEmail,
  validatePasswordLength,
} from '../utils';

// Local Typings
interface Props {
  error: string;
  onSubmit: (values: any) => void;
}

// Local Variables
const useStyles = makeStyles((theme) => ({
  form: {
    boxSizing: 'border-box',
    maxWidth: 368,
    padding: '24px 24px 8px',
  },
  input: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  title: {
    fontFamily: 'BioRhyme',
  },
}));

// Component Definition
const SignUpForm: FC<Props> = ({
  error = '',
  onSubmit,
}) => {
  const classes = useStyles();

  const validate = (values: any) => {
    const errors: any = {};

    if (validateEmail(values.email)) {
      errors.email = 'Invalid email';
    }

    if (validatePasswordLength(values.password)) {
      errors.password = 'Password must be at least 6 characters long';
    }

    // map over values and make all required
    Object.entries(values).forEach(([k, v]) => {
      if (!v) {
        errors[k] = 'Required';
      }
    });

    // is passwords do not match
    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }
    return errors;
  };

  return (
    <Formik
      initialValues={{
        confirmPassword: '',
        email: '',
        firstName: '',
        lastName: '',
        password: '',
      }}
      onSubmit={async (values, { setSubmitting }) => {
        // do code here to submit
        await onSubmit(values);
        setSubmitting(false);
      }}
      validate={validate}
    >
      {({ isSubmitting }) => (
        <Form className={classes.form}>
          <Typography
            className={classes.title}
            variant="h5"
          >
            Sign Up
          </Typography>

          <Box
            maxWidth={360}
            py={2}
          >
            <CustomInput
              className={classes.input}
              fullWidth
              label="First Name"
              name="firstName"
            />
            <CustomInput
              className={classes.input}
              fullWidth
              label="Last Name"
              name="lastName"
            />
            <CustomInput
              className={classes.input}
              fullWidth
              label="Email"
              name="email"
              type="email"
            />
            <CustomInput
              className={classes.input}
              fullWidth
              label="Password"
              name="password"
              type="password"
            />
            <CustomInput
              className={classes.input}
              fullWidth
              label="Confirm Password"
              name="confirmPassword"
              type="password"
            />
          </Box>

          <CustomFormButton
            buttonText="Sign Up"
            disabled={isSubmitting}
          />

          <ErrorMessage
            condition={!!error}
            message={error}
          />
        </Form>
      )}
    </Formik>
  );
};

export default SignUpForm;
