// External Dependencies
import { alpha, lighten } from '@mui/material/styles';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import LicenseIcon from '@mui/icons-material/LocalActivity';
import styled from 'styled-components';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// Internal Dependencies
import {
  EnhancedCard,
  ShowPageDataDisplay,
} from 'components/shared';

// Local Typings
interface Props {
  districtId: string;
  licensePriceInCents: number;
}

// Local Variables
const StyledEnhancedCard = styled(EnhancedCard)(({ theme }) => ({
  '.cardHeaderAvatar': {
    backgroundColor: lighten(theme.palette.stripeBlue['600'], 0.4),
  },
  '.cardHeaderTitle': {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: 500,
  },
  '.licensesCardHeader': {
    backgroundColor: alpha(theme.palette.accentBlue, 0.1),
  },
}));

// Component Definition
const DistrictLicensesCard = ({
  districtId,
  licensePriceInCents,
}: Props): JSX.Element => {
  const navigate = useNavigate();

  const handleNavigateToLicensesPage = useCallback(() => {
    navigate(`/districts/${districtId}/licenses`);
  }, [districtId]);

  return (
    <StyledEnhancedCard
      sx={{
        marginY: 3,
      }}
    >
      <CardHeader
        avatar={(
          <Avatar
            aira-label="Licenses Info"
            className="cardHeaderAvatar"
          >
            <LicenseIcon />
          </Avatar>
        )}
        className="licensesCardHeader"
        classes={{
          title: 'cardHeaderTitle',
        }}
        title="Licenses Info"
      />

      <Divider />

      <CardContent>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
        >
          <ShowPageDataDisplay
            label="License Price"
            type="currency"
            value={licensePriceInCents}
          />
          <Button
            endIcon={<ArrowForwardIcon />}
            onClick={handleNavigateToLicensesPage}
            sx={{ height: 36 }}
            variant="outlined"
          >
            View Licenses
          </Button>
        </Box>
      </CardContent>
    </StyledEnhancedCard>
  );
};

export default DistrictLicensesCard;
