// External Dependencies
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import { convertCentsToDollars, displayPriceStringFromDollarAmount } from 'utils/lib/currency';
import { EnhancedCard, EnhancedCardContent } from 'components/shared';
import { useGetDistrictTotals } from 'hooks/useGetDistrictTotals';

const StyledRoot = styled.section(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    '.metrics-data': {
      fontSize: 22,
    },
    '.metrics-title': {
      fontSize: 13,
    },
    gap: theme.spacing(2),
  },
  display: 'flex',
  gap: theme.spacing(4),
  marginTop: theme.spacing(4),
}));

// Component Definition
const Metrics = (): JSX.Element => {
  const {
    totalActiveLicenses,
    totalDistrictValueInCents,
  } = useGetDistrictTotals();

  const totalRevenuePriceString = displayPriceStringFromDollarAmount(
    convertCentsToDollars(totalDistrictValueInCents),
  );

  return (
    <StyledRoot>
      <EnhancedCard>
        <EnhancedCardContent>
          <Typography
            className="metrics-title"
            component="h3"
            gutterBottom
            variant="subtitle1"
          >
            # of Active Licenses
          </Typography>

          <Typography
            className="metrics-data"
            component="div"
            variant="h4"
          >
            {totalActiveLicenses}
          </Typography>
        </EnhancedCardContent>
      </EnhancedCard>

      <EnhancedCard>
        <EnhancedCardContent>
          <Typography
            className="metrics-title"
            component="h3"
            gutterBottom
            variant="subtitle1"
          >
            Total Annual Value
          </Typography>

          <Typography
            className="metrics-data"
            component="div"
            variant="h4"
          >
            {totalRevenuePriceString}
          </Typography>
        </EnhancedCardContent>
      </EnhancedCard>
    </StyledRoot>
  );
};

export default Metrics;
