// External Dependencies
import axios, { AxiosResponse } from 'axios';
import {
  UseMutationOptions,
  useMutation,
} from '@tanstack/react-query';

// Internal Dependencies
import {
  clearTokens,
  getToken,
  setTokenInStorage,
} from '../cookies';

// Local Typings
export interface LoginFormValues {
  email: string;
  password: string;
}

export interface SignupFormValues {
  confirmPassword: string;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
}

export interface StatesValues {
  states: DB.State[];
}

// Utils
export async function sendRequest<T>({
  data = null,
  endpoint,
  method = 'GET',
  tokenOverride,
}: {
  data?: any,
  endpoint: string;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  tokenOverride?: string;
}): Promise<AxiosResponse<T>> {
  // if dev, log the data being sent to the server
  if (process.env.NODE_ENV === 'development') {
    console.log('endpoint:', endpoint, { data });
  }
  const { REACT_APP_API_URL } = process.env;
  const token = tokenOverride || getToken();
  const url = `${REACT_APP_API_URL}${endpoint}`;

  try {
    const response: AxiosResponse<T> = await axios({
      data,
      headers: { 'X-Access-Token': token },
      method,
      url,
      withCredentials: true,
    });

    return response;
  } catch (err: any) {
    if (process.env.NODE_ENV === 'development') {
      console.error('sendRequest: err', { err }); // eslint-disable-line
    }

    return err.response;
  }
}

export async function getRequest<Response = any>({
  endpoint,
}: {
  endpoint: string;
}) {
  return sendRequest<Response>({
    endpoint,
    method: 'GET',
  });
}

export async function postRequest<Response = any>({
  data,
  endpoint,
}: {
  data?: any;
  endpoint: string;
}) {
  return sendRequest<Response>({
    data,
    endpoint,
    method: 'POST',
  });
}

export async function putRequest<Response = any>({
  data,
  endpoint,
}: {
  data?: any;
  endpoint: string;
}) {
  return sendRequest<Response>({
    data,
    endpoint,
    method: 'PUT',
  });
}

// get methods
export const getMyProfile = async () => {
  const endpoint = '/admin/auth/me';
  return sendRequest({ endpoint });
};

export const getOrganization = async (id: string) => {
  const endpoint = `/admin/v1/organizations/${id}`;
  return sendRequest({ endpoint });
};

export const getOrganizations = async () => {
  const endpoint = '/admin/v1/organizations?order_by=label';
  return sendRequest({ endpoint });
};

export const getOrgCount = async () => {
  const endpoint = '/auth/number_of_orgs';
  return sendRequest({ endpoint });
};

export const getOrgTypes = async () => {
  const endpoint = '/auth/organization_types';
  return sendRequest({ endpoint });
};

export const getStates = async () => {
  const endpoint = '/auth/states';
  return sendRequest<StatesValues>({ endpoint });
};

export const handleTokenResponse = (response: AxiosResponse) => {
  if (response && response.status === 200) {
    const { headers } = response;
    const token = headers && headers['x-access-token'];
    setTokenInStorage(token);
  } else {
    clearTokens();
  }
};

// post methods
export const fetchUserToken = async (userId: string) => {
  const endpoint = '/admin/auth/become_user';
  const data = { userId };

  let token;

  try {
    const response = await postRequest({ data, endpoint });

    if (response.status === 200) {
      const tokenFromResponse = response.headers['x-access-token'];
      token = tokenFromResponse;
    }
  } catch (error) {
    console.error(error);
  }
  return token;
};

export const login = async (data: LoginFormValues) => {
  const endpoint = '/admin/auth/login';
  const response = await postRequest({
    data,
    endpoint,
  });
  handleTokenResponse(response);
  return response;
};

export const logout = async () => {
  const endpoint = '/auth/logout';
  const response = await postRequest({ endpoint });
  clearTokens();
  return response;
};

export const refreshToken = async () => {
  const endpoint = '/auth/refresh_token';
  const response = await postRequest({ endpoint });
  handleTokenResponse(response);
  return response;
};

export const signUp = async (data: SignupFormValues) => {
  const endpoint = '/admin/auth/signup';
  const response = await postRequest({ data, endpoint });
  handleTokenResponse(response);
  return response;
};

// put methods
// Currently unused as of 2022-01-02
export const toggleOrgActive = async (id: string) => {
  const endpoint = `/admin/v1/organizations/${id}/toggle_active`;
  const response = await putRequest({ endpoint });
  return response;
};

export const updateSubscription = async (id: string, data: any) => {
  const endpoint = `/admin/v1/subscriptions/${id}`;
  const response = await putRequest({ data, endpoint });
  return response;
};

export const useTanstackMutation = <TData, TError, TVariables, TContext>({
  ...options
}: UseMutationOptions<TData, TError, TVariables, TContext>) => {
  return useMutation({
    ...options,
    onError: (error, variables, context) => {
      if (options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};
