// External Dependencies
import { useState } from 'react';

// Local Typings
export interface UseTextFieldProps {
  onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  onReset: () => void;
  value: string;
}

// Hook Definition
function useTextField(initialValue: string = '') {
  const [value, setValue] = useState(initialValue);

  function handleChange(evt: React.ChangeEvent<HTMLInputElement>) {
    setValue(evt.target.value);
  }

  function handleResetValue() {
    setValue('');
  }

  return {
    onChange: handleChange,
    onReset: handleResetValue,
    value,
  };
}

export default useTextField;
