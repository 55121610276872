// External Dependencies
import Box from '@mui/material/Box';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarProps,
  GridToolbarQuickFilter,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { FC, useEffect } from 'react';
import styled from 'styled-components';

// Local Dependencies
import AddButton, { AddButtonProps } from '../AddButton';
import {
  Flex,
  RefreshingIcon,
} from '..';
import ToolbarMoreActionsIconMenu from './ToolbarMoreActionsIconMenu';

// Local Typings
export interface EnhancedGridToolbarProps {
  addButtonProps?: AddButtonProps | null;
  isHydrating: boolean;
  toolbarActions?: IToolbarAction[];
  withSearch?: boolean;
}
export interface IToolbarAction {
  action: () => void;
  icon?: React.ReactNode;
  isDisabled?: boolean;
  text: string;
}

// Local Variables
const StyledRoot = styled(Box)(({ theme }) => ({
  '.bottomToolbarContainer': {
    padding: theme.spacing(0.5, 1.5, 1.5),
  },
  '.searchAndActionsContainer': {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    justifyContent: 'flex-end',
  },
  '.topToolbarContainer': {
    padding: theme.spacing(1.5, 1.5, 0.5),
  },

  borderBottom: `1px solid ${theme.palette.grey['300']}`,
  left: 0,
  position: 'sticky',
  right: 0,
}));

const EMPTY_SEARCH_STATE = [''];

// Component Definition
const EnhancedGridToolbar: FC<EnhancedGridToolbarProps & GridToolbarProps> = ({
  addButtonProps,
  isHydrating,
  setFilterButtonEl,
  toolbarActions,
  withSearch,
}) => {
  const apiContext = useGridApiContext();

  // When the component mounts or unmounts, we set the search state back to an empty string
  // If we don't reset this manually, unmounting some components like the
  //  DialogPeoplePicker will maintain the search data rows, but the value for
  //  the quick filter search will be gone b/c GridToolbarQuickFilter uses
  //  React local state to track the search value.
  // If MUI-X DataGrid ever exposes the `searchValue` externally, we should
  //  consider setting it to this in TableDataGrid so search is maintained from localStorage
  //  `JSON.parse(searchParams.dataGridFilters).quickFilterValues`
  useEffect(() => {
    apiContext.current.setQuickFilterValues(EMPTY_SEARCH_STATE);

    return () => apiContext.current.setQuickFilterValues(EMPTY_SEARCH_STATE);
  }, []);

  const shouldShowMoreActionsMenu = toolbarActions && toolbarActions.length > 0;

  return (
    <StyledRoot>
      <Flex
        alignItems="flex-start"
        className="topToolbarContainer"
        justifyContent="space-between"
      >
        <Flex>
          {addButtonProps && (
            <AddButton
              {...addButtonProps}
              marginRight={24}
            />
          )}
        </Flex>
        <Flex className="searchAndActionsContainer">
          {isHydrating && <RefreshingIcon sx={{ marginX: 2 }} />}

          {withSearch && <GridToolbarQuickFilter debounceMs={300} />}

          {shouldShowMoreActionsMenu && (
            <ToolbarMoreActionsIconMenu moreActions={toolbarActions} />
          )}
        </Flex>
      </Flex>

      <Box className="bottomToolbarContainer">
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton ref={setFilterButtonEl} />
        </GridToolbarContainer>
      </Box>
    </StyledRoot>
  );
};

export default EnhancedGridToolbar;
