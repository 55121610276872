import gql from 'graphql-tag';
import { useQueryEnhanced } from 'hooks';

export interface FeatureFlagOverride {
  id: string;
  isActive: boolean;
  organization: {
    id: string;
    label: string;
  };
  reason: string;
}

export interface FeatureFlag {
  allowOrganizationOverrides: boolean;
  dependsOn: FeatureFlag | null;
  description: string;
  id: number;
  isActive: boolean;
  label: string;
  overrides: FeatureFlagOverride[];
}

interface FeatureFlagsResponse {
  featureFlags: FeatureFlag[];
}

const GET_FEATURE_FLAGS = gql`
  query FeatureFlags {
    featureFlags {
      allowOrganizationOverrides
      dependsOn {
        id
        label
      }
      description
      id
      isActive
      label
      overrides {
        id
        isActive
        organization {
          id
          label
        }
        reason
      }
    }
  }
`;

export const useGetFeatureFlags = () =>
  useQueryEnhanced<FeatureFlagsResponse>(GET_FEATURE_FLAGS);
