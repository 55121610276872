import Dialog from '@mui/material/Dialog';
import { Form, Formik } from 'formik';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { DialogActions } from '@mui/material';
import CustomInput from '../../components/shared/CustomInput';
import { Flex } from '../../components/shared';
import SaveButton from '../../components/shared/SaveButton';

// Local Typings
interface LoginValues {
  email: string;
  password: string;
}
interface Props {
  isOpen: boolean;
  isSubmitting: boolean;
  onClose: () => void;
  onSubmit: (values: LoginValues) => void;
}

const LoginFormDialog = ({
  isOpen,
  isSubmitting,
  onClose,
  onSubmit,
}: Props): JSX.Element => {
  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
    >
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={onSubmit}
      >
        {() => {
          return (
            <Form>
              <DialogTitle>
                Login as User
              </DialogTitle>

              <DialogContent>
                <CustomInput
                  label="Email"
                  name="email"
                  placeholder="Enter email"
                />

                <CustomInput
                  label="Password"
                  name="password"
                  type="password"
                />
              </DialogContent>

              <DialogActions>
                <Flex
                  justifyContent="flex-end"
                  width="100%"
                >
                  <SaveButton
                    isSaving={isSubmitting}
                  >
                    Submit
                  </SaveButton>
                </Flex>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default LoginFormDialog;
