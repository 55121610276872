// External Dependencies
import {
  Alert, AlertProps, AlertTitle, Collapse,
} from '@mui/material';
import { FC, ReactNode } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import styled, { useTheme } from 'styled-components';

// Local Typings
interface Props extends AlertProps {
  action?: ReactNode;
  children: any;
  isAlertOpen?: boolean;
  onClose?: () => void;
  title?: AlertProps['title'];
}

// Local Variables
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
}));

// Component Definition
const EnhancedAlert: FC<Props> = ({
  action,
  children,
  isAlertOpen,
  onClose,
  title,
  ...otherProps
}) => {
  const theme = useTheme();

  const actionElements = [action];

  const closeActionElement = (
    <StyledIconButton
      aria-label="close"
      key="close-button"
      onClick={onClose}
      size="small"
    >
      <CloseIcon fontSize="small" />
    </StyledIconButton>
  );

  if (onClose) {
    actionElements.push(closeActionElement);
  }

  const alertElement = (
    <StyledAlert
      action={actionElements}
      {...otherProps}
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      {children}
    </StyledAlert>
  );

  return onClose
    ? (
      <Collapse
        in={isAlertOpen}
        timeout={theme.transitions.duration.shortest}
      >
        {alertElement}
      </Collapse>
    ) : alertElement;
};

export default EnhancedAlert;
