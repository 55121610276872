// External Dependencies
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import styled from 'styled-components';

// Internal Dependencies
import { EnhancedCard, ShowPageDataDisplay } from 'components/shared';

// Local Typings
interface Props {
  feedback: GQL.IFeedback;
}

// Local Variables
const StyledCardContent = styled(CardContent)(({ theme }) => ({
  '&:last-child': {
    paddingBottom: theme.spacing(2),
  },
}));

// Component Definition
const SubmissionDetailsCard: FC<Props> = ({ feedback }) => {
  const navigate = useNavigate();

  const navigateToDistrictShowPage = () => {
    navigate(`/districts/${feedback.district?.id}`);
  };

  const navigateToOrganizationShowPage = () => {
    navigate(`/organizations/${feedback.organization?.id}`);
  };

  return (
    <EnhancedCard>
      <StyledCardContent>
        <>
          <Box
            alignItems="center"
            display="flex"
            flexWrap="wrap"
          >
            <ShowPageDataDisplay
              label="District"
              value={feedback.district?.label}
            />

            {feedback.district && (
              <Button
                color="primary"
                onClick={navigateToDistrictShowPage}
                size="small"
                variant="outlined"
              >
                View District
              </Button>
            )}
          </Box>

          <Box
            alignItems="center"
            display="flex"
            flexWrap="wrap"
          >
            <ShowPageDataDisplay
              label="Organization"
              value={feedback.organization?.label}
            />

            {feedback.organization && (
              <Button
                color="primary"
                onClick={navigateToOrganizationShowPage}
                size="small"
                variant="outlined"
              >
                View Organization
              </Button>
            )}
          </Box>

          <ShowPageDataDisplay
            label="Submitted At"
            type="datetime"
            value={feedback.submittedAt}
          />

          <ShowPageDataDisplay
            label="Submitted By"
            value={feedback.submittedBy.email}
          />
        </>
      </StyledCardContent>
    </EnhancedCard>
  );
};

export default SubmissionDetailsCard;
