import { memo } from 'react';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import LockIcon from '@mui/icons-material/Lock';
import { useTheme } from 'styled-components';

import BackToButton from './BackToButton';
import EnhancedCard from './EnhancedCard';
import EnhancedAlert from './EnhancedAlert';

// Component Definition
const RestrictedContentCard = (): JSX.Element => {
  const theme = useTheme();

  return (
    <EnhancedCard>
      <CardContent>
        <Box
          display="flex"
          justifyContent="center"
          marginBottom={4}
          marginTop={2}
        >
          <LockIcon
            htmlColor={theme.palette.error.dark}
            sx={{
              height: 72,
              width: 72,
            }}
          />
        </Box>

        <EnhancedAlert
          severity="error"
          title="Restricted Page"
        >
          Your permission level does not allow you to view this page.
        </EnhancedAlert>

        <Box marginTop={4}>
          <BackToButton
            context="dashboard"
            navigateTo="/"
          />
        </Box>
      </CardContent>
    </EnhancedCard>
  );
};

export default memo(RestrictedContentCard);
