// External Dependencies
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import { lighten } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Internal Dependencies
import { EnhancedCard, EnhancedCardContent } from 'components/shared';
import { parseSearch } from 'utils';
import { useGetStateOptions } from 'hooks/useGetStateOptions';

// Local Dependencies
import MemberSearchInput from './MemberSearchInput';
import MemberSearchResults from './MemberSearchResults';

// Local Variables
const StyledSection = styled.section(({ theme }) => ({
  '.member-search-card': {
    [theme.breakpoints.down('md')]: {
      minWidth: '100%',
    },
    borderRadius: 12,
    marginBottom: theme.spacing(4),
    minWidth: 520,
  },
  '.member-search-card-header': {
    background: lighten(theme.palette.prestoPrimaryLight, 0.7),
  },
  '.member-search-card-header-text': {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
    marginBottom: 0,
  },
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 720,
  width: '100%',
}));

// Component Definition
const MemberSearch = (): JSX.Element => {
  const navigate = useNavigate();

  const currentSearchParams = parseSearch(window.location.search);

  const stateOptions = useGetStateOptions();

  const [searchedEmail, setSearchedEmail] = useState(currentSearchParams.email ?? '');
  const [isLoadingData, setIsLoadingData] = useState(false);

  useEffect(() => {
    // If searchedEmail changes, we want to update the appended query string in the URL
    const searchParams = new URLSearchParams(window.location.search);

    if (!currentSearchParams.email && searchedEmail) {
      searchParams.append('email', searchedEmail);
    } else if (!searchedEmail) {
      searchParams.delete('email');
    }

    navigate({
      search: searchParams.toString(),
    });
  }, [currentSearchParams.email, searchedEmail]);

  return (
    <StyledSection>
      <EnhancedCard className="member-search-card">
        <EnhancedCardContent className="member-search-card-header">
          <Typography
            className="member-search-card-header-text"
            component="h3"
            variant="h6"
          >
            Member search
          </Typography>
        </EnhancedCardContent>

        <EnhancedCardContent>
          <MemberSearchInput
            isLoading={isLoadingData}
            searchedEmail={searchedEmail}
            setSearchedEmail={setSearchedEmail}
          />
        </EnhancedCardContent>
      </EnhancedCard>

      {searchedEmail && (
        <MemberSearchResults
          searchedEmail={searchedEmail}
          setIsLoadingData={setIsLoadingData}
          stateOptions={stateOptions.options}
        />
      )}
    </StyledSection>
  );
};

export default MemberSearch;
