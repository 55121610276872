// External Dependencies
import { convertCentsToDollars, convertDollarsToCents } from '@presto-assistant/api_types/utils';

// Internal Dependencies
import { formatDate, formatDateTime, getDateFromInput } from 'utils/lib/date_helpers';
import { displayPriceStringFromDollarAmount } from 'utils/lib/currency';
import { DataGridColDef } from 'types/dataGrid';

// Local Typings
interface ColumnArguments<T> {
  field: keyof T;
  headerName: string;
  hideable?: boolean;
  minWidth?: number;
}

interface DisplayCellOptions {
  format: 'date' | 'datetime' | 'money' | 'text';
}

export const displayCell = (
  value?: string | number | null,
  options: DisplayCellOptions = {
    format: 'text',
  },
) => {
  if (value === '' || value === null || value === undefined) {
    return '—';
  }

  switch (options.format) {
    case 'date':
      return formatDate(value as string);
    case 'datetime':
      return formatDateTime(value as string);
    case 'money':
      return displayPriceStringFromDollarAmount(convertCentsToDollars(value as number));
    default:
      return value.toString(); // if it is a boolean, we want to return the string
  }
};

export const renderMoneyCell = (value: string | number) => (Number.isFinite(Number(value))
  ? displayCell(convertDollarsToCents(value as number), { format: 'money' })
  : value);

export function currencyFromCentsColumn<T>(args: ColumnArguments<T>): DataGridColDef<T> {
  return {
    field: args.field,
    headerName: args.headerName,
    renderCell: (params) => {
      if (params.value === null) {
        return null;
      }

      return renderMoneyCell(params.value);
    },
    type: 'number',
    valueGetter: (params) => {
      const value = params.row[args.field] as unknown as any;

      if (Array.isArray(value) || value === null || value === '' || !Number.isFinite(Number(value))) {
        return '—';
      }

      return displayCell(convertCentsToDollars(Number(value)));
    },
    width: 160,
  };
}

export function dateColumn<T>(args: ColumnArguments<T>): DataGridColDef<T> {
  return {
    field: args.field,
    headerName: args.headerName,
    hideable: args.hideable,
    minWidth: args.minWidth ?? 160,
    renderCell: ({ row }) => displayCell(row[args.field] as unknown as string, { format: 'date' }),
    type: 'date',
    valueFormatter: (params) => displayCell(params.value, { format: 'date' }),
    valueGetter: (params) => {
      const date = formatDate(params.value);

      if (!date) {
        return null;
      }

      return new Date(date);
    },
    width: 160,
  };
}

export function dateTimeColumn<T>(args: ColumnArguments<T>): DataGridColDef<T> {
  return {
    field: args.field,
    headerName: args.headerName,
    hideable: args.hideable,
    minWidth: args.minWidth ?? 200,
    renderCell: ({ row }) => displayCell(row[args.field] as unknown as string, { format: 'datetime' }),
    type: 'dateTime',
    valueFormatter: (params) => {
      getDateFromInput(params.value);
    },
    width: 200,
  };
}
