// External Dependencies
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';

// Local Typings
interface Props {
  context: string;
  navigateTo?: string;
  onClickButton?: () => void;
  rootClassName?: string;
}

// Local Variables
const useStyles = makeStyles((theme) => ({
  backButton: {
    [theme.breakpoints.down('md')]: {
      fontSize: theme.spacing(1.5),
      marginBottom: theme.spacing(1),
    },
    backgroundColor: theme.palette.common.white,
  },
  backIcon: {
    [theme.breakpoints.down('md')]: {
      fontSize: theme.spacing(2),
    },
    marginRight: theme.spacing(1),
  },
}));

// Component Definition
const BackToButton = ({
  context,
  navigateTo,
  onClickButton,
  rootClassName,
}: Props): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    if (onClickButton) {
      onClickButton();
    } else if (navigateTo) {
      navigate(navigateTo);
    }
  }, [navigate, navigateTo, onClickButton]);

  return (
    <Button
      className={clsx(classes.backButton, rootClassName)}
      color="primary"
      onClick={handleClick}
      onKeyPress={handleClick}
      size="small"
      startIcon={(
        <ArrowBackIcon
          className={classes.backIcon}
          fontSize="small"
        />
      )}
      variant="outlined"
    >
      Back to {context}
    </Button>
  );
};

export default BackToButton;
