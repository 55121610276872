// External Dependencies
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControlLabel,
  Switch,
  Typography,
} from '@mui/material';
import { ApolloError } from '@apollo/client';
import {
  FC, FocusEvent, MouseEvent, useEffect, useState,
} from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Internal Dependencies
import { FeatureFlag } from 'gql/queries/featureFlags';
import { Flex } from 'components/shared';
import { useDidMount } from 'hooks/useDidMount';
import { useUpdateFeatureFlag } from 'gql/mutations/featureFlags';

// Local Dependencies
import OverridesList from './OverridesList';

// Local Typings
interface Props {
  featureFlags: FeatureFlag[];
  onError: (error: ApolloError | null) => void;
}

type FlagValues = Record<number, boolean>;

// Local Variables
function reduceFeatureFlags(featureFlags: FeatureFlag[]): Record<number, boolean> {
  return featureFlags.reduce<FlagValues>((prev, curr) => ({
    ...prev,
    [curr.id]: curr.isActive,
  }), {});
}
function reduceFeatureFlagOverrides(featureFlags: FeatureFlag[]): Record<number, boolean> {
  return featureFlags.reduce<FlagValues>((prev, curr) => ({
    ...prev,
    [curr.id]: curr.allowOrganizationOverrides,
  }), {});
}

const stopPropagation = (evt: MouseEvent | FocusEvent) => evt.stopPropagation();

// Component Definition
const FlagList: FC<Props> = ({
  featureFlags,
  onError,
}) => {
  const [flagValues, setFlagValues] = useState<FlagValues>(reduceFeatureFlags(featureFlags));
  const [overrideValues, setOverrideValues] = useState<FlagValues>(
    reduceFeatureFlagOverrides(featureFlags),
  );

  const [updateFeatureFlag] = useUpdateFeatureFlag({
    onError,
  });

  const didMount = useDidMount();

  useEffect(() => {
    if (didMount) {
      setFlagValues(reduceFeatureFlags(featureFlags));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureFlags]);

  const handleChange = (flagId: number) => () => {
    const isActive = !flagValues[flagId];

    setFlagValues({
      ...flagValues,
      [flagId]: isActive,
    });

    updateFeatureFlag({
      variables: {
        id: flagId,
        input: {
          isActive,
        },
      },
    });
  };

  const handleChangeOverride = (flagId: number) => () => {
    const allowOrganizationOverrides = !overrideValues[flagId];

    setOverrideValues({
      ...overrideValues,
      [flagId]: allowOrganizationOverrides,
    });

    updateFeatureFlag({
      variables: {
        id: flagId,
        input: {
          allowOrganizationOverrides,
        },
      },
    });
  };

  return (
    <>
      {featureFlags.map((flag) => {
        const isActive = flagValues[flag.id];
        const allowOrganizationOverrides = overrideValues[flag.id];

        return (
          <Accordion
            key={flag.id}
            square
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box width="100%">
                <Flex justifyContent="space-between">
                  <Typography gutterBottom>
                    {flag.label}
                  </Typography>

                  <FormControlLabel
                    aria-label="Toggle Feature"
                    control={(
                      <Switch
                        checked={isActive}
                        color="primary"
                        name={flag.label}
                        onChange={handleChange(flag.id)}
                      />
                    )}
                    label="Active"
                    onClick={stopPropagation}
                    onFocus={stopPropagation}
                  />
                </Flex>

                <Box
                  marginLeft={2}
                  marginRight={4}
                  marginTop={1}
                >
                  <Flex justifyContent="space-between">
                    <Typography variant="body2">
                      {flag.description}
                    </Typography>

                    <Typography variant="body2">
                      Depends on: {flag.dependsOn?.label ?? '—'}
                    </Typography>
                  </Flex>
                </Box>
              </Box>
            </AccordionSummary>

            <AccordionDetails>
              <Flex
                alignItems="flex-start"
                flexDirection="column"
              >
                <Typography>
                  <FormControlLabel
                    aria-label="Toggle Allow Override"
                    control={(
                      <Switch
                        checked={allowOrganizationOverrides}
                        color="primary"
                        name={flag.label}
                        onChange={handleChangeOverride(flag.id)}
                      />
                    )}
                    label="Allow Organization Overrides"
                    onClick={stopPropagation}
                    onFocus={stopPropagation}
                  />
                </Typography>

                {allowOrganizationOverrides && (
                  <OverridesList
                    flag={flag}
                    onError={onError}
                  />
                )}
              </Flex>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};

export default FlagList;
