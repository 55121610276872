// External Dependencies
import {
  IconButton,
  IconButtonProps,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
  Tooltip,
} from '@mui/material';
import {
  KeyboardEvent, MouseEvent, ReactNode, useCallback, useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// Local Typings
export interface MoreActionsItem<T> {
  action: ((row: T) => void) | (() => void);
  icon?: ReactNode;
  isDisabled?: boolean | ((row: T) => boolean);
  text: string;
}
interface Props<T> {
  disabled?: boolean;
  iconButtonProps?: IconButtonProps;
  moreActions: MoreActionsItem<T>[];
  row?: T;
}

// Local Variables
const useStyles = makeStyles({
  iconButton: {
    fontSize: '1.25rem',
    padding: 8,
  },
});

const anchorOrigin: MenuProps['anchorOrigin'] = { horizontal: 'right', vertical: 'bottom' };
const transformOrigin: MenuProps['transformOrigin'] = { horizontal: 'right', vertical: 'top' };

function handleKeyDown(event: KeyboardEvent) {
  if (['Enter', ' '].includes(event.key)) {
    event.stopPropagation();
  }
}

// Component Definition
function ToolbarMoreActionsIconMenu<T>({
  disabled,
  iconButtonProps,
  moreActions,
  row,
}: Props<T>) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<MenuProps['anchorEl']>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event: MouseEvent) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback((event: MouseEvent, action: MoreActionsItem<T>['action'] | null = null) => {
    event.stopPropagation();

    action?.(row as T);

    setAnchorEl(null);
  }, [row]);

  const getIsDisabled = useCallback((action: MoreActionsItem<T>): boolean => {
    if (typeof action.isDisabled !== 'function') {
      return Boolean(action.isDisabled);
    }

    if (row) {
      return action.isDisabled(row);
    }

    return false;
  }, [row]);

  return (
    <>
      <Tooltip
        key="icon-button-more-actions"
        placement="bottom-end"
        title="More Actions"
      >
        <div>
          <IconButton
            aria-controls={open ? 'more-actions-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            aria-label="More actions"
            aria-owns={open ? 'more-actions-menu' : undefined}
            classes={{
              sizeSmall: classes.iconButton,
            }}
            disabled={disabled}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            size="small"
            {...iconButtonProps}
          >
            <MoreVertIcon fontSize="small" />
          </IconButton>
        </div>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        id="more-actions-menu"
        onClose={(e) => handleClose(e as MouseEvent)}
        open={open}
        transformOrigin={transformOrigin}
      >
        {moreActions.map((action) => (
          <MenuItem
            disabled={getIsDisabled(action)}
            key={action.text}
            onClick={(e) => handleClose(e, action.action)}
            onKeyDown={handleKeyDown}
          >
            {action.icon && <ListItemIcon>{action.icon}</ListItemIcon>}
            {action.text && <ListItemText>{action.text}</ListItemText>}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default ToolbarMoreActionsIconMenu;
