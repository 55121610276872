// External Dependencies
import { useState, useEffect } from 'react';

// Hook Definition
export const useDidMount = () => {
  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    setDidMount(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return didMount;
};
