// External Dependencies
import { GridColDef } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';

// Internal Dependencies
import { useGetStripeWebhookEventTypes } from 'gql/queries/stripeWebhookEvents';

// Local Variables
export const useColumns = (extraColumns?: GridColDef[]) => {
  const {
    data,
  } = useGetStripeWebhookEventTypes();

  return useMemo(() => {
    const columns: GridColDef<GQL.IStripeWebhookEvent>[] = [
      {
        field: 'eventId',
        headerName: 'Event Id',
        width: 292,
      },
      {
        field: 'eventType',
        headerName: 'Event Type',
        type: 'singleSelect',
        valueOptions: data?.stripeWebhookEventTypes || [],
        width: 292,
      },
      {
        field: 'eventDate',
        headerName: 'Event Date',
        valueFormatter: ({ value }) => new Date(value).toLocaleString(),
        width: 292,
      },
      {
        field: 'processedAt',
        headerName: 'Date Processed',
        valueFormatter: ({ value }) => new Date(value).toLocaleString(),
        width: 292,
      },
      ...(extraColumns ?? []),
    ];

    return columns;
  }, [
    data,
    extraColumns,
  ]);
};
