// Local Dependencies
import AnnualValue from './AnnualValue';
import DailyActiveUsers from './DailyActiveUsers';
import Metrics from './Metrics';

// Component Definition
const Dashboard = (): JSX.Element => {
  return (
    <>
      <DailyActiveUsers />

      <AnnualValue />

      <Metrics />
    </>

  );
};

export default Dashboard;
