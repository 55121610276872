// External Dependencies
import SvgIcon from '@mui/material/SvgIcon';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from 'styled-components';

// Local Variables
const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      width: '55%',
    },
    height: '100%',
    width: '60%',
  },
}));

// Component Definition
const PrestoLogoVerticalSvg = (props: any) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <SvgIcon
      className={classes.root}
      htmlColor={theme.palette.prestoPrimaryMain}
      viewBox="0 0 383.81 214.66"
      {...props}
    >
      <g>
        <circle
          cx="217.04"
          cy="78.16"
          r="23"
        />
        <path
          d="M253.88,8.94c-14.1-7-26-10.47-48.72-8.29a65.61,65.61,0,0,0-24.34,7.76c-9.16,5.23-9.16,5.23-19.07,14.06L93.24,91a19.46,19.46,0,0,0,27.51,27.52h0L189.27,50a39.7,39.7,0,0,1,13.18-8.77A40.12,40.12,0,0,1,225.11,39,39.59,39.59,0,0,1,236,42.86a39.9,39.9,0,1,1-46.75,63.54l-7.86-7.86c-7.46-7.46-19.61-8-27.33-.82a19.45,19.45,0,0,0-.51,28l8.18,8.18A78.8,78.8,0,0,0,294.83,63.16a71,71,0,0,0-3.24-10.26c-2.8-7.38-2.92-8.51-7.16-15.17-12.08-19-20.66-22.7-30.55-28.79"
        />
        <path
          d="M0,213.33V190.86c0-5,3.12-8.47,7.84-8.47S16,185.82,16,190.86v4.24c0,4.94-3.17,8.24-8,8.24H5.57v10a.7.7,0,0,1-.76.8H.8A.71.71,0,0,1,0,213.33Zm7.62-15.11c1.78,0,2.72-1,2.72-2.95v-4.81c0-1.74-.89-2.81-2.41-2.81s-2.36,1.07-2.36,2.81v7.76Z"
        />
        <path
          d="M27.73,213.33V190.86c0-5,3.21-8.47,7.93-8.47s8.11,3.43,8.11,8.47v3.88a8.16,8.16,0,0,1-3.3,7l4.33,11.59c.13.49-.27.8-.76.8H39.72c-.49,0-.63-.31-.81-.76l-3.47-10.21h-2.1v10.17c0,.53-.26.8-.75.8H28.53A.71.71,0,0,1,27.73,213.33ZM35.39,198c1.83,0,2.77-1.12,2.77-3.12v-4.46c0-1.74-.94-2.81-2.45-2.81s-2.37,1.07-2.37,2.81V198Z"
        />
        <path
          d="M55.77,205.93V191.08c0-4.94,3.21-8.33,8-8.33h4.68a.72.72,0,0,1,.8.76v3.56a.72.72,0,0,1-.8.8h-4c-1.92,0-3.08,1.34-3.08,3.39v4.64h6a.73.73,0,0,1,.8.8v3.39a.71.71,0,0,1-.8.8h-6v4.9c0,2.05,1.16,3.39,3.08,3.39h4a.72.72,0,0,1,.8.76v3.61a.72.72,0,0,1-.8.76H63.79C59,214.31,55.77,210.92,55.77,205.93Z"
        />
        <path
          d="M80.24,205.39a.7.7,0,0,1,.76-.8h4a.73.73,0,0,1,.8.76c.09,2.76,1,4.1,2.63,4.1s2.36-1,2.36-2.59c0-6.15-10.29-8.73-10.29-17.11,0-4.59,3.07-7.36,7.62-7.36s7.67,3.35,7.84,8.87c0,.54-.26.8-.75.8h-4a.72.72,0,0,1-.8-.8c-.09-2.54-.85-3.7-2.23-3.7A2,2,0,0,0,86,189.75c0,5.48,10.39,8.11,10.39,17.11,0,4.82-3.25,7.8-8.07,7.8S80.42,211.14,80.24,205.39Z"
        />
        <path
          d="M122.36,183.69v3.61c0,.49-.26.75-.8.75h-4.23v25.28a.7.7,0,0,1-.76.8h-4a.7.7,0,0,1-.76-.8V188.05h-4.28a.68.68,0,0,1-.76-.75v-3.61a.69.69,0,0,1,.76-.76h14C122.1,182.93,122.36,183.2,122.36,183.69Z"
        />
        <path
          d="M132.49,206.1V191c0-5.13,3.34-8.51,8.2-8.51s8.15,3.38,8.15,8.38v15.42c0,5-3.3,8.38-8.15,8.38S132.49,211.23,132.49,206.1Zm10.74.54V190.42a2.51,2.51,0,0,0-2.54-2.81c-1.56,0-2.59,1.11-2.59,2.94v16c0,1.82,1,2.89,2.59,2.89A2.47,2.47,0,0,0,143.23,206.64Z"
        />
        <path
          d="M162.17,213.68V190.55c0-4.81,3-8,7.36-8s7.35,3.21,7.35,8v23.13a.42.42,0,0,1-.44.45h-1.79a.42.42,0,0,1-.44-.45V202.81h-9.36v10.87a.43.43,0,0,1-.45.45h-1.78A.43.43,0,0,1,162.17,213.68Zm12-13.37v-9.76c0-3.48-1.7-5.53-4.68-5.53s-4.68,2-4.68,5.53v9.76Z"
        />
        <path
          d="M190.17,206.1a.42.42,0,0,1,.44-.44h1.79a.42.42,0,0,1,.44.44c.14,3.93,1.78,6,4.82,6s4.72-1.61,4.72-4.68c0-7.62-12-9.32-12-18,0-4.14,2.85-6.91,7.18-6.91,4.05,0,6.86,3.3,7.08,8.25a.41.41,0,0,1-.44.44h-1.79a.42.42,0,0,1-.44-.44c-.18-3.92-1.74-5.75-4.41-5.75-2.95,0-4.5,1.51-4.5,4.41,0,7.18,12,8.83,12,18,0,4.32-2.94,7.17-7.39,7.17S190.3,211.19,190.17,206.1Z"
        />
        <path
          d="M217.18,206.1a.42.42,0,0,1,.45-.44h1.78a.42.42,0,0,1,.45.44c.13,3.93,1.78,6,4.81,6s4.72-1.61,4.72-4.68c0-7.62-12-9.32-12-18,0-4.14,2.86-6.91,7.18-6.91,4.06,0,6.86,3.3,7.09,8.25a.42.42,0,0,1-.45.44h-1.78a.42.42,0,0,1-.45-.44c-.18-3.92-1.74-5.75-4.41-5.75-2.94,0-4.5,1.51-4.5,4.41,0,7.18,12,8.83,12,18,0,4.32-2.94,7.17-7.4,7.17S217.32,211.19,217.18,206.1Z"
        />
        <path
          d="M246.16,182.93h1.78a.41.41,0,0,1,.44.44v30.31a.42.42,0,0,1-.44.45h-1.78a.43.43,0,0,1-.45-.45V183.37A.42.42,0,0,1,246.16,182.93Z"
        />
        <path
          d="M262,206.1a.41.41,0,0,1,.44-.44h1.78a.42.42,0,0,1,.45.44c.13,3.93,1.78,6,4.81,6s4.73-1.61,4.73-4.68c0-7.62-12-9.32-12-18,0-4.14,2.85-6.91,7.17-6.91,4.06,0,6.87,3.3,7.09,8.25a.42.42,0,0,1-.44.44h-1.79a.41.41,0,0,1-.44-.44c-.18-3.92-1.74-5.75-4.42-5.75-2.94,0-4.5,1.51-4.5,4.41,0,7.18,12,8.83,12,18,0,4.32-2.94,7.17-7.4,7.17S262.16,211.19,262,206.1Z"
        />
        <path
          d="M302.1,183.37V185a.42.42,0,0,1-.45.44H296.3v28.26a.42.42,0,0,1-.44.45h-1.78a.43.43,0,0,1-.45-.45V185.42h-5.35a.41.41,0,0,1-.44-.44v-1.61a.41.41,0,0,1,.44-.44h13.37A.42.42,0,0,1,302.1,183.37Z"
        />
        <path
          d="M314.45,213.68V190.55c0-4.81,2.94-8,7.35-8s7.36,3.21,7.36,8v23.13a.43.43,0,0,1-.45.45h-1.78a.43.43,0,0,1-.45-.45V202.81h-9.36v10.87a.42.42,0,0,1-.44.45h-1.79A.42.42,0,0,1,314.45,213.68Zm12-13.37v-9.76c0-3.48-1.69-5.53-4.68-5.53s-4.68,2-4.68,5.53v9.76Z"
        />
        <path
          d="M343.6,213.68V190.5c0-4.68,2.9-8,7.36-8s7.35,3.34,7.35,8v23.18a.43.43,0,0,1-.45.45h-1.78a.42.42,0,0,1-.44-.45V190.5c0-3.29-1.83-5.52-4.68-5.52s-4.68,2.23-4.68,5.52v23.18a.43.43,0,0,1-.45.45h-1.78A.43.43,0,0,1,343.6,213.68Z"
        />
        <path
          d="M383.81,183.37V185a.42.42,0,0,1-.45.44H378v28.26a.42.42,0,0,1-.44.45h-1.78a.43.43,0,0,1-.45-.45V185.42H370a.41.41,0,0,1-.44-.44v-1.61a.41.41,0,0,1,.44-.44h13.37A.42.42,0,0,1,383.81,183.37Z"
        />
      </g>
    </SvgIcon>
  );
};

export default PrestoLogoVerticalSvg;
