// External Dependencies
import {
  Box, CardContent, SelectChangeEvent,
} from '@mui/material';
import {
  FC, useCallback, useState,
} from 'react';
import moment from 'moment';

// Internal Dependencies
import {
  AreaLineChart,
  EnhancedCard,
  Select,
  Subtitle,
} from 'components/shared';
import { useGetAnnualValue } from 'gql/queries/dashboardMetrics';
import { SelectOption } from 'components/shared/Select';

// Local Variables
const threeMonthsAgo = moment().subtract(3, 'months').startOf('month').format('YYYY-MM-DD');
const sixMonthsAgo = moment().subtract(6, 'months').startOf('month').format('YYYY-MM-DD');
const twelveMonthsAgo = moment().subtract(12, 'months').startOf('month').format('YYYY-MM-DD');
const twoYearsAgo = moment().subtract(24, 'months').startOf('month').format('YYYY-MM-DD');

const selectOptions: SelectOption[] = [
  {
    id: threeMonthsAgo,
    label: '3 Months',
  },
  {
    id: sixMonthsAgo,
    label: '6 Months',
  },
  {
    id: twelveMonthsAgo,
    label: '12 Months',
  },
  {
    id: twoYearsAgo,
    label: '2 Years',
  },
  {
    id: '',
    label: 'All Time',
  },
];

// Component Definition
const AnnualValue: FC = () => {
  const [startDate, setStartDate] = useState<string | null>(threeMonthsAgo);

  const {
    data: annualValueData,
  } = useGetAnnualValue({
    startDate: startDate || null,
  });

  const chartData = annualValueData?.annualValue;

  const handleChange = useCallback((event: SelectChangeEvent<unknown>) => {
    setStartDate(event.target.value as string);
  }, []);

  return (
    <>
      <Box
        marginBottom={1}
        marginTop={4}
      >
        <Subtitle>
          Annual Value
        </Subtitle>
      </Box>

      <EnhancedCard sx={{ display: 'inline-block' }}>
        <CardContent>
          <Select
            fullWidth={false}
            label="Time Period"
            name="timePeriod"
            onChange={handleChange}
            options={selectOptions}
            required
            value={startDate ?? ''}
          />
        </CardContent>
      </EnhancedCard>

      <EnhancedCard>
        <CardContent>
          <AreaLineChart<GQL.IAnnualValue>
            data={chartData}
            xKey="date"
            yKey="annualValueInDollars"
          />
        </CardContent>
      </EnhancedCard>
    </>
  );
};

export default AnnualValue;
