// External Dependencies
import { useQuery } from '@tanstack/react-query';

// Internal Dependencies
import { prestoAdminApiClient } from 'utils/api/apiClient';

export const useGetAllOrganizations = () => {
  return useQuery({
    queryFn: () => prestoAdminApiClient.v1.organizations.getAll(),
    queryKey: ['adminOrganizations'],
  });
};
