// External Dependencies
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import { darken, lighten } from '@mui/material/styles';
import { ExtendedMember } from '@presto-assistant/api_types/api/admin/members';

// Internal Dependencies
import { EnhancedCard } from 'components/shared';

// Local Dependencies
import MemberLoginInfoSection from './MemberLoginInfoSection';
import MemberCardHeader from './MemberCardHeader';
import MembershipSection from './MembershipSection';
import MemberProfileSection from './MemberProfileSection';

// Local Typings
interface MemberWithStateLabel extends Partial<ExtendedMember> {
  stateLabel?: string;
}

interface Props {
  member?: MemberWithStateLabel;
}

// Local Variables
const StyledEnhancedCard = styled(EnhancedCard)(({ theme }) => ({
  '.auth-user-data': {
    alignItems: 'baseline',
    display: 'flex',
    gap: theme.spacing(3),
    justifyContent: 'space-between',
  },
  '.auth-user-data-display': {
    marginTop: 0,
  },
  '.link': {
    borderBottom: `1px solid ${theme.palette.link}`,
    boxShadow: `inset 0 -2px 0px 0px ${theme.palette.link}`,
    color: darken(theme.palette.link, 0.9),
    marginLeft: theme.spacing(1),
    padding: '4px 1px 0px',
    textDecoration: 'none',
    transition: 'all 0.15s ease-out',
  },
  '.member-auth-user': {
    backgroundColor: theme.palette.grey['100'],
  },
  '.member-search-result-address': {
    fontSize: '0.9rem',
  },
  '.member-search-result-address-subheader': {
    marginTop: theme.spacing(2),
  },
  '.member-search-result-card-header': {
    background: lighten(theme.palette.prestoPrimaryLight, 0.9),
  },
  '.member-search-result-header': {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
    marginBottom: 0,
  },
  '.member-search-result-membership': {
    background: lighten(theme.palette.stripeBlue['300'], 0.92),
  },
  '.subheader': {
    fontSize: '1.1rem',
    fontWeight: theme.typography.fontWeightMedium,
  },
  borderRadius: 12,
  marginBottom: theme.spacing(1.5),
})) as typeof Typography;

// Component Definition
const MemberSearchResultCard = ({ member }: Props): JSX.Element | null => {
  const hasMembershipData = Boolean(member?.memberships?.length);

  return (
    <Collapse in={Boolean(member)}>
      <StyledEnhancedCard>
        <MemberCardHeader member={member} />

        <MemberLoginInfoSection
          authUser={member?.authUser}
          memberEmail={member?.email}
          memberId={member?.id}
        />

        <MemberProfileSection member={member} />

        {hasMembershipData && (
          <MembershipSection memberships={member?.memberships} />
        )}
      </StyledEnhancedCard>
    </Collapse>
  );
};

export default MemberSearchResultCard;
