// External Dependencies
import { FC } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import {
  PersonListItem,
  ShowPageDataDisplay,
} from 'components/shared';
import { isMobileScreenSize } from 'state/selectors';

// Local Dependencies
import DistrictOrgList from './DistrictOrgList';
import TaxExemptFormListItem from './TaxExemptFormListItem';

// Local Typings
interface Props {
  district: GQL.IDistrict;
}

// Local Variables
const StyledFirstSection = styled.section(({ theme }) => ({
  paddingBottom: theme.spacing(2),
}));

const StyledSection = styled.section(({ theme }) => ({
  padding: theme.spacing(2, 0),
}));

const StyledEmptyState = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.grey['50'],
  border: `1px solid ${theme.palette.grey['200']}`,
  borderRadius: theme.shape.borderRadius,
  padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
}));

const StyledSubtitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

// Component Definition
const DistrictInfoContent: FC<Props> = ({ district }) => {
  const isMobileScreen = useSelector(isMobileScreenSize);

  const {
    admin,
    districtAssistants,
    organizations,
  } = district;

  return (
    <>
      <StyledFirstSection>
        <StyledSubtitle variant="subtitle2">Presto Info</StyledSubtitle>

        <Box marginLeft={2}>
          <ShowPageDataDisplay
            label="District ID"
            value={district.id}
          />
          <ShowPageDataDisplay
            label="Number of Organizations"
            value={organizations.length}
          />
          <ShowPageDataDisplay
            label="Verified"
            type="boolean"
            value={district.isVerified}
          />
          <TaxExemptFormListItem
            district={district}
            isMobileScreen={isMobileScreen}
          />
        </Box>
      </StyledFirstSection>

      <Divider />

      <StyledSection>
        <StyledSubtitle variant="subtitle2">Admin Info</StyledSubtitle>

        <Box
          mt={2}
          mx={2}
        >
          <List dense={isMobileScreen}>
            <PersonListItem
              userData={admin}
              variant="district_administrator"
            />
            {districtAssistants.map((districtAssistant) => (districtAssistant.id !== admin.id ? (
              <PersonListItem
                key={districtAssistant.id}
                userData={districtAssistant}
                variant="district_assistant"
              />
            ) : null))}
          </List>
        </Box>
      </StyledSection>

      <Divider />

      <Box
        component="section"
        sx={{
          paddingTop: 2,
        }}
      >
        <StyledSubtitle variant="subtitle2">Organizations</StyledSubtitle>

        {organizations.length > 0 ? (
          <DistrictOrgList organizations={organizations} />
        ) : (
          <StyledEmptyState color="textSecondary">
            No organizations yet.
          </StyledEmptyState>
        )}
      </Box>
    </>
  );
};

export default DistrictInfoContent;
