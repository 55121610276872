// External Dependencies
import Typography from '@mui/material/Typography';
import { useTheme } from 'styled-components';
import Box from '@mui/material/Box';

// Internal Dependencies
import { EnhancedCard, Flex } from 'components/shared';
import { formatDateTime } from 'utils';

// Local Typings
interface Props {
  author: string;
  createdAt: string;
  note: string;
  outbound: boolean;
}

// Component Definition
const MessageBlock = ({
  author,
  createdAt,
  note,
  outbound,
}: Props): JSX.Element => {
  const theme = useTheme();

  return (
    <Flex
      component="li"
      flexDirection={outbound ? 'row-reverse' : 'row'}
      marginBottom={2}
      padding={1}
    >
      <Box
        alignSelf={outbound ? 'flex-end' : 'flex-start'}
        maxWidth="min(70%, 500px)"
        width="fit-content"
      >
        <Flex
          alignItems="center"
          gap={2}
          justifyContent="space-between"
          paddingX={1}
        >
          <Typography
            color="textSecondary"
            variant="caption"
          >
            {author}
          </Typography>

          <Typography
            color="textSecondary"
            variant="caption"
          >
            {formatDateTime(new Date(createdAt))}
          </Typography>
        </Flex>

        <EnhancedCard
          sx={{
            backgroundColor: outbound ? 'primary.light' : theme.palette.grey[100],
            borderRadius: 2,
            padding: 1,
          }}
        >
          <Typography
            color={outbound ? 'primary.contrastText' : 'text.primary'}
            variant="body2"
          >
            {note}
          </Typography>
        </EnhancedCard>
      </Box>
    </Flex>
  );
};

export default MessageBlock;
