interface AddressInfo {
  city: string | null;
  state: string | null;
  zipcode: string | null;
}

export const formatAddress = ({
  city,
  state,
  zipcode,
}: AddressInfo) => {
  // We avoid ternary here so eslint doesn't complain
  //  about an "ambiguous return"
  if (city) {
    return `${city}, ${state} ${zipcode}`;
  }
  // If there is no "city" data, then we don't want to show a dangling comma
  return `${state} ${zipcode ?? ''}`;
};
