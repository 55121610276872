// Internal Dependencies
import { Subtitle } from 'components/shared';

// Local Dependencies
import ImportsTable from './ImportsTable';

// Component Definition
const Imports = (): JSX.Element => {
  return (
    <>
      <Subtitle>
        Imports
      </Subtitle>

      <ImportsTable />
    </>
  );
};

export default Imports;
