// External Dependencies
import { combineReducers } from 'redux';
import { createReducer } from 'deox';

// Internal Dependencies
import theme from 'components/shared/theme';

// Local Dependencies
import { updateDeviceInnerWidth } from '../actions';

// Local Typings
interface Action {
  payload: number;
  type: string;
}
export interface DeviceState {
  deviceSize: string
}

// Local Variables
const {
  breakpoints: {
    values: {
      lg,
      md,
      sm,
      xs,
    },
  },
} = theme;

const INITIAL_STATE: DeviceState = {
  deviceSize: 'lg',
};

// Reducers
const deviceSize = createReducer(INITIAL_STATE.deviceSize, (handleAction) => [
  handleAction(updateDeviceInnerWidth, (state, { payload }: Action) => {
    let size = '';

    if (payload > lg) {
      size = 'xl';
    } else if (payload > md && payload <= lg) {
      size = 'lg';
    } else if (payload > sm && payload <= md) {
      size = 'md';
    } else if (payload >= xs && payload <= sm) {
      size = 'sm';
    }
    return size;
  }),
]);

export default combineReducers({ deviceSize });
