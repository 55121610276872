// External Dependencies
import {
  Box, CardContent, IconButton, Typography,
} from '@mui/material';
import {
  FC, useCallback, useMemo, useState,
} from 'react';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import moment from 'moment';

// Internal Dependencies
import {
  AreaLineChart,
  EnhancedCard,
  Flex,
  Subtitle,
} from 'components/shared';
import { useGetDailyActiveUserCounts } from 'gql/queries/dashboardMetrics';

// Component Definition
const DailyActiveUsers: FC = () => {
  const [month, setMonth] = useState(moment().startOf('month'));

  const queryParams = useMemo<GQL.IDailyActiveUsersQueryParams>(() => ({
    endDate: moment(month).endOf('month').format('YYYY-MM-DD'),
    startDate: moment(month).format('YYYY-MM-DD'),
  }), [month]);

  const monthText = useMemo(() => moment(month).format('MMMM YYYY'), [month]);

  const {
    data: dauData,
  } = useGetDailyActiveUserCounts(queryParams);

  const chartData = dauData?.dailyActiveUserCounts;

  const isPreviousDisabled = useMemo(
    () => moment(month).startOf('month') <= moment('2021').startOf('year'),
    [month],
  );

  const isNextDisabled = useMemo(
    () => moment(month).startOf('month') >= moment().startOf('month'),
    [month],
  );

  const handlePrevious = useCallback(() => {
    setMonth((m) => moment(m).subtract(1, 'month'));
  }, []);

  const handleNext = useCallback(() => {
    const nextMonth = moment(month).add(1, 'month');

    if (nextMonth < moment()) {
      setMonth(nextMonth);
    }
  }, [month]);

  return (
    <>
      <Box
        marginBottom={1}
      >
        <Subtitle>
          Daily Active Users
        </Subtitle>
      </Box>

      <EnhancedCard>
        <CardContent>
          <AreaLineChart<GQL.IDailyActiveUserCount>
            data={chartData}
            xKey="date"
            yKey="count"
          />

          <Flex justifyContent="center">
            <IconButton
              disabled={isPreviousDisabled}
              onClick={handlePrevious}
              size="large"
            >
              <ArrowLeftIcon />
            </IconButton>
            <Typography>
              {monthText}
            </Typography>
            <IconButton
              disabled={isNextDisabled}
              onClick={handleNext}
              size="large"
            >
              <ArrowRightIcon />
            </IconButton>
          </Flex>
        </CardContent>
      </EnhancedCard>
    </>
  );
};

export default DailyActiveUsers;
