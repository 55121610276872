import { Children, cloneElement, Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  renderActionButtons: PropTypes.func,
};

const defaultProps = {
  height: null,
  renderActionButtons: () => {},
};

const findHighestRef = (refs) => {
  let maxHeight = 0;
  refs.forEach((ref) => {
    const currHeight = ref.slideRef ? ref.slideRef.offsetHeight : 0;
    if (currHeight > maxHeight) {
      maxHeight = currHeight;
    }
  });
  return maxHeight;
};

class SlideWrapper extends Component {
  childrenRefs = [];

  render() {
    const height = findHighestRef(this.childrenRefs);
    const {
      children,
      height: propsHeight,
      renderActionButtons,
    } = this.props;

    const StyledWrapper = styled.div({
      height: propsHeight || height,
      overflow: 'hidden',
      position: 'relative',
      width: '100%',
    });

    const hasHeight = propsHeight || height > 0;

    return (
      <>
        <StyledWrapper>
          {Children.map(children, (child, index) => {
            const el = cloneElement(child, {
              ref: (ref) => {
                this.childrenRefs[index] = ref;
              },
            });
            return el;
          })}
        </StyledWrapper>

        {/* passing render action buttons here eliminates rendering before height is establshed */}
        {hasHeight && (
          renderActionButtons()
        )}
      </>
    );
  }
}

SlideWrapper.propTypes = propTypes;
SlideWrapper.defaultProps = defaultProps;

export default SlideWrapper;
